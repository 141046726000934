import { FC } from 'react';

import { Form, Input } from 'antd';
import { useTheme } from 'styled-components';

import { plantApi } from '@/api';
import { PlantForm } from '@/types/plant';
import { isValidAssetName } from '@/utils';

type Props = {
  disabled?: boolean;
};

const Description: FC<Props> = ({ disabled = false }) => {
  const theme = useTheme();
  return (
    <Form.Item
      wrapperCol={{ span: 12 }}
      className="p-16 pb-40"
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
    >
      <Form.Item<PlantForm>
        wrapperCol={{ span: 24 }}
        name="name"
        hasFeedback
        validateDebounce={1000}
        label="Plant Name"
        rules={[
          { required: true, message: 'Plant name cannot be empty.' },
          () => ({
            async validator(_rule, value) {
              if (isValidAssetName(value).valid) {
                return new Promise<void>((resolve, reject) => {
                  plantApi
                    .searchPlantByExactName(value)
                    .then((response) => {
                      if (response.results?.length > 0) {
                        reject('Plant name you have entered already exist.');
                      } else {
                        resolve();
                      }
                    })
                    .catch(() => resolve());
                });
              } else
                return new Promise((_resolve, reject) =>
                  reject(isValidAssetName(value).error)
                );
            },
          }),
        ]}
      >
        <Input disabled={disabled} placeholder="Plant name" />
      </Form.Item>
      <Form.Item<PlantForm>
        wrapperCol={{ span: 24 }}
        name="description"
        label="Description"
        rules={[{ required: false }]}
      >
        <Input.TextArea
          disabled={disabled}
          style={{ resize: 'none' }}
          placeholder="A brief description about your plant"
          rows={4}
          showCount
          maxLength={140}
        />
      </Form.Item>
    </Form.Item>
  );
};

export default Description;
