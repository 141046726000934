import { useEffect, useRef, useState } from 'react';

import { Button, Col, Flex, Form, Input, notification, Typography } from 'antd';
import { Link } from 'react-router-dom';

import { authApi } from '@/api';
import OTPInput from '@/components/ROTPInput';
import useAuth from '@/hooks/useAuth';
import CookieService from '@/services/CookieService';
import LocalStorageService from '@/services/LocalStorageService';
import { LoginPayload } from '@/types/auth';
import CustomAxiosError from '@/types/error';
import { displayNotification } from '@/utils';

interface EmailStepProps {
  setEmailSent: (value: boolean) => void;
  setCurrentStep: (value: number) => void;
}
interface ChildRef {
  handleClear: () => void;
}

export const EmailStep: React.FC<EmailStepProps> = ({
  setEmailSent,
  setCurrentStep,
}) => {
  const { revalidateUser } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (values: LoginPayload) => {
    const currentTimestamp = new Date().getTime();
    setLoading(true);
    try {
      LocalStorageService.setEmailSenderTS(currentTimestamp.toString());
      const { access, refresh } = await authApi.sendEmailVerificationCode(
        values.email
      );
      CookieService.setAuthToken(access);
      CookieService.setRefreshToken(refresh);
      await revalidateUser();
      setEmailSent(true);
    } catch (error: unknown) {
      const axiosError = error as CustomAxiosError;
      // Kullanıcı doğrulandıysa bile, profil bilgilerini kontrol et
      if (
        axiosError.response?.status === 400 &&
        axiosError.response?.data?.errorCode === 'user_20'
      ) {
        await handleUserProfileError(setCurrentStep);
      } else {
        displayNotification(
          'error',
          axiosError.response?.data.userMessage ||
            'An error occurred. Please try again.'
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUserProfileError = async (
    setCurrentStep: (step: number) => void
  ) => {
    const userData = await authApi.me();
    if (!userData.fullName || !userData.phoneNumber || !userData.jobTitle) {
      displayNotification(
        'info',
        'User already verified, but missing profile information.'
      );
      setCurrentStep(1);
    } else if (!userData.country || !userData.organization) {
      displayNotification(
        'info',
        'User already verified, but missing organization information.'
      );
      setCurrentStep(2);
    } else {
      displayNotification(
        'info',
        'User already verified, but missing plan information. Please select a plan.'
      );
      setCurrentStep(3);
    }
  };

  const onFinishFailed = (errorInfo: unknown) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Typography.Text className="info-text fs-14-regular text-faded-color text-center">
        To get started, Please sign in
      </Typography.Text>
      <Flex vertical className="w-100">
        <Form
          name="login"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          requiredMark={false}
        >
          <Form.Item
            label={
              <Typography.Text className="fs-14-regular">
                <span style={{ color: '#FF0000' }}>*</span> Email
              </Typography.Text>
            }
            name="email"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder="example@example.com"
              className="input-style"
              size="large"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </Form.Item>

          <Button
            className="btn-style"
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
          >
            Sign up
          </Button>
        </Form>
      </Flex>
    </>
  );
};

export const EmailStepVerification: React.FC<EmailStepProps> = ({
  setCurrentStep,
  setEmailSent,
}) => {
  const { user, revalidateUser } = useAuth();
  const [otpValue, setOtpValue] = useState<string[]>(['', '', '', '', '', '']);
  const otpRef = useRef<ChildRef>(null);
  const [isOtpValid, setIsOtpValid] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(
        () => setCountdown((prevCountdown) => prevCountdown - 1),
        1000
      );
      return () => clearInterval(timer);
    }
  }, [countdown]);

  const handleOtpChange = (otp: string[]) => {
    setIsOtpValid(
      otp.every((value) => value !== '' && !isNaN(parseInt(value, 10)))
    );
    setOtpValue(otp);
  };

  const handleClearOtp = () => otpRef.current?.handleClear();

  const handleResendEmail = async () => {
    const email = user?.email;
    if (!email) {
      throw new Error('Email address is missing.');
    }
    handleClearOtp();

    try {
      const { access, refresh } =
        await authApi.sendEmailVerificationCode(email);
      CookieService.setAuthToken(access);
      CookieService.setRefreshToken(refresh);
      await revalidateUser();
      setCountdown(60);
      notification.success({
        message:
          'Verification code has been sent successfully. Please check your email.',
      });
    } catch (error) {
      const axiosError = error as CustomAxiosError;
      notification.error({
        message: axiosError.response?.data.userMessage || 'An error occured!',
      });
    }
  };

  const handleVerifyCode = async (code = otpValue.join('')) => {
    setLoading(true);
    try {
      await authApi.verifyRegistrationCode(code);
      displayNotification(
        'success',
        'Verification code is validated. Please wait for automatic redirection.'
      );
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await revalidateUser();
      setCurrentStep(1);
      LocalStorageService.setCurrentStep(1);
    } catch (error) {
      const axiosError = error as CustomAxiosError;
      displayNotification(
        'error',
        axiosError.response?.data.userMessage ||
          'Invalid OTP code, please try again.'
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Typography.Text className="info-text fs-14-regular text-faded-color text-center">
        We have sent a verification code to the email address you provided (
        {user?.email}). <br />
        Please enter the code in the field below.
      </Typography.Text>
      <OTPInput
        onChange={handleOtpChange}
        ref={otpRef}
        handleVerifyCode={handleVerifyCode}
      />
      <Flex align="center" gap={11} className="receive-text">
        {countdown > 0 ? (
          <Typography.Text className="fs-12-regular text-faded-color">
            Resend will be available in{' '}
            <span className="text-blue-color">{countdown}</span> s
          </Typography.Text>
        ) : (
          <>
            <Typography.Text className="fs-12-regular text-faded-color">
              Didn’t receive a code{' '}
            </Typography.Text>
            <Col
              onClick={handleResendEmail}
              className="resend fs-12-regular text-blue-color"
            >
              Resend Code
            </Col>
          </>
        )}
      </Flex>

      <Typography.Text className="fs-12-regular text-faded-color text-center">
        By signing up, I agree to the RatioSIM{' '}
        <Link
          className="text-blue-color"
          to="https://ratiosim.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link
          className="text-blue-color"
          to="https://ratiosim.com/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </Link>
      </Typography.Text>
      <Flex className="btn-group">
        <Button
          onClick={setEmailSent.bind(null, false)}
          className="btn-back"
          type="default"
          htmlType="submit"
        >
          Back
        </Button>
        <Button
          className="btn-send"
          type="primary"
          htmlType="submit"
          block
          disabled={!isOtpValid}
          onClick={() => handleVerifyCode()}
          loading={loading}
        >
          Send Code
        </Button>
      </Flex>
    </>
  );
};
