import { FC } from 'react';

import { Flex, Typography } from 'antd';
import { useTheme } from 'styled-components';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import Table from '@/components/RTable';
import Tooltip from '@/components/RTooltip';
import { SimulationResult } from '@/types/simulations';
import { formatNumberWithCommas } from '@/utils';

import { StyledTable } from './styles';

interface TotalTableDataTypes {
  key: string;
  title: string | JSX.Element;
  beforeStorage: string;
  afterStorage: string;
  storage: string;
}

interface TotalTableProps {
  simulationData: SimulationResult;
}

const TotalTable: FC<TotalTableProps> = ({ simulationData }) => {
  const theme = useTheme();
  const totalDataTable: TotalTableDataTypes[] = [
    {
      key: '1',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '17px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Gross Profit from Sale
          </Typography.Text>
          <Tooltip
            title="Gross Profit from Sale"
            description="Profitability of the core business operations before accounting for other expenses."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      beforeStorage:
        '$ ' +
        formatNumberWithCommas(
          Number(simulationData.result.grossProfitWos.toFixed(0))
        ),
      afterStorage:
        '$ ' +
        formatNumberWithCommas(
          Number(simulationData.result.grossProfitFromSale?.toFixed(0) || 0)
        ),
      storage:
        '$ ' +
        formatNumberWithCommas(
          Number(simulationData.result.grossProfitOfBatteryFromSale.toFixed(0))
        ),
    },
    {
      key: '2',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '17px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Gross Profit from Capacity Reserved
          </Typography.Text>
          <Tooltip
            title="Gross Profit from Capacity Reserved"
            description="Income generated from providing Ancillary Services."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      beforeStorage: '$ 0',
      afterStorage:
        '$ ' +
        formatNumberWithCommas(
          Number(simulationData.result.revenueFromOfferedCapacity.toFixed(0))
        ),
      storage:
        '$ ' +
        formatNumberWithCommas(
          Number(simulationData.result.revenueFromOfferedCapacity.toFixed(0))
        ),
    },
    {
      key: '3',
      title: (
        <Flex
          justify="space-between"
          align="center"
          style={{
            padding: '17px 24px',
            backgroundColor: theme.colors.blueLightest,
            borderTop: '3px solid white',
          }}
        >
          <Typography.Text className="fs-14-bold">
            Total Gross Profit
          </Typography.Text>
          <Tooltip
            title="Total Gross Profit"
            description="Sum of revenue from PFC and SFC, along with the gross profit from sales."
          >
            <IconInfo fill="black" />
          </Tooltip>
        </Flex>
      ),
      beforeStorage:
        '$ ' +
        formatNumberWithCommas(
          Number(simulationData.result.grossProfitWos.toFixed(0))
        ),
      afterStorage:
        '$ ' +
        formatNumberWithCommas(
          Number(simulationData.result.grossProfit.toFixed(0))
        ),
      storage:
        '$ ' +
        formatNumberWithCommas(
          Number(simulationData.result.grossProfitOfBattery.toFixed(0))
        ),
    },
  ];

  const totalColumns = [
    {
      dataIndex: 'title',
      width: '28%',
    },
    {
      title: (
        <Flex justify="center" align="center" gap={8}>
          <Typography.Text className="fs-14-bold">
            Before Storage
          </Typography.Text>
        </Flex>
      ),
      dataIndex: 'beforeStorage',
      key: 'beforeStorage',
      width: '24%',
    },
    {
      title: (
        <Flex justify="center" align="center" gap={8}>
          <Typography.Text className="fs-14-bold">
            After Storage
          </Typography.Text>
        </Flex>
      ),
      dataIndex: 'afterStorage',
      key: 'afterStorage',
      width: '24%',
    },
    {
      title: (
        <Flex justify="center" align="center" gap={8}>
          <Typography.Text className="fs-14-bold">Storage</Typography.Text>
        </Flex>
      ),
      dataIndex: 'storage',
      key: 'storage',
      width: '24%',
    },
  ];

  return (
    <StyledTable>
      <Table
        dataSource={totalDataTable}
        columns={totalColumns}
        pagination={false}
      />
    </StyledTable>
  );
};

export default TotalTable;
