import { Card } from 'antd';
import styled from 'styled-components';

import { type ExtendedCardProps } from './index';

export default styled(Card)<ExtendedCardProps>`
  background-color: ${({ background, theme }) =>
    background || theme.colors.light} !important;
  border: 0.5px solid
    ${({ borderColor, theme }) => borderColor || theme.colors.grayLightAccent} !important;
  border-radius: 10px 10px 10px 10px;

  .ant-card-head {
    border-radius: 10px 10px 0px 0px;
    background-color: ${({ background, theme }) =>
      background || theme.colors.light};
    border-bottom: 0.5px solid
      ${({ borderColor, theme }) => borderColor || theme.colors.grayLightAccent};
    padding: 17.5px 24px;
    margin: 0px;
    .ant-card-head-title {
      font-size: 17px;
      line-height: 25px;
      font-weight: 700;
      color: ${({ theme }) => theme.colors.dark};
    }
  }
  .ant-card-body {
    min-height: ${({ minHeight }) => minHeight || 'auto'} !important;
    border-radius: 0 0 6px 6px;
    padding: ${({ $paddingBody }) => $paddingBody || '0px'} !important;
  }

  .filter-header {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.grayLightAccent};
    margin-bottom: 12px;
  }
`;
