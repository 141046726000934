import type { FC } from 'react';
import { useCallback, useDeferredValue, useEffect, useState } from 'react';

import { Button, Col, Row, Select, Table } from 'antd';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconPlus from '@/assets/icons/icon-plus.svg?react';
import Card from '@/components/RCard';
import PageHeader from '@/components/RPageHeader';
import SearchInput from '@/components/RSearchInput';
import UpdateTime from '@/components/RUpdateTime';
import { RUsageLimitIndicator } from '@/components/RUsageLimitIndicator';
import useAuth from '@/hooks/useAuth';
import useStorageSystemsColumns from '@/hooks/useStorageSystemsColumns';
import { DrawerType } from '@/types/global';
import {
  QueryParamsFilterProps,
  StorageSystem,
  StorageSystemsResponse,
} from '@/types/storageSystem';
import { createQueryString, hasNonEmptyValues } from '@/utils';

import StorageCreateDrawer from './Drawer/StorageCreateDrawer';
import StorageGenericDrawer from './Drawer/StorageGenericDrawer';
import StyledStorageSystems from './styles';

const Scenarios: FC = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const [nameSearchValue, setNameSearchValue] = useState<string>();
  const [selectedActiveStatus, setSelectedActiveStatus] = useState<
    'all' | 'active' | 'inactive'
  >('active');
  const [queryParams, setQueryParams] = useState<QueryParamsFilterProps>({
    name: '',
    isActive: undefined,
  });
  const queryString = createQueryString(queryParams);

  const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState<boolean>(false);

  const deferredNameSearch = useDeferredValue(nameSearchValue);
  const pageSize = 7;
  const [offset, setOffset] = useState(1);

  useEffect(() => {
    setQueryParams((prev) => ({
      ...prev,
      name: deferredNameSearch || '',
      isActive: selectedActiveStatus,
    }));
  }, [deferredNameSearch, selectedActiveStatus]);

  const {
    data: storageSystemsData,
    isLoading,
    mutate,
  } = useSWR<StorageSystemsResponse>(
    hasNonEmptyValues(queryParams)
      ? `/storage/?limit=${pageSize}&offset=${
          (offset - 1) * pageSize
        }&${queryString}`
      : null
  );

  const [drawer, setDrawer] = useState<DrawerType<StorageSystem>>({
    type: null,
    data: null,
  });
  const updateDrawer = useCallback(
    (
      type: DrawerType<StorageSystem>['type'],
      data: DrawerType<StorageSystem>['data']
    ) => {
      setDrawer({ type, data });
    },
    []
  );

  const storageTableColumns = useStorageSystemsColumns(mutate, updateDrawer);
  const assetLimit = user?.organization.subscription.plan.simAssetLimit;

  const handlePaginationChange = (page: number) => {
    setOffset(page);
  };

  const handleCreateDrawerOpen = () => {
    setIsCreateDrawerOpen(true);
  };

  return (
    <StyledStorageSystems>
      <PageHeader
        title="Storage Systems"
        description="Saved storage systems for different simulations. Each storage systems parameters can be edited for different simulations."
        rightSideSection={<RUsageLimitIndicator.Storages />}
      />
      <Card title="All Storage Systems">
        <Row
          justify="space-between"
          className="filter-header"
          style={{ padding: '22px' }}
        >
          <Col className="left-side">
            <Row>
              <Col>
                <Row style={{ height: '100%' }} align="middle" justify="center">
                  <SearchInput
                    placeholder="Search Input"
                    onChange={(e) => setNameSearchValue(e.target.value)}
                  />
                </Row>
              </Col>
              <Col className="pl-16">
                <Row style={{ height: '100%' }} align="middle" justify="center">
                  <Select
                    defaultValue={selectedActiveStatus}
                    placeholder="Status"
                    style={{ width: 180 }}
                    onChange={(val) =>
                      setSelectedActiveStatus(
                        val as 'all' | 'active' | 'inactive'
                      )
                    }
                    options={[
                      { value: 'all', label: 'All' },
                      { value: 'active', label: 'Active' },
                      { value: 'inactive', label: 'Inactive' },
                    ]}
                  />
                </Row>
              </Col>
              <Col className="pl-16">
                <Row style={{ height: '100%' }} align="middle" justify="center">
                  <UpdateTime fetchFunction={mutate} />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<IconPlus fill={theme.colors.light} />}
              onClick={() => handleCreateDrawerOpen()}
            >
              Create
            </Button>
          </Col>
        </Row>
        <Table
          rowKey="id"
          columns={storageTableColumns}
          dataSource={storageSystemsData?.results}
          loading={isLoading}
          size="small"
          pagination={{
            showSizeChanger: false,
            onChange: handlePaginationChange,
            defaultCurrent: 1,
            pageSize: pageSize,
            hideOnSinglePage: true,
            total: storageSystemsData?.count,
          }}
          scroll={{ x: 1000 }}
        />
      </Card>
      {assetLimit && storageSystemsData && isCreateDrawerOpen && (
        <StorageCreateDrawer
          mutate={mutate}
          open={isCreateDrawerOpen}
          setIsOpen={setIsCreateDrawerOpen}
          maxDataCount={assetLimit}
          currentDataCount={storageSystemsData?.count}
        />
      )}
      {assetLimit && storageSystemsData && drawer.type && (
        <StorageGenericDrawer
          mutate={mutate}
          drawerOptions={drawer}
          setDrawerOptions={setDrawer}
        />
      )}
    </StyledStorageSystems>
  );
};

export default Scenarios;
