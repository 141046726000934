import { FC } from 'react';

import {
  Col,
  Form,
  FormInstance,
  InputNumber,
  notification,
  Row,
  Typography,
} from 'antd';
import { useTheme } from 'styled-components';

import { StorageSystemForm } from '@/types/storageSystem';

type Props = {
  disabled?: boolean;
  form: FormInstance<StorageSystemForm>;
};

const AncillaryServices: FC<Props> = ({ disabled = false, form }) => {
  const theme = useTheme();

  //Constants
  const coefPFC = [2.25, 2.25, 2.25, 2.25, 2.25, 2.25];
  const coefSFC = [9, 6.25, 9, 11.25, 9, 9];

  const timeSpans = [
    '00.00 to 03.59',
    '04.00 to 06.59',
    '07.00 to 10.59',
    '11.00 to 14.59',
    '16.00 to 19.59',
    '20.00 to 23.59',
  ];

  const sfcPfcAbilityCheck = () => {
    const {
      ratedEnergyMwh,
      minAllowedStateOfCharge,
      maxAllowedStateOfCharge,
      netOverallRoundTripEfficiency,
      pfcCapacities,
      sfcCapacities,
    } = form.getFieldsValue(true);

    if (
      !ratedEnergyMwh ||
      !minAllowedStateOfCharge ||
      !maxAllowedStateOfCharge ||
      !netOverallRoundTripEfficiency
    ) {
      form.setFieldsValue({
        pfcCapacities: [0, 0, 0, 0, 0, 0],
        sfcCapacities: [0, 0, 0, 0, 0, 0],
      });
    }

    for (let i = 0; i < pfcCapacities.length; i++) {
      const e = pfcCapacities[i];
      if (e > 0) {
        const value =
          (e / 100) *
          (ratedEnergyMwh *
            ((maxAllowedStateOfCharge - minAllowedStateOfCharge) / 100) *
            (netOverallRoundTripEfficiency / 100));
        if (value < coefPFC[i]) {
          form.setFieldValue(['pfcCapacities', i], 0);
          notification.error({
            message: `Capacity Reserved for PFC value should be suitable for the regulation between ${timeSpans[i]}`,
          });
        }
      }
    }

    for (let i = 0; i < sfcCapacities.length; i++) {
      const e = sfcCapacities[i];
      if (e > 0) {
        const value =
          (e / 100) *
          (ratedEnergyMwh *
            ((maxAllowedStateOfCharge - minAllowedStateOfCharge) / 100) *
            (netOverallRoundTripEfficiency / 100));
        if (value < coefSFC[i]) {
          form.setFieldValue(['sfcCapacities', i], 0);
          notification.error({
            message: `Capacity Reserved for SFC value should be suitable for the regulation between ${timeSpans[i]}`,
          });
        }
      }
    }
  };

  return (
    <Form.Item
      wrapperCol={{ span: 24 }}
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
      className="p-16"
    >
      <Form.Item<StorageSystemForm>>
        <Typography.Text type="secondary" className="mb-24">
          In this tab, you can enter the percentage of battery capacity you wish
          to allocate to ancillary services during different block hours. Please
          ensure that these percentages comply with the Battery Capability
          parameters, as there are regulatory requirements that must be
          followed.
          <br />
          <br />
        </Typography.Text>
        {/* TABLE TITLES */}
        <Row gutter={40} className="mb-24" align={'middle'} justify={'center'}>
          <Col span={8}>
            <Row align={'middle'} justify={'start'}>
              <Typography.Text>Time Gaps</Typography.Text>
            </Row>
          </Col>
          <Col span={8}>
            <Row align={'middle'} justify={'start'}>
              <Typography.Text>Capacity Reserved for PFC</Typography.Text>
            </Row>
          </Col>
          <Col span={8}>
            <Row align={'middle'} justify={'start'}>
              <Typography.Text>Capacity Reserved for SFC</Typography.Text>
            </Row>
          </Col>
        </Row>
        <Row gutter={40} justify={'start'} align={'stretch'}>
          {/* TIME GAPS */}
          <Col span={8}>
            {timeSpans.map((item, index) => (
              <Row gutter={24} key={index} justify={'start'} align={'stretch'}>
                <Col style={{ marginBottom: '35px' }}>
                  <Typography.Text>{item}</Typography.Text>
                </Col>
              </Row>
            ))}
          </Col>
          {/* PFK */}
          <Form.List name="pfcCapacities" initialValue={[0, 0, 0, 0, 0, 0]}>
            {(fields) => (
              <Col span={8}>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <Row key={key} align="top" gutter={10} wrap={false}>
                      <Col flex={1}>
                        <Form.Item
                          {...restField}
                          name={[name]}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  // !value ||
                                  getFieldValue('sfcCapacities')[key] + value <=
                                  100
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    'Sum of PFC and SFC reserved capacities should not exceed 100.'
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <InputNumber
                            max={100}
                            onBlur={() => sfcPfcAbilityCheck()}
                            step={1}
                            precision={1}
                            formatter={(value) =>
                              value
                                ? Number(value).toFixed(1).replace(/\.0$/, '')
                                : ''
                            }
                            parser={(value: string | undefined) =>
                              value ? parseFloat(value) : 0
                            }
                            className="w-100"
                            addonAfter="%"
                            disabled={disabled}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        style={{
                          position: 'absolute',
                          right: '-8px',
                        }}
                      >
                        <Typography.Text
                          className="fs-16"
                          style={{ color: theme.colors.bluePrimary }}
                        >
                          +
                        </Typography.Text>
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            )}
          </Form.List>
          {/* SFK */}
          <Form.List name="sfcCapacities" initialValue={[0, 0, 0, 0, 0, 0]}>
            {(fields) => (
              <Col span={8}>
                {fields.map(({ key, name, ...restField }) => (
                  <Row key={key} align="bottom" wrap={false}>
                    <Col flex={1}>
                      <Form.Item
                        {...restField}
                        name={[name]}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                // !value ||
                                getFieldValue('pfcCapacities')[key] + value <=
                                100
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  'Sum of PFC and SFC reserved capacities should not exceed 100.'
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          max={100}
                          step={1}
                          precision={1}
                          formatter={(value) =>
                            value
                              ? Number(value).toFixed(1).replace(/\.0$/, '')
                              : ''
                          }
                          parser={(value: string | undefined) =>
                            value ? parseFloat(value) : 0
                          }
                          onBlur={() => sfcPfcAbilityCheck()}
                          className="w-100"
                          addonAfter="%"
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                ))}
              </Col>
            )}
          </Form.List>
        </Row>
      </Form.Item>
    </Form.Item>
  );
};

export default AncillaryServices;
