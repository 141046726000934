import { useEffect, useState } from 'react';

import { Button, Flex, Spin, Typography } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useParams } from 'react-router';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
  Line,
} from 'recharts';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconCalender from '@/assets/icons/icon-calender.svg?react';
import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconSearch from '@/assets/icons/icon-search.svg?react';
import Card from '@/components/RCard';
import CustomGraphicTooltip from '@/components/RGraphicTooltip';
import RTooltip from '@/components/RTooltip';
import useCalculateEndDate, {
  EndDateFilters,
} from '@/hooks/useCalculateEndDate';
import {
  SimulationResult,
  SimulationResultMarketEnergyActivity,
} from '@/types/simulations';
import {
  formatDateXAxis,
  getTickStep,
  tickFormatterWithoutDollars,
} from '@/utils';

import StyledEnergyActivityAtMarket from './styles';
import { StyledDatePicker, StyledSearch, StyledSelect } from '../../styles';

const EnergyActivityAtMarket = () => {
  const resultId = useParams<Record<string, string>>();
  const [closedDataKeys, setClosedDataKeys] = useState<string[]>([]);
  const [filters, setFilters] = useState<EndDateFilters>({
    startDate: '',
    endDate: '',
    timeUnit: 'year',
    inputValue: undefined,
  });
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const theme = useTheme();

  useEffect(() => {
    if (simulationData?.startDate && simulationData?.endDate) {
      setFilters((prevState) => ({
        ...prevState,
        startDate: moment(simulationData?.startDate).toISOString(),
        endDate: moment(simulationData?.endDate).toISOString(),
      }));
    }
  }, [simulationData?.startDate, simulationData?.endDate]);

  useCalculateEndDate(filters, setFilters);
  const shouldDataFetch =
    filters.startDate && filters.endDate && filters.timeUnit;

  const {
    data: marketEnergyActivityData,
    isLoading,
    error,
  } = useSWR<SimulationResultMarketEnergyActivity>(
    shouldDataFetch
      ? `/simulation/${resultId.resultId}/market_energy_activity/?startDatetime=${filters.startDate}&endDatetime=${filters.endDate}&timeUnit=${filters.timeUnit}`
      : null,
    {
      revalidateOnFocus: false,
    }
  );
  const isDataZero = marketEnergyActivityData?.graphData.length === 0;

  const data =
    marketEnergyActivityData?.graphData.map((item) => {
      const filteredItem: {
        name: string;
        energySold: number;
        energyPurchased: number;
        pfcParticipationAmount: number;
        sfcParticipationAmount: number;
        pfcPrice: number;
        sfcPrice: number;
        mcpPrice: number;
      } = {
        name: formatDateXAxis(item.dt, filters.timeUnit || 'year'),
        energySold: 0,
        energyPurchased: 0,
        pfcParticipationAmount: 0,
        sfcParticipationAmount: 0,
        pfcPrice: 0,
        sfcPrice: 0,
        mcpPrice: 0,
      };
      if (!closedDataKeys.includes('energySold')) {
        filteredItem.energySold = item.sold;
      }
      if (!closedDataKeys.includes('energyPurchased')) {
        filteredItem.energyPurchased = item.purchased;
      }
      if (!closedDataKeys.includes('pfcParticipationAmount')) {
        filteredItem.pfcParticipationAmount = item.offeredCapacityPfc;
      }
      if (!closedDataKeys.includes('sfcParticipationAmount')) {
        filteredItem.sfcParticipationAmount = item.offeredCapacitySfc;
      }
      if (!closedDataKeys.includes('pfcPrice')) {
        filteredItem.pfcPrice = Number(item.pfcPrice.toFixed(1));
      }
      if (!closedDataKeys.includes('sfcPrice')) {
        filteredItem.sfcPrice = Number(item.sfcPrice.toFixed(1));
      }
      if (!closedDataKeys.includes('mcpPrice')) {
        filteredItem.mcpPrice = Number(item.mcp.toFixed(1));
      }
      return filteredItem;
    }) || [];

  const maxValueLeftAxis = Math.max(
    ...data.flatMap((item) => [
      item.energySold +
        item.energyPurchased +
        item.pfcParticipationAmount +
        item.sfcParticipationAmount,
    ])
  );

  const minValueLeftAxis = Math.min(
    ...data.flatMap((item) => [
      item.energySold,
      item.energyPurchased,
      item.pfcParticipationAmount,
      item.sfcParticipationAmount,
    ])
  );

  const maxValuesRightAxis = Math.max(
    ...data.flatMap((item) => [item.pfcPrice, item.sfcPrice, item.mcpPrice])
  );

  const minValuesRightAxis = Math.min(
    ...data.flatMap((item) => [item.pfcPrice, item.sfcPrice, item.mcpPrice])
  );

  const roundToNearest = (num: number, nearest: number) => {
    return Math.round(num / nearest) * nearest;
  };

  const tickStepLeft = getTickStep(
    Math.max(Math.abs(minValueLeftAxis), Math.abs(maxValueLeftAxis))
  );
  const tickStepRight = getTickStep(
    Math.max(Math.abs(minValuesRightAxis), Math.abs(maxValuesRightAxis))
  );
  const upperBoundRight = roundToNearest(
    maxValuesRightAxis * 1.1,
    tickStepRight
  );
  const lowerBoundRight = roundToNearest(
    minValuesRightAxis * 1.1,
    tickStepRight
  );
  const maxAbsBoundRight = Math.max(
    Math.abs(upperBoundRight),
    Math.abs(lowerBoundRight)
  );
  const domainRight = [0, maxAbsBoundRight || 5 * tickStepRight];

  const stepRight = Math.ceil(maxAbsBoundRight / 5) || tickStepRight;
  const ticksRight = Array.from({ length: 6 }, (_, i) =>
    roundToNearest(i * stepRight, tickStepRight)
  );

  const upperBoundLeft = roundToNearest(maxValueLeftAxis * 1.1, tickStepLeft);
  const lowerBoundLeft = roundToNearest(minValueLeftAxis * 1.1, tickStepLeft);
  const maxAbsBoundLeft = Math.max(
    Math.abs(upperBoundLeft),
    Math.abs(lowerBoundLeft)
  );
  const domainLeft = [0, maxAbsBoundLeft || 5 * tickStepLeft];

  const stepLeft = Math.ceil(maxAbsBoundLeft / 5) || tickStepLeft;
  const ticksLeft = Array.from({ length: 6 }, (_, i) =>
    roundToNearest(i * stepLeft, tickStepLeft)
  );

  const toggleClosedDataKeys = (key: string = '') => {
    if (closedDataKeys.includes(key)) {
      setClosedDataKeys(closedDataKeys.filter((item) => item !== key));
    } else {
      setClosedDataKeys([...closedDataKeys, key]);
    }
  };

  const handleTimeUnitChange = (value: unknown) => {
    setFilters({ ...filters, timeUnit: value as EndDateFilters['timeUnit'] });
  };

  const handleInputChange = (value: unknown) => {
    const inputValue = value as number;
    setFilters((prevState) => ({
      ...prevState,
      inputValue: inputValue <= 0 ? 1 : inputValue > 200 ? 200 : inputValue,
    }));
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return (
      current &&
      (current.isBefore(dayjs(simulationData?.startDate).startOf('day')) ||
        current.isAfter(dayjs(simulationData?.endDate).endOf('day')))
    );
  };

  const handleDatePickerChange = (date: unknown) => {
    const selectedDate = date as moment.Moment | null;
    if (selectedDate) {
      setFilters((prevState) => ({
        ...prevState,
        startDate: selectedDate
          ? selectedDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : '',
      }));
    }
  };

  return (
    <StyledEnergyActivityAtMarket>
      <Card
        title={
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              Energy Activity at Market
            </Typography.Title>
            <RTooltip
              title="Energy Activity at Market"
              description="This graph shows the energy activities within the market on a
              yearly, monthly, daily, and hourly basis."
            >
              <IconInfo />
            </RTooltip>
          </Flex>
        }
        extra={
          <Flex gap={16} align="center">
            <Flex gap={8} align="center">
              <motion.div
                whileTap={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 24 }}
              >
                <StyledDatePicker
                  onChange={handleDatePickerChange}
                  placeholder="Select Date"
                  suffixIcon={<IconCalender />}
                  disabledDate={disabledDate}
                />
              </motion.div>
              <motion.div
                whileTap={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 24 }}
              >
                <StyledSelect
                  defaultValue="Year"
                  style={{ width: '109px' }}
                  onChange={handleTimeUnitChange}
                >
                  <StyledSelect.Option value="year">Year</StyledSelect.Option>
                  <StyledSelect.Option value="month">Month</StyledSelect.Option>
                  <StyledSelect.Option value="week">Week</StyledSelect.Option>
                  <StyledSelect.Option value="day">Day</StyledSelect.Option>
                  <StyledSelect.Option value="hour">Hour</StyledSelect.Option>
                </StyledSelect>
              </motion.div>
              <motion.div
                whileTap={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 24 }}
              >
                <StyledSearch
                  placeholder="120"
                  onChange={handleInputChange}
                  prefix={
                    <IconSearch style={{ height: '16px', width: 'auto' }} />
                  }
                  type="number"
                  value={filters.inputValue}
                  status={
                    filters.inputValue !== undefined &&
                    (filters.inputValue === 1 || filters.inputValue === 200)
                      ? 'warning'
                      : undefined
                  }
                />
              </motion.div>
            </Flex>
            <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
          </Flex>
        }
        $paddingBody="24px"
        style={{ width: '100%', marginTop: '24px' }}
        styles={{ header: { padding: '14px 24px' } }}
      >
        {isLoading ? (
          <Flex justify="center" align="center" style={{ height: 400 }}>
            <Spin style={{ margin: '20px' }} />
          </Flex>
        ) : error || isDataZero ? (
          <Flex
            vertical
            gap={12}
            justify="center"
            align="center"
            style={{ height: 400 }}
          >
            <IconError width={50} height={50} fill={theme.colors.tagFailure} />
            {error && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                An error occured while fetching data. Please check your inputs
                and contact support if the issue persists.
              </Typography.Text>
            )}
            {isDataZero && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                No data is currently available. Please review your inputs.
              </Typography.Text>
            )}
          </Flex>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={data} stackOffset="sign">
              <CartesianGrid vertical={false} horizontal={true} />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis
                yAxisId="left"
                domain={domainLeft}
                ticks={ticksLeft}
                tickFormatter={tickFormatterWithoutDollars}
                axisLine={false}
                tickLine={false}
                label={{
                  value: 'Energy (MWh)',
                  angle: -90,
                  dx: -25,
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                domain={domainRight}
                ticks={ticksRight}
                tickFormatter={tickFormatterWithoutDollars}
                axisLine={false}
                tickLine={false}
                label={{
                  angle: 90,
                  dx: 25,
                }}
              />
              <Tooltip content={<CustomGraphicTooltip />} />
              <Legend
                payload={[
                  {
                    value: 'Energy Sold',
                    id: 'energySold',
                    type: 'circle',
                    color: theme.colors.greenSecondary,
                    inactive: closedDataKeys.includes('energySold'),
                  },
                  {
                    value: 'Energy Purchased',
                    id: 'energyPurchased',
                    type: 'circle',
                    color: theme.colors.blueLightAccent,
                    inactive: closedDataKeys.includes('energyPurchased'),
                  },
                  {
                    value: 'PFC Participation Amount',
                    id: 'pfcParticipationAmount',
                    type: 'circle',
                    color: theme.colors.purplePrimary,
                    inactive: closedDataKeys.includes('pfcParticipationAmount'),
                  },
                  {
                    value: 'SFC Participation Amount',
                    id: 'sfcParticipationAmount',
                    type: 'circle',
                    color: theme.colors.orangeSecondary,
                    inactive: closedDataKeys.includes('sfcParticipationAmount'),
                  },
                  {
                    value: 'MCP ($/MWh)',
                    id: 'mcpPrice',
                    type: 'line',
                    color: theme.colors.greenLight,
                    inactive: closedDataKeys.includes('mcpPrice'),
                  },
                  {
                    value: 'PFC Price($/MW)',
                    id: 'pfcPrice',
                    type: 'line',
                    color: theme.colors.redPrimary,
                    inactive: closedDataKeys.includes('pfcPrice'),
                  },
                  {
                    value: 'SFC Price($/MW)',
                    id: 'sfcPrice',
                    type: 'line',
                    color: theme.colors.blueSecondary,
                    inactive: closedDataKeys.includes('sfcPrice'),
                  },
                ]}
                onClick={({ id }) => toggleClosedDataKeys(id)}
              />

              {!closedDataKeys.includes('sfcPrice') && (
                <Line
                  dot={false}
                  name="SFC Price"
                  unit={'$/MW'}
                  yAxisId="right"
                  type="linear"
                  dataKey="sfcPrice"
                  stroke={theme.colors.blueSecondary}
                  strokeWidth={2}
                />
              )}
              {!closedDataKeys.includes('pfcPrice') && (
                <Line
                  dot={false}
                  unit={'$/MW'}
                  name="PFC Price"
                  yAxisId="right"
                  type="linear"
                  dataKey="pfcPrice"
                  stroke={theme.colors.redPrimary}
                  strokeWidth={2}
                />
              )}
              {!closedDataKeys.includes('mcpPrice') && (
                <Line
                  dot={false}
                  unit={'$/MWh'}
                  name="MCP"
                  yAxisId="right"
                  type="linear"
                  dataKey="mcpPrice"
                  stroke={theme.colors.greenLight}
                  strokeWidth={2}
                />
              )}

              {!closedDataKeys.includes('sfcParticipationAmount') && (
                <Bar
                  yAxisId="left"
                  unit={'MWh'}
                  name="SFC Participation Amount"
                  dataKey="sfcParticipationAmount"
                  stackId="a"
                  barSize={50}
                  fill={theme.colors.orangeSecondary}
                />
              )}
              {!closedDataKeys.includes('pfcParticipationAmount') && (
                <Bar
                  yAxisId="left"
                  unit={'MWh'}
                  name="PFC Participation Amount"
                  dataKey="pfcParticipationAmount"
                  stackId="a"
                  barSize={50}
                  fill={theme.colors.purplePrimary}
                />
              )}
              {!closedDataKeys.includes('energyPurchased') && (
                <Bar
                  yAxisId="left"
                  unit={'MWh'}
                  name="Energy Purchased"
                  dataKey="energyPurchased"
                  stackId="a"
                  barSize={50}
                  fill={theme.colors.blueLightAccent}
                />
              )}
              {!closedDataKeys.includes('energySold') && (
                <Bar
                  yAxisId="left"
                  unit={'MWh'}
                  name="Energy Sold"
                  dataKey="energySold"
                  stackId="a"
                  barSize={50}
                  fill={theme.colors.greenSecondary}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Card>
    </StyledEnergyActivityAtMarket>
  );
};
export default EnergyActivityAtMarket;
