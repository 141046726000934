import { type FC } from 'react';

import { Outlet } from 'react-router-dom';

import Content from './Content';
import Header from './Header';
import Sider from './Sider';
import StyledLayout from './styles';

const LayoutMain: FC = () => {
  return (
    <StyledLayout>
      <Header />
      <StyledLayout hasSider>
        <Sider />
        <Content>
          <Outlet />
        </Content>
      </StyledLayout>
    </StyledLayout>
  );
};

export default LayoutMain;
