import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormProps,
  notification,
  Row,
  Typography,
} from 'antd';
import Lottie from 'lottie-react';
import useSWR from 'swr';

import { plantApi } from '@/api';
import loadingLottie from '@/assets/lotties/ratio-loading-lottie.json';
import Card from '@/components/RCard';
import RCollapse, { ExtendedCollapsePanelProps } from '@/components/RCollapse';
import RNotificationCard from '@/components/RNotificationCard';
import { AnimatedText } from '@/components/RTextAnimation';
import RUsageLimitIndicator from '@/components/RUsageLimitIndicator';
import { Draft } from '@/types/global';
import { Plant, PlantForm } from '@/types/plant';
import { dateStringToISO8601, handleError } from '@/utils';

import { StyledDrawer } from './styles';
import { DrawerType } from './types';
import Description from '../Form/Description';
import FinancialMetrics from '../Form/FinancialMetrics';
import GenerationProfile from '../Form/GenerationProfile';
import GridActions from '../Form/GridActions';
import MechanicalProps from '../Form/MechanicalProps';
import ParticipationFiT from '../Form/ParticipationFiT';

type Props = {
  drawerOptions: DrawerType;
  setDrawerOptions: (options: DrawerType) => void;
  mutate?: () => void;
};

const PlantDrawer = (props: Props) => {
  const { drawerOptions, setDrawerOptions, mutate } = props;
  const { type, data: selectedPlant } = drawerOptions;
  const { data } = useSWR<Plant>(
    selectedPlant?.id ? `/plant/${selectedPlant.id}/` : null,
    {
      revalidateOnFocus: false,
    }
  );
  const [form] = Form.useForm();
  const [notificationCardVisible, setNotificationCardVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [duplicateButtonDisabled, setDuplicateButtonDisabled] =
    useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const initialFormValues: PlantForm | undefined = useMemo(() => data, [data]);
  const [draft, setDraft] = useState<Draft>({
    draftId: undefined,
    startDate: undefined,
    endDate: undefined,
  });
  const [uploadError, setUploadError] = useState<string | null>('');

  useEffect(() => {
    if (initialFormValues) {
      form.setFieldsValue(initialFormValues);
      setDuplicateButtonDisabled(true);
    }
  }, [initialFormValues, form]);

  const hasUnsavedData = () => {
    if (!initialFormValues) return false;

    const currentFields: Partial<PlantForm> = form.getFieldsValue(true);

    // Form değerlerini başlangıç değerleriyle karşılaştır
    return Object.keys(initialFormValues).some((key) => {
      const typedKey = key as keyof PlantForm; // Tür dönüşümü
      const initialValue = initialFormValues[typedKey];
      const currentValue = currentFields[typedKey];

      // Farklılıkları kontrol et
      if (Array.isArray(initialValue) && Array.isArray(currentValue)) {
        return JSON.stringify(initialValue) !== JSON.stringify(currentValue);
      }
      return initialValue !== currentValue;
    });
  };

  const onClose = () => {
    if (hasUnsavedData()) {
      setNotificationCardVisible(true);
    } else {
      setDrawerOptions({
        type: null,
        data: null,
      });
    }
  };

  const title =
    drawerOptions.type === 'view'
      ? `View Plant`
      : drawerOptions.type === 'duplicate'
        ? `Duplicate Plant`
        : '';
  const description =
    drawerOptions.type === 'view'
      ? `You can view and duplicate details of "${data?.name}" plant.`
      : drawerOptions.type === 'duplicate'
        ? `You can view and duplicate details of "${data?.name}" plant.`
        : '';

  const resetDrawerData = () => {
    form.resetFields();
    form.setFieldsValue(initialFormValues);
    setDuplicateButtonDisabled(true);
  };

  const onFinish = async () => {
    const plantId = data?.id;
    const values: PlantForm = form.getFieldsValue(true);

    const requiredValues = [
      values.name,
      values.generationType,
      values.installedPowerKw,
      values.licensedPowerKw,
      values.fixedOpex,
      values.capexValue,
      values.capexUnit,
      values.depreciationYears,
      values.investmentLag,
      values.gridPowerConstraintInflow,
      values.gridPowerConstraintOutflow,
    ];

    if (requiredValues.some((value) => value == null)) {
      throw new Error('Some required values are undefined.');
    }

    if (!plantId) {
      throw new Error('Plant ID is undefined.');
    }

    try {
      setIsLoading(true);
      await plantApi.duplicatePlant(plantId, {
        ...values,
        generationFeedInTariffEndDate:
          values.generationFeedInTariffEndDate &&
          dateStringToISO8601(values.generationFeedInTariffEndDate as string),
        generationLocalContributionEndDate:
          values.generationLocalContributionEndDate &&
          dateStringToISO8601(
            values.generationLocalContributionEndDate as string
          ),
        generationFeedInTariffWithStorageEndDate:
          values.generationFeedInTariffWithStorageEndDate &&
          dateStringToISO8601(
            values.generationFeedInTariffWithStorageEndDate as string
          ),
        storageLocalContributionEndDate:
          values.storageLocalContributionEndDate &&
          dateStringToISO8601(values.storageLocalContributionEndDate as string),
        generation:
          typeof values.generation === 'string' ? undefined : values.generation,
      });

      notification.success({
        message: `${name} plant has been created successfully!`,
      });

      setIsLoading(false);
      resetDrawerData();
      mutate && mutate();
      onClose();
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const isDisabled = drawerOptions.type === 'view' ? true : false;

  const collapseItems: ExtendedCollapsePanelProps[] = [
    {
      key: 'description-section',
      header: <Typography.Text className="bold">Description</Typography.Text>,
      children: <Description disabled={isDisabled} />,
    },
    {
      key: 'mecanical-props-section',
      header: (
        <Typography.Text className="bold">
          Mechanical Properties
        </Typography.Text>
      ),
      children: <MechanicalProps disabled={isDisabled} />,
    },
    {
      key: 'grid-actions-section',
      header: <Typography.Text className="bold">Grid Actions</Typography.Text>,
      children: <GridActions disabled={isDisabled} />,
    },
    {
      key: 'participation-fit-section',
      header: (
        <Typography.Text className="bold">Participation FiT</Typography.Text>
      ),
      children: <ParticipationFiT form={form} disabled={isDisabled} />,
    },
    {
      key: 'financial-metrics-section',
      header: (
        <Typography.Text className="bold">Financial Metrics</Typography.Text>
      ),
      children: (
        <FinancialMetrics
          form={form}
          disabled={isDisabled}
          initialValues={initialFormValues}
        />
      ),
    },
    {
      key: 'generation-profile-section',
      header: (
        <Typography.Text className="bold">Generation Profile</Typography.Text>
      ),
      children: (
        <GenerationProfile
          currentPlantData={data}
          disabled={isDisabled}
          form={form}
          draft={draft}
          setDraft={setDraft}
          uploadError={uploadError}
          setUploadError={setUploadError}
        />
      ),
    },
  ];

  const onFieldsChange: FormProps['onFieldsChange'] = () => {
    if (initialFormValues) {
      setDuplicateButtonDisabled(false);
    }
  };

  const resetFormFieldsToInitialValues = () => {
    if (initialFormValues) {
      form.setFieldsValue(initialFormValues);
      setDuplicateButtonDisabled(true);
      setDraft({
        draftId: undefined,
        startDate: undefined,
        endDate: undefined,
      });
      setUploadError(null);
    }
  };

  //TODO Split this into a separate component
  const overlayTextArray = useMemo(() => {
    return [
      'Configuring plant settings.',
      'Analyzing file...',
      'The final touches are being made.',
    ];
  }, []);

  const [overlayText, setOverlayText] = useState<string>(overlayTextArray[0]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        if (currentIndex < overlayTextArray.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setOverlayText(overlayTextArray[currentIndex + 1]);
        } else {
          clearInterval(interval);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [currentIndex, overlayTextArray, isLoading]);

  return (
    <StyledDrawer
      className={drawerOptions.type === 'view' ? 'view-drawer' : ''}
      title={title}
      description={description}
      rightSideSection={<RUsageLimitIndicator.Plants />}
      destroyOnClose
      open={!!type}
      onClose={onClose}
    >
      <Card className="px-16 py-16 context-card">
        {initialFormValues && (
          <Form<PlantForm>
            onFieldsChange={onFieldsChange}
            className="form-styles"
            initialValues={initialFormValues}
            form={form}
            layout="vertical"
            onFinish={onFinish}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            }}
          >
            <RCollapse
              defaultActiveKey={collapseItems[0].key}
              items={collapseItems}
            />
            {drawerOptions.type === 'duplicate' && (
              <Row
                className="bottom-button-group"
                align="middle"
                justify="space-between"
              >
                <Button onClick={onClose}>Cancel</Button>
                {!duplicateButtonDisabled && (
                  <div className="right-side-btn-group">
                    <Button
                      onClick={resetFormFieldsToInitialValues}
                      loading={isLoading}
                      disabled={isLoading}
                      className="ml-4"
                    >
                      Reset
                    </Button>
                    <Button
                      loading={isLoading}
                      disabled={isLoading}
                      className="ml-4"
                      type="primary"
                      htmlType="submit"
                    >
                      Duplicate
                    </Button>
                  </div>
                )}
              </Row>
            )}
          </Form>
        )}
      </Card>
      <RNotificationCard
        title="Data will be lost."
        message="Are you sure you want to leave?"
        type="warning"
        confirmText="Leave"
        cancelText="Cancel"
        open={notificationCardVisible}
        onConfirm={() => {
          resetDrawerData();
          setNotificationCardVisible(false);
          setDrawerOptions({
            type: null,
            data: null,
          });
        }}
        onCancel={() => setNotificationCardVisible(false)}
      />
      {isLoading && (
        <Row align="top" justify="center" className="pt-120 loading-wrapper">
          <Col>
            <Lottie
              style={{ height: '120px' }}
              animationData={loadingLottie}
              loop={true}
            />
            <Row>
              <AnimatedText className="animated-text" text={overlayText} />
            </Row>
          </Col>
        </Row>
      )}
    </StyledDrawer>
  );
};

export default PlantDrawer;
