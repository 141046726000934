import { Button, Row, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconEye from '@/assets/icons/icon-view.svg?react';
import { Plant } from '@/types/plant';

type Props = {
  prepareSimulationItem: (id: string, type: string) => void;
};

const useNewSimulationPlantTableColumns = (props: Props) => {
  const columns: ColumnsType<Plant> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{ maxWidth: '90px' }}
            className="text"
            ellipsis={{
              rows: 1,
              tooltip: name,
              expandable: false,
            }}
          >
            {name}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description) => (
        <Typography.Paragraph
          style={{ maxWidth: '100px' }}
          className="text"
          ellipsis={{
            rows: 1,
            tooltip: description,
            expandable: false,
          }}
        >
          {description}
        </Typography.Paragraph>
      ),
    },
    {
      title: (
        <Tooltip
          className="cursor-pointer"
          trigger="click"
          title="Installed Power (MW/h)"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Ins. Power(MW)</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'installedPowerKw',
      align: 'center',
      sorter: (a, b) => a.installedPowerKw - b.installedPowerKw,
      key: 'installedPowerKw',
      render: (installedPowerKw) => (
        <Row align="middle" justify="end">
          {installedPowerKw}
        </Row>
      ),
    },
    {
      title: (
        <Tooltip
          className="cursor-pointer"
          trigger="click"
          title="License Power (MW/h)"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Lic. Power(MW)</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'licensedPowerKw',
      align: 'center',
      key: 'licensedPowerKw ',
      sorter: (a, b) => a.licensedPowerKw - b.licensedPowerKw,
      render: (licensedPowerKw) => (
        <Row align="middle" justify="end">
          {licensedPowerKw}
        </Row>
      ),
    },
    {
      title: (
        <Tooltip className="cursor-pointer" trigger="click" title="TODO">
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Created Date</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: 180,
      render: (dataIndex) => moment(dataIndex).format('DD.MM.YYYY HH:mm'),
      sorter: (a, b) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    },

    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '50px',
      render: (_, plant) => (
        <Row
          align="middle"
          justify="space-between"
          className="default-icon-button"
        >
          <Tooltip title="View">
            <Button
              className="default-icon-button"
              type="default"
              onClick={() => {
                props.prepareSimulationItem(plant.id, 'Plant');
              }}
              icon={<IconEye />}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];

  return columns;
};

export default useNewSimulationPlantTableColumns;
