import { FC } from 'react';

import { Dropdown, Row, MenuProps, Col } from 'antd';
import { Link } from 'react-router-dom';

import Question from '@/assets/icons/icon-question-mark.svg?react';
import paths from '@/routes/paths';

const Terms: FC = () => {
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link target="_blank" to={paths.external.tos}>
          Terms of Service
        </Link>
      ),
    },
    {
      key: '2',
      label: (
        <Link target="_blank" to={paths.external.privacy}>
          Privacy Policy
        </Link>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Col>
        <Row
          style={{ minWidth: '48px' }}
          className="toolbar-icon"
          align="middle"
          justify="center"
        >
          <Question />
        </Row>
      </Col>
    </Dropdown>
  );
};

const HeaderToolbar: FC = () => {
  return (
    <Row className="border-divide-left" align="middle" justify="center">
      <Terms />
    </Row>
  );
};

export default HeaderToolbar;
