import { useState } from 'react';

import { Flex, Spin, TableColumnsType, Typography } from 'antd';
import { useParams } from 'react-router';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconDown from '@/assets/icons/icon-down.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconRight from '@/assets/icons/icon-right.svg?react';
import Card from '@/components/RCard';
import Table from '@/components/RTable';
import Tooltip from '@/components/RTooltip';
import {
  SimulationResult,
  SimulationResultYearlyFinancialAnalysis,
  SimulationResultYearlyFinancialAnalysisData,
} from '@/types/simulations';
import { converToMillion } from '@/utils';

import StyledProfit from './styles';

interface DataType {
  key: React.Key;
  name: React.ReactNode;
  total?: string;
  children?: DataType[];
}

const CashFlowTable = () => {
  const theme = useTheme();
  const resultId = useParams<Record<string, string>>();
  const {
    data: financialData,
    isLoading,
    error,
  } = useSWR<SimulationResultYearlyFinancialAnalysis>(
    `/simulation/${resultId.resultId}/yearly_financial_analysis/`,
    {
      revalidateOnFocus: false,
    }
  );
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const onExpand = (expanded: boolean, record: DataType) => {
    const keys = expanded
      ? [...expandedRowKeys, record.key]
      : expandedRowKeys.filter((key) => key !== record.key);
    setExpandedRowKeys(keys);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customExpandIcon = (props: any) => {
    if (!props.record.children) {
      return <span style={{ marginRight: '22px' }} />;
    }
    if (props.expanded) {
      return (
        <IconDown
          width={12}
          height={12}
          style={{ marginRight: '8px' }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    } else {
      return (
        <IconRight
          width={12}
          height={12}
          style={{ marginRight: '8px' }}
          onClick={(e) => props.onExpand(props.record, e)}
        />
      );
    }
  };

  let years: number[] = [];

  financialData?.graphData.forEach((item) => {
    years.push(item.year);
  });

  const scrollX = 600 + years.length * 100;

  years = [...new Set(years)].sort((a, b) => a - b);

  const getValuesForYears = (
    graphData: SimulationResultYearlyFinancialAnalysisData[],
    key: string
  ) => {
    const values: Record<string, string | number> = {};
    if (graphData) {
      years.forEach((year) => {
        const data = graphData.find((item) => item.year === year);
        values[year.toString()] = data
          ? converToMillion(data[key]) + ' $'
          : '0';
      });
    }
    return values;
  };

  if (!financialData || !simulationData) {
    return null;
  }

  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      width: 350,
      fixed: 'left',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
    },
    ...years.map((year) => ({
      title: year.toString(),
      dataIndex: year.toString(),
      key: year.toString(),
      width: 100,
    })),
  ];

  const dataSource = [
    {
      key: '1',
      name: 'EBITDA',
      total: converToMillion(simulationData?.result.ebitda) + ' $',
      ...getValuesForYears(financialData.graphData, 'ebitda'),
    },
    {
      key: '2',
      name: 'Tax',
      total: converToMillion(simulationData?.result.tax) + ' $',
      ...getValuesForYears(financialData.graphData, 'tax'),
    },
    {
      key: '3',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          Net Profit
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.netProfit) + ' $',
      ...getValuesForYears(financialData.graphData, 'netProfit'),
    },
    {
      key: '6',
      name: <span></span>,
    },
    {
      key: '7',
      name: 'CAPEX',
    },
    {
      key: '8',
      name: 'Capex',
      total: converToMillion(simulationData?.result.capexWithStorage) + ' $',
      ...getValuesForYears(financialData.graphData, 'capexWithStorage'),
      children: [
        {
          key: '8-1',
          name: 'Initial Capex',
          total: converToMillion(simulationData?.result.initialCapex) + ' $',
          ...getValuesForYears(financialData.graphData, 'initialCapex'),
        },
        {
          key: '8-2',
          name: 'Battery Replacement Cost',
          total:
            converToMillion(simulationData?.result.batteryReplacementCost) +
            ' $',
          ...getValuesForYears(
            financialData.graphData,
            'batteryReplacementCost'
          ),
        },
      ],
    },
    {
      key: '9',
      name: 'Residual Values',
      total: converToMillion(simulationData?.result.totalResidual) + ' $',
      ...getValuesForYears(financialData.graphData, 'totalResidual'),

      children: [
        {
          key: '9-1',
          name: 'Plant Residual Value',
          total: converToMillion(simulationData?.result.residualPlant) + ' $',
          ...getValuesForYears(financialData.graphData, 'residualPlant'),
        },
        {
          key: '9-2',
          name: 'Battery Residual Value',
          total: converToMillion(simulationData?.result.residualBattery) + ' $',
          ...getValuesForYears(financialData.graphData, 'residualBattery'),
        },
        {
          key: '9-3',
          name: 'BOS Residual Value',
          total: converToMillion(simulationData?.result.residualBos) + ' $',
          ...getValuesForYears(financialData.graphData, 'residualBos'),
        },
      ],
    },
    {
      key: '10',
      name: <span></span>,
    },
    {
      key: '11',
      name: 'Cash Flow From Financing Activities',
      total: converToMillion(simulationData?.result.cashFlow) + ' $',
      ...getValuesForYears(financialData.graphData, 'cashFlow'),
    },
    {
      key: '12',
      name: (
        <Typography.Text className="fs-14-extra-bold text-gray-color">
          Net Cash Flow
        </Typography.Text>
      ),
      total: converToMillion(simulationData?.result.netPresentValue) + ' $',
      ...getValuesForYears(financialData.graphData, 'netPresentValue'),
    },
  ];

  return (
    <StyledProfit>
      <Card
        title={
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              Cash Flow
            </Typography.Title>
            <Tooltip
              title="Cash Flow"
              description="Cashflow starting from EBITDA, adjusted for taxes, Capex, and residuals."
            >
              <IconInfo />
            </Tooltip>
          </Flex>
        }
        styles={{ header: { padding: '17.25px 24px' } }}
        $paddingBody="12px 0"
        style={{ marginTop: '24px' }}
      >
        {isLoading ? (
          <Flex justify="center" align="center" style={{ height: 361 }}>
            <Spin style={{ margin: '20px' }} />
          </Flex>
        ) : error ? (
          <Flex
            vertical
            gap={12}
            justify="center"
            align="center"
            style={{ height: 361 }}
          >
            <IconError width={50} height={50} fill={theme.colors.tagFailure} />
            <Typography.Text className="fs-14-regular text-gray-color">
              An error occured while fetching data. Please check your inputs or
              contact support if the issue persists.
            </Typography.Text>
          </Flex>
        ) : (
          <Table
            dataSource={dataSource}
            columns={columns}
            expandable={{
              expandedRowKeys,
              onExpand,
              expandIcon: customExpandIcon,
              rowExpandable: (record) => !!record.children,
            }}
            pagination={false}
            scroll={{ x: scrollX }}
          />
        )}
      </Card>
    </StyledProfit>
  );
};

export default CashFlowTable;
