import { FC, useEffect, useState } from 'react';

import {
  Button,
  Dropdown,
  Flex,
  notification,
  Spin,
  Tag,
  Typography,
} from 'antd';
import { motion } from 'framer-motion';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconClock from '@/assets/icons/icon-clock-circle.svg?react';
import IconExport from '@/assets/icons/icon-export.svg?react';
import IconID from '@/assets/icons/icon-id.svg?react';
import IconName from '@/assets/icons/icon-name.svg?react';
import IconPlants from '@/assets/icons/icon-plants.svg?react';
import IconScenarios from '@/assets/icons/icon-scenarios.svg?react';
import IconStorageSystems from '@/assets/icons/icon-storage-systems.svg?react';
import Card from '@/components/RCard';
import PageHeader from '@/components/RPageHeader';
import CustomTab from '@/components/RTab';
import useAuth from '@/hooks/useAuth';
import StorageDrawer from '@/pages/StorageSystems/Drawer/StorageGenericDrawer';
import paths from '@/routes/paths';
import { DrawerType } from '@/types/global';
import { Plant } from '@/types/plant';
import { Scenario } from '@/types/scenario';
import { SimulationResult } from '@/types/simulations';
import { StorageSystem } from '@/types/storageSystem';

import { formatDate } from './../../utils/index';
import Analysis from './Analysis/index';
import Energy from './Energy';
import Finance from './Finance';
import StyledResult from './styles';
import Summary from './Summary/index';
import PlantDrawer from '../Plants/Drawer/PlantGenericDrawer';
import ScenarioDrawer from '../Scenarios/Drawer/ScenarioGenericDrawer';

const TabItems = [
  {
    label: 'Summary',
    children: <Summary />,
    key: '1',
    tab: <motion.div whileTap={{ scale: 0.9 }}>Summary</motion.div>,
  },
  {
    label: 'Finance',
    children: <Finance />,
    key: '2',
    tab: <motion.div whileTap={{ scale: 0.9 }}>Finance</motion.div>,
  },
  {
    label: 'Energy',
    children: <Energy />,
    key: '3',
    tab: <motion.div whileTap={{ scale: 0.9 }}>Energy</motion.div>,
  },
  {
    label: 'Analysis',
    children: <Analysis />,
    key: '4',
    tab: <motion.div whileTap={{ scale: 0.9 }}>Analysis</motion.div>,
  },
];

const tabNameToKey: { [key: string]: string } = {
  summary: '1',
  finance: '2',
  energy: '3',
  analysis: '4',
};

const keyToTabName: { [key: string]: string } = {
  '1': 'summary',
  '2': 'finance',
  '3': 'energy',
  '4': 'analysis',
};

const Result: FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const resultId = useParams<Record<string, string>>();
  const { data: simulationData, isLoading } = useSWR<SimulationResult>(
    resultId.resultId && `/simulation/${resultId.resultId}/`,
    { revalidateOnFocus: false }
  );

  const queryParams = new URLSearchParams(location.search);
  const defaultTabName = queryParams.get('tab') || 'summary';
  const defaultTabKey = tabNameToKey[defaultTabName] || '1';
  const [activeTabKey, setActiveTabKey] = useState(defaultTabKey);
  const assetLimit = user?.organization.subscription.plan.simAssetLimit;
  const theme = useTheme();

  const [drawerPlant, setDrawerPlant] = useState<DrawerType<Plant>>({
    type: null,
    data: null,
  });
  const [drawerScenario, setDrawerScenario] = useState<DrawerType<Scenario>>({
    type: null,
    data: null,
  });

  const [drawerStorageSystem, setDrawerStorageSystem] = useState<
    DrawerType<StorageSystem>
  >({
    type: null,
    data: null,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabName = queryParams.get('tab');

    if (!tabName) {
      navigate('?tab=summary', { replace: true });
    } else {
      const tabKey = tabNameToKey[tabName] || '1';
      setActiveTabKey(tabKey);
    }
  }, [location.search, navigate]);

  useEffect(() => {
    const handleBackButton = () => {
      navigate(paths.simulation.base);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate]);

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
    const tabName = keyToTabName[key];
    navigate(`?tab=${tabName}`);
  };

  // const handleToggle = (checked: boolean) => {
  //   setIsChecked(checked);
  // };

  const handleTagClick = (type: string, assetID: number | undefined) => {
    if (type === 'plant') {
      setDrawerPlant({
        type: 'view',
        data: { id: String(assetID) },
      });
    } else if (type === 'scenario') {
      setDrawerScenario({
        type: 'view',
        data: { id: String(assetID) },
      });
    } else if (type === 'storage') {
      setDrawerStorageSystem({
        type: 'view',
        data: { id: String(assetID) },
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMenuClick = (e: any) => {
    const downloadFile = async (fileUrl: string | undefined) => {
      try {
        if (fileUrl) {
          const link = document.createElement('a');
          link.href = fileUrl;
          link.setAttribute('download', '');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error('File URL is not found!');
        }
      } catch (error) {
        notification.error({
          message: 'Failed to download file!',
        });
        console.log(error);
      }
    };

    if (e.key === '1') {
      downloadFile(simulationData?.financialMetricsFile);
    } else if (e.key === '2') {
      downloadFile(simulationData?.solutionFile);
    }
  };

  const menu = [
    { key: '1', label: 'Export Aggregated Data' },
    { key: '2', label: 'Export Raw Data' },
  ];

  return (
    <StyledResult>
      <PageHeader
        title="Results"
        description="Displays the energy and financial outcomes of the simulation."
      />
      {isLoading ? (
        <Flex justify="center" align="center" style={{ height: '157.5px' }}>
          <Spin style={{ margin: '20px' }} />
        </Flex>
      ) : (
        <Card
          title={
            <Flex gap={24}>
              <Typography.Title level={3} className="fs-17-bold">
                Parameters
              </Typography.Title>
              <Flex gap={8} align="center">
                <Typography.Text className="fs-14-medium text-faded-color">
                  ID
                </Typography.Text>
                <IconID fill={theme.colors.grayAccent} />
                <Typography.Text className="fs-14-regular ">
                  {simulationData?.id}
                </Typography.Text>
              </Flex>
              <Flex gap={8} align="center">
                <Typography.Text className="fs-14-medium text-faded-color">
                  Name
                </Typography.Text>
                <IconName fill={theme.colors.grayAccent} />
                <Typography.Text
                  className="fs-14-regular"
                  style={{ maxWidth: '150px' }}
                  ellipsis={{
                    tooltip: simulationData?.name,
                  }}
                >
                  {simulationData?.name}
                </Typography.Text>
              </Flex>
              <Flex gap={8} align="center">
                <Typography.Text className="fs-14-medium text-faded-color">
                  Start Date
                </Typography.Text>
                <IconClock fill={theme.colors.grayAccent} />
                <Typography.Text className="fs-14-regular ">
                  {formatDate(simulationData?.startDate || '')}
                </Typography.Text>
              </Flex>
              <Flex gap={8} align="center">
                <Typography.Text className="fs-14-medium text-faded-color">
                  End Date
                </Typography.Text>
                <IconClock fill={theme.colors.grayAccent} />
                <Typography.Text className="fs-14-regular">
                  {formatDate(simulationData?.endDate || '')}
                </Typography.Text>
              </Flex>
              <Flex gap={8} align="center">
                <Typography.Text className="fs-14-medium text-faded-color">
                  Result Date
                </Typography.Text>
                <IconClock fill={theme.colors.grayAccent} />
                <Typography.Text className="fs-14-regular ">
                  {formatDate(simulationData?.dateDone || '')}
                </Typography.Text>
              </Flex>
            </Flex>
          }
          $paddingBody="32px 24px"
          background="transparent"
        >
          <Flex gap={72} align="center">
            <Flex gap={24} align="center">
              <Flex gap={8} align="center">
                <IconPlants
                  fill={theme.colors.grayAccent}
                  style={{ height: '32px', width: 'auto' }}
                />
                <Typography.Text className="fs-17-semi-bold faded-text">
                  Plant
                </Typography.Text>
              </Flex>{' '}
              <Flex gap={12} align="center">
                {simulationData?.plants.map((plant) => (
                  <motion.div key={plant.id} whileTap={{ scale: 0.95 }}>
                    <Tag
                      className="tags"
                      onClick={() => {
                        handleTagClick('plant', plant.id);
                      }}
                      key={plant.id}
                    >
                      {plant.name.length > 20
                        ? `${plant.name.substring(0, 17)}...`
                        : plant.name}
                    </Tag>
                  </motion.div>
                ))}
              </Flex>
            </Flex>
            <Flex gap={24} align="center">
              <Flex gap={8} align="center">
                <IconScenarios
                  fill={theme.colors.grayAccent}
                  style={{ height: '32px', width: 'auto' }}
                />
                <Typography.Text className="fs-17-semi-bold faded-text">
                  Scenario
                </Typography.Text>
              </Flex>
              <motion.div whileTap={{ scale: 0.95 }}>
                <Tag
                  className="tags"
                  onClick={() =>
                    handleTagClick('scenario', simulationData?.scenario.id)
                  }
                >
                  {simulationData && simulationData?.scenario.name.length > 20
                    ? `${simulationData.scenario.name.substring(0, 17)}...`
                    : simulationData?.scenario.name}
                </Tag>
              </motion.div>
            </Flex>
            <Flex gap={24} align="center">
              <Flex gap={8} align="center">
                <IconStorageSystems
                  fill={theme.colors.grayAccent}
                  style={{ height: '32px', width: 'auto' }}
                />
                <Typography.Text className="fs-17-semi-bold faded-text">
                  Storage System
                </Typography.Text>
              </Flex>
              <motion.div whileTap={{ scale: 0.95 }}>
                <Tag
                  className="tags"
                  onClick={() =>
                    handleTagClick('storage', simulationData?.storage.id)
                  }
                >
                  {simulationData && simulationData?.storage.name.length > 20
                    ? `${simulationData.storage.name.substring(0, 17)}...`
                    : simulationData?.storage.name}
                </Tag>
              </motion.div>
            </Flex>
          </Flex>
        </Card>
      )}
      <CustomTab
        items={TabItems}
        activeKey={activeTabKey}
        onChange={handleTabChange}
        tabBarExtraContent={
          <Flex gap={16}>
            {/* <Flex gap={16} align="center">
              <Typography.Text
                className={isChecked ? 'switch-text' : 'fs-14-bold'}
              >
                Simple
              </Typography.Text>
              <Switch onChange={handleToggle} defaultChecked={true} />
              <Typography.Text
                className={isChecked ? 'fs-14-bold' : 'switch-text'}
              >
                Detailed
              </Typography.Text>
            </Flex> */}
            <Dropdown
              menu={{ items: menu, onClick: handleMenuClick }}
              trigger={['click']}
            >
              <motion.div whileTap={{ scale: 0.95 }}>
                <Button
                  style={{
                    background: 'transparent',
                    width: '41px',
                    height: '41px',
                  }}
                  icon={<IconExport fill={theme.colors.bluePrimary} />}
                />
              </motion.div>
            </Dropdown>
          </Flex>
        }
        renderTabBar={(props, DefaultTabBar) => (
          <DefaultTabBar {...props}>
            {(node) => (
              <motion.div whileTap={{ scale: 0.95 }}>{node}</motion.div>
            )}
          </DefaultTabBar>
        )}
      />
      {assetLimit && (
        <PlantDrawer
          drawerOptions={drawerPlant}
          setDrawerOptions={setDrawerPlant}
        />
      )}
      {assetLimit && (
        <ScenarioDrawer
          drawerOptions={drawerScenario}
          setDrawerOptions={setDrawerScenario}
        />
      )}
      {assetLimit && (
        <StorageDrawer
          drawerOptions={drawerStorageSystem}
          setDrawerOptions={setDrawerStorageSystem}
        />
      )}
    </StyledResult>
  );
};

export default Result;
