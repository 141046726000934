import { useEffect, useMemo, useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormProps,
  notification,
  Row,
  Typography,
} from 'antd';
import Lottie from 'lottie-react';
import useSWR from 'swr';

import { storageApi } from '@/api';
import loadingLottie from '@/assets/lotties/ratio-loading-lottie.json';
import Card from '@/components/RCard';
import RCollapse, { ExtendedCollapsePanelProps } from '@/components/RCollapse';
import RNotificationCard from '@/components/RNotificationCard';
import { AnimatedText } from '@/components/RTextAnimation';
import { RUsageLimitIndicator } from '@/components/RUsageLimitIndicator';
import GridActions from '@/pages/Plants/Form/GridActions';
import { DrawerType } from '@/types/global';
import { StorageSystem, StorageSystemForm } from '@/types/storageSystem';
import { handleError } from '@/utils';

import { StyledDrawer } from './styles';
import AncillaryServices from '../Form/AncillaryServices';
import BatteryCapability from '../Form/BatteryCapability';
import CycleLife from '../Form/CycleLife';
import Description from '../Form/Description';
import FinancialMetrics from '../Form/FinancialMetrics';
import RateCapability from '../Form/RateCapability';

type Props = {
  drawerOptions: DrawerType<StorageSystem>;
  setDrawerOptions: (options: DrawerType<StorageSystem>) => void;
  mutate?: () => void;
};

const StorageDrawer = (props: Props) => {
  const { drawerOptions, setDrawerOptions, mutate } = props;
  const { type, data: selectedScenario } = drawerOptions;
  const { data } = useSWR<StorageSystem>(
    selectedScenario?.id ? `/storage/${selectedScenario?.id}/` : null,
    {
      revalidateOnFocus: false,
    }
  );
  const [form] = Form.useForm();
  const [notificationCardVisible, setNotificationCardVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [duplicateButtonDisabled, setDuplicateButtonDisabled] =
    useState<boolean>(true);

  const initialFormValues: StorageSystemForm | undefined = useMemo(
    () => data,
    [data]
  );

  useEffect(() => {
    if (initialFormValues) {
      form.setFieldsValue(initialFormValues);
      setDuplicateButtonDisabled(true);
    }
  }, [initialFormValues, form]);

  const hasUnsavedData = () => {
    if (!initialFormValues) return false;

    const currentFields: Partial<StorageSystemForm> = form.getFieldsValue(true);

    // Form değerlerini başlangıç değerleriyle karşılaştır
    return Object.keys(initialFormValues).some((key) => {
      const typedKey = key as keyof StorageSystemForm; // Tür dönüşümü
      const initialValue = initialFormValues[typedKey];
      const currentValue = currentFields[typedKey];

      // Farklılıkları kontrol et
      if (Array.isArray(initialValue) && Array.isArray(currentValue)) {
        return JSON.stringify(initialValue) !== JSON.stringify(currentValue);
      }
      return initialValue !== currentValue;
    });
  };

  const onClose = () => {
    if (hasUnsavedData()) {
      setNotificationCardVisible(true);
    } else {
      setDrawerOptions({
        type: null,
        data: null,
      });
    }
  };

  const title =
    drawerOptions.type === 'view'
      ? `View Storage System`
      : drawerOptions.type === 'duplicate'
        ? `Duplicate Storage System`
        : '';
  const description =
    drawerOptions.type === 'view'
      ? `You can view and duplicate details of "${data?.name}" storage system.`
      : drawerOptions.type === 'duplicate'
        ? `You can view and duplicate details of "${data?.name}" storage system.`
        : '';

  const resetDrawerData = () => {
    form.resetFields();
    form.setFieldsValue(initialFormValues);
    setDuplicateButtonDisabled(true);
  };

  const onFinish = async () => {
    const storageId = data?.id;
    const values: StorageSystemForm = form.getFieldsValue(true);
    if (!values.name) {
      throw new Error('Some required values are undefined.');
    }
    const requiredValues = [
      values.name,
      values.investmentLag,
      values.bosLifespan,
      values.depreciationYearsBattery,
      values.depreciationYearsBos,
      values.batteryCapexValue,
      values.pcsCostValue,
      values.bosCostValue,
      values.fixedOpex,
      values.ratedPowerMw,
      values.ratedEnergyMwh,
      values.minAllowedSoh,
      values.minAllowedStateOfCharge,
      values.maxAllowedStateOfCharge,
      values.netOverallRoundTripEfficiency,
      values.minAllowedCRateForCharging,
      values.maxAllowedCRateForCharging,
      values.rateCapabilities,
      values.gridPowerConstraintInflow,
      values.gridPowerConstraintOutflow,
    ];

    if (requiredValues.some((value) => value == null)) {
      throw new Error('Some required values are undefined.');
    }

    if (!storageId) {
      throw new Error('Plant ID is undefined.');
    }

    try {
      setIsLoading(true);
      await storageApi.duplicateStorageSystem(storageId, {
        ...values,
      });
      notification.success({
        message: `${values.name} storage system has been duplicated successfully!`,
      });
      setIsLoading(false);
      resetDrawerData();
      mutate && mutate();
      setTimeout(() => {
        onClose();
      }, 500);
    } catch (error) {
      handleError(error);
      setIsLoading(false);
    }
  };

  const isDisabled = drawerOptions.type === 'view' ? true : false;

  const collapseItems: ExtendedCollapsePanelProps[] = [
    {
      key: 'description-section',
      header: <Typography.Text className="bold">Description</Typography.Text>,
      children: <Description disabled={isDisabled} />,
    },
    {
      key: 'financial-section',
      header: (
        <Typography.Text className="bold">Financial Metrics</Typography.Text>
      ),
      children: (
        <FinancialMetrics
          form={form}
          disabled={isDisabled}
          initialValues={initialFormValues}
        />
      ),
    },
    {
      key: 'battery-section',
      header: (
        <Typography.Text className="bold">Battery Capability</Typography.Text>
      ),
      children: <BatteryCapability form={form} disabled={isDisabled} />,
    },
    {
      key: 'rate-capability-section',
      header: (
        <Typography.Text className="bold">Rate Capability</Typography.Text>
      ),
      children: <RateCapability form={form} disabled={isDisabled} />,
    },
    {
      key: 'cycle-life-section',
      header: <Typography.Text className="bold">Cycle Life</Typography.Text>,
      children: <CycleLife form={form} disabled={isDisabled} />,
    },
    {
      key: 'grid-actions-section',
      header: <Typography.Text className="bold">Grid Actions</Typography.Text>,
      children: <GridActions disabled={isDisabled} />,
    },
    {
      key: 'ancillary-services-section',
      header: (
        <Typography.Text className="bold">Ancillary Services</Typography.Text>
      ),
      children: <AncillaryServices form={form} disabled={isDisabled} />,
    },
  ];

  const onFieldsChange: FormProps['onFieldsChange'] = () => {
    if (initialFormValues) {
      setDuplicateButtonDisabled(false);
    }
  };

  const resetFormFieldsToInitialValues = () => {
    if (initialFormValues) {
      form.setFieldsValue(initialFormValues);
      setDuplicateButtonDisabled(true);
    }
  };

  const overlayTextArray = useMemo(() => {
    return [
      'Configuring storage system settings.',
      'Analyzing file...',
      'The final touches are being made.',
    ];
  }, []);

  const [overlayText, setOverlayText] = useState<string>(overlayTextArray[0]);

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        if (currentIndex < overlayTextArray.length - 1) {
          setCurrentIndex(currentIndex + 1);
          setOverlayText(overlayTextArray[currentIndex + 1]);
        } else {
          clearInterval(interval);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [currentIndex, overlayTextArray, isLoading]);

  return (
    <StyledDrawer
      className={drawerOptions.type === 'view' ? 'view-drawer' : ''}
      title={title}
      description={description}
      rightSideSection={<RUsageLimitIndicator.Storages />}
      destroyOnClose
      open={!!type}
      onClose={onClose}
    >
      <Card className="px-16 py-16 content-card">
        {initialFormValues && (
          <Form<StorageSystemForm>
            onFieldsChange={onFieldsChange}
            className="form-styles"
            layout="vertical"
            initialValues={initialFormValues}
            form={form}
            onFinish={onFinish}
            scrollToFirstError={{
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            }}
          >
            <RCollapse
              defaultActiveKey={collapseItems[0].key}
              items={collapseItems}
            />
            {drawerOptions.type === 'duplicate' && (
              <Row
                className="bottom-button-group"
                align="middle"
                justify="space-between"
              >
                <Button onClick={onClose}>Cancel</Button>
                {!duplicateButtonDisabled && (
                  <div className="right-side-btn-group">
                    <Button
                      onClick={resetFormFieldsToInitialValues}
                      loading={isLoading}
                      disabled={isLoading}
                      className="ml-4"
                    >
                      Reset
                    </Button>
                    <Button
                      loading={isLoading}
                      disabled={isLoading}
                      className="ml-4"
                      type="primary"
                      htmlType="submit"
                    >
                      Duplicate
                    </Button>
                  </div>
                )}
              </Row>
            )}
          </Form>
        )}
      </Card>
      <RNotificationCard
        title="Data will be lost."
        message="Are you sure you want to leave?"
        type="warning"
        confirmText="Leave"
        cancelText="Cancel"
        open={notificationCardVisible}
        onConfirm={() => {
          resetDrawerData();
          setNotificationCardVisible(false);
          setDrawerOptions({
            type: null,
            data: null,
          });
        }}
        onCancel={() => setNotificationCardVisible(false)}
      />
      {isLoading && (
        <Row align="top" justify="center" className="pt-120 loading-wrapper">
          <Col>
            <Lottie
              style={{ height: '120px' }}
              animationData={loadingLottie}
              loop={true}
            />
            <Row>
              <AnimatedText className="animated-text" text={overlayText} />
            </Row>
          </Col>
        </Row>
      )}
    </StyledDrawer>
  );
};

export default StorageDrawer;
