import { useEffect, useState } from 'react';

import { Button, Flex, Typography, Spin } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useParams } from 'react-router';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ComposedChart,
  ResponsiveContainer,
  Line,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconCalender from '@/assets/icons/icon-calender.svg?react';
import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconSearch from '@/assets/icons/icon-search.svg?react';
import Card from '@/components/RCard';
import CustomGraphicTooltip from '@/components/RGraphicTooltip';
import RTooltip from '@/components/RTooltip';
import useCalculateEndDate, {
  EndDateFilters,
} from '@/hooks/useCalculateEndDate';
import {
  SimulationResult,
  SimulationResultEnergyActivity,
} from '@/types/simulations';
import { formatDateXAxis, formatNumberWithCommas, getTickStep } from '@/utils';

import StyledEnergyActivity from './styles';
import { StyledDatePicker, StyledSearch, StyledSelect } from '../../styles';

const EnergyActivity = () => {
  const resultId = useParams<Record<string, string>>();
  const [closedDataKeys, setClosedDataKeys] = useState<string[]>([]);
  const [filters, setFilters] = useState<EndDateFilters>({
    startDate: '',
    endDate: '',
    timeUnit: 'year',
    inputValue: undefined,
  });
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const theme = useTheme();

  useEffect(() => {
    if (simulationData?.startDate && simulationData?.endDate) {
      setFilters((prevState) => ({
        ...prevState,
        startDate: moment(simulationData?.startDate).toISOString(),
        endDate: moment(simulationData?.endDate).toISOString(),
      }));
    }
  }, [simulationData?.startDate, simulationData?.endDate]);

  useCalculateEndDate(filters, setFilters);
  const shouldFetchData =
    filters.startDate && filters.endDate && filters.timeUnit;

  const {
    data: energyActivityData,
    isLoading,
    error,
  } = useSWR<SimulationResultEnergyActivity>(
    shouldFetchData
      ? `/simulation/${resultId.resultId}/energy_activity/?startDatetime=${filters.startDate}&endDatetime=${filters.endDate}&timeUnit=${filters.timeUnit}`
      : null,
    {
      revalidateOnFocus: false,
    }
  );
  const isDataZero = energyActivityData?.graphData.length === 0;

  const constraintValues = {
    outflowStorage: energyActivityData?.gridPowerConstraintOutflowStorage || 0,
    inflowStorage: energyActivityData?.gridPowerConstraintInflowStorage || 0,
    outflowPlant: energyActivityData?.gridPowerConstraintOutflowPlant || 0,
    inflowPlant: energyActivityData?.gridPowerConstraintInflowPlant || 0,
  };

  const data =
    energyActivityData?.graphData.map((item) => {
      const filteredItem: {
        name: string;
        gridActivity: number;
        generation: number;
        batteryActivity: number;
        consumption: number;
        curtailed: number;
        outflowStorage: number;
        inflowStorage: number;
        outflowPlant: number;
        inflowPlant: number;
      } = {
        name: formatDateXAxis(item.dt, filters.timeUnit || 'year'),
        gridActivity: 0,
        generation: 0,
        batteryActivity: 0,
        consumption: 0,
        curtailed: 0,
        outflowStorage: constraintValues.outflowStorage,
        inflowStorage: constraintValues.inflowStorage,
        outflowPlant: constraintValues.outflowPlant,
        inflowPlant: constraintValues.inflowPlant,
      };
      if (!closedDataKeys.includes('gridActivity')) {
        filteredItem.gridActivity = item.gridActivity;
      }
      if (!closedDataKeys.includes('generation')) {
        filteredItem.generation = item.generation;
      }
      if (!closedDataKeys.includes('batteryActivity')) {
        filteredItem.batteryActivity = item.batteryActivity;
      }
      if (!closedDataKeys.includes('consumption')) {
        filteredItem.consumption = item.consumption;
      }
      return filteredItem;
    }) || [];

  const maxValue = Math.max(
    ...data.flatMap((item) => [
      item.gridActivity,
      item.generation,
      item.batteryActivity,
      item.consumption,
      item.outflowStorage,
      item.inflowStorage,
      item.outflowPlant,
      item.inflowPlant,
    ])
  );

  const minValue = Math.min(
    ...data.flatMap((item) => [
      item.gridActivity,
      item.generation,
      item.batteryActivity,
      item.consumption,
    ])
  );

  const roundToNearest = (num: number, nearest: number) => {
    return Math.round(num / nearest) * nearest;
  };

  const multiplier = filters.timeUnit === 'hour' ? 0.25 : 1.1;
  const tickStep = getTickStep(
    Math.max(Math.abs(minValue), Math.abs(maxValue))
  );
  const upperBound = roundToNearest(maxValue * multiplier, tickStep);
  const lowerBound = roundToNearest(minValue * multiplier, tickStep);
  const maxAbsBound = Math.max(Math.abs(upperBound), Math.abs(lowerBound));
  const domain = [-maxAbsBound, maxAbsBound];

  const divider = filters.timeUnit === 'hour' ? 1 : 2;
  const step = Math.ceil(maxAbsBound / divider);

  const ticks = [
    -2 * step,
    -1 * step,
    0,
    1 * step,
    2 * step,
    3 * step,
    4 * step,
    5 * step,
  ]
    .map((value) => roundToNearest(value, tickStep))
    .filter((value) => value !== 0 || maxValue !== 0 || minValue !== 0);

  const toggleClosedDataKeys = (key: string = '') => {
    if (closedDataKeys.includes(key)) {
      setClosedDataKeys(closedDataKeys.filter((item) => item !== key));
    } else {
      setClosedDataKeys([...closedDataKeys, key]);
    }
  };

  const handleTimeUnitChange = (value: unknown) => {
    setFilters({ ...filters, timeUnit: value as EndDateFilters['timeUnit'] });
  };

  const handleInputChange = (value: unknown) => {
    const inputValue = value as number;
    setFilters((prevState) => ({
      ...prevState,
      inputValue: inputValue <= 0 ? 1 : inputValue > 200 ? 200 : inputValue,
    }));
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current.isBefore(dayjs(simulationData?.startDate).startOf('day')) ||
        current.isAfter(dayjs(simulationData?.endDate).endOf('day')))
    );
  };

  const handleDatePickerChange = (date: unknown) => {
    const selectedDate = date as moment.Moment | null;
    if (selectedDate) {
      setFilters((prevState) => ({
        ...prevState,
        startDate: selectedDate
          ? selectedDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : '',
      }));
    }
  };

  const tickFormatter = (value: number) => {
    const absValue = Math.abs(value);
    if (absValue >= 1e6) {
      return `${(value / 1e6).toFixed(1)}M`;
    } else if (absValue >= 1e3) {
      return `${(value / 1e3).toFixed(0)}K`;
    } else {
      return `${value}`;
    }
  };

  return (
    <StyledEnergyActivity>
      <Card
        title={
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              Energy Activity
            </Typography.Title>
            <RTooltip
              title="Energy Activity"
              description="This graph shows energy activities on a yearly, monthly, daily,
              and hourly basis."
            >
              <IconInfo />
            </RTooltip>
          </Flex>
        }
        extra={
          <Flex gap={16} align="center">
            <Flex gap={8} align="center">
              <motion.div
                whileTap={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 24 }}
              >
                <StyledDatePicker
                  onChange={handleDatePickerChange}
                  placeholder="Select Date"
                  suffixIcon={<IconCalender />}
                  disabledDate={disabledDate}
                />
              </motion.div>
              <motion.div
                whileTap={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 24 }}
              >
                <StyledSelect
                  defaultValue="Year"
                  style={{ width: '109px' }}
                  onChange={handleTimeUnitChange}
                >
                  <StyledSelect.Option value="year">Year</StyledSelect.Option>
                  <StyledSelect.Option value="month">Month</StyledSelect.Option>
                  <StyledSelect.Option value="week">Week</StyledSelect.Option>
                  <StyledSelect.Option value="day">Day</StyledSelect.Option>
                  <StyledSelect.Option value="hour">Hour</StyledSelect.Option>
                </StyledSelect>
              </motion.div>
              <motion.div
                whileTap={{ scale: 1.1 }}
                transition={{ type: 'spring', stiffness: 300, damping: 24 }}
              >
                <StyledSearch
                  placeholder="120"
                  onChange={handleInputChange}
                  prefix={
                    <IconSearch style={{ height: '16px', width: 'auto' }} />
                  }
                  type="number"
                  value={filters.inputValue}
                  status={
                    filters.inputValue !== undefined &&
                    (filters.inputValue === 1 || filters.inputValue === 200)
                      ? 'warning'
                      : undefined
                  }
                />
              </motion.div>
            </Flex>
            <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
          </Flex>
        }
        $paddingBody="24px"
        style={{ width: '100%', marginTop: '24px' }}
        styles={{ header: { padding: '14px 24px' } }}
      >
        {isLoading ? (
          <Flex justify="center" align="center" style={{ height: 400 }}>
            <Spin style={{ margin: '20px' }} />
          </Flex>
        ) : error || isDataZero ? (
          <Flex
            vertical
            gap={12}
            justify="center"
            align="center"
            style={{ height: 400 }}
          >
            <IconError width={50} height={50} fill={theme.colors.tagFailure} />
            {error && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                An error occured while fetching data. Please check your inputs
                and contact support if the issue persists.
              </Typography.Text>
            )}
            {isDataZero && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                No data is currently available. Please review your inputs.
              </Typography.Text>
            )}
          </Flex>
        ) : (
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={data} stackOffset="sign">
              <CartesianGrid vertical={false} horizontal={true} />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis
                yAxisId="left"
                domain={domain}
                ticks={ticks}
                axisLine={false}
                tickLine={false}
                tickFormatter={tickFormatter}
                label={{
                  value: 'Energy (MWh)',
                  angle: -90,
                  dx: -25,
                }}
              />
              <Tooltip
                content={({ active, payload, label }) => {
                  const typedPayload = payload as Payload<number, string>[];
                  const curtailedValue =
                    Number(data.find((d) => d.name === label)?.curtailed) || 0;
                  const extraData =
                    curtailedValue !== 0
                      ? [
                          {
                            name: 'Curtailed',
                            value: (
                              <Typography.Text className="text-gray-color">
                                {formatNumberWithCommas(curtailedValue)}
                              </Typography.Text>
                            ),
                            unit: ' MWh',
                            color: theme.colors.greenPale,
                            type: 'circle',
                          },
                        ]
                      : undefined;
                  return (
                    <CustomGraphicTooltip
                      active={active}
                      payload={typedPayload}
                      label={label}
                      extraData={extraData}
                    />
                  );
                }}
              />
              <Legend
                payload={[
                  {
                    value: (
                      <Flex gap={8} align="center">
                        Grid Activity
                        <RTooltip
                          title="Grid Activity"
                          description="Purchased Energy - Sold Energy"
                        >
                          <IconInfo fill={theme.colors.grayPrimary} />
                        </RTooltip>
                      </Flex>
                    ),
                    id: 'gridActivity',
                    type: 'circle',
                    color: theme.colors.yellowAccent,
                    inactive: closedDataKeys.includes('gridActivity'),
                  },
                  {
                    value: (
                      <Flex gap={8} align="center">
                        Generation
                        <RTooltip
                          title="Generation"
                          description="Gross Generation"
                        >
                          <IconInfo fill={theme.colors.grayPrimary} />
                        </RTooltip>
                      </Flex>
                    ),
                    id: 'generation',
                    type: 'circle',
                    color: theme.colors.blueLightAccent,
                    inactive: closedDataKeys.includes('generation'),
                  },
                  {
                    value: (
                      <Flex gap={8} align="center">
                        Battery Activity
                        <RTooltip
                          title="Battery Activity"
                          description="Discharged Energy Amount - Charged Energy Amount"
                        >
                          <IconInfo fill={theme.colors.grayPrimary} />
                        </RTooltip>
                      </Flex>
                    ),
                    id: 'batteryActivity',
                    type: 'circle',
                    color: theme.colors.greenSecondary,
                    inactive: closedDataKeys.includes('batteryActivity'),
                  },
                  {
                    value: (
                      <Flex gap={8} align="center">
                        Consumption
                        <RTooltip
                          title={'Consumption'}
                          description="Internal Consumption"
                        >
                          <IconInfo fill={theme.colors.grayPrimary} />
                        </RTooltip>
                      </Flex>
                    ),
                    id: 'consumption',
                    type: 'circle',
                    color: theme.colors.purplePrimary,
                    inactive: closedDataKeys.includes('consumption'),
                  },
                ]}
                onClick={({ id }) => toggleClosedDataKeys(id)}
              />

              {filters.timeUnit === 'hour' && (
                <>
                  <Line
                    name="Grid Power Constraint Outflow Plant"
                    yAxisId="left"
                    type="monotone"
                    dataKey="outflowPlant"
                    stroke={theme.colors.blueLight}
                    strokeWidth={2}
                    unit={'MWh'}
                    strokeDasharray={'5 5'}
                    dot={false}
                    activeDot={{ r: 4 }}
                    isAnimationActive={false}
                  />
                  <Line
                    name="Grid Power Constraint Inflow Plant"
                    yAxisId="left"
                    type="monotone"
                    dataKey="inflowPlant"
                    stroke={theme.colors.blueLightAccent}
                    strokeWidth={2}
                    unit={'MWh'}
                    strokeDasharray={'5 5'}
                    dot={false}
                    activeDot={{ r: 4 }}
                    isAnimationActive={false}
                  />
                  <Line
                    name="Grid Power Constraint Outflow Storage"
                    yAxisId="left"
                    type="monotone"
                    dataKey="outflowStorage"
                    stroke={theme.colors.greenLight}
                    strokeWidth={2}
                    unit={'MWh'}
                    strokeDasharray={'5 5'}
                    dot={false}
                    activeDot={{ r: 4 }}
                    isAnimationActive={false}
                  />
                  <Line
                    name="Grid Power Constraint Inflow Storage"
                    yAxisId="left"
                    type="monotone"
                    dataKey="inflowStorage"
                    stroke={theme.colors.greenPale}
                    strokeWidth={2}
                    unit={'MWh'}
                    strokeDasharray={'5 5'}
                    dot={false}
                    activeDot={{ r: 4 }}
                    isAnimationActive={false}
                  />
                </>
              )}
              {!closedDataKeys.includes('consumption') && (
                <Bar
                  name="Consumption"
                  yAxisId="left"
                  stackId={1}
                  unit={'MWh'}
                  dataKey="consumption"
                  barSize={50}
                  fill={theme.colors.purplePrimary}
                />
              )}
              {!closedDataKeys.includes('batteryActivity') && (
                <Bar
                  name="Battery Activity"
                  yAxisId="left"
                  stackId={1}
                  unit={'MWh'}
                  dataKey="batteryActivity"
                  barSize={50}
                  fill={theme.colors.greenSecondary}
                />
              )}
              {!closedDataKeys.includes('generation') && (
                <Bar
                  name="Generation"
                  yAxisId="left"
                  stackId={1}
                  unit={'MWh'}
                  dataKey="generation"
                  barSize={50}
                  fill={theme.colors.blueLightAccent}
                />
              )}
              {!closedDataKeys.includes('gridActivity') && (
                <Bar
                  name="Grid Activity"
                  yAxisId="left"
                  stackId={1}
                  unit={'MWh'}
                  dataKey="gridActivity"
                  barSize={50}
                  fill={theme.colors.yellowAccent}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </Card>
    </StyledEnergyActivity>
  );
};
export default EnergyActivity;
