import { useEffect } from 'react';

import { Col, Row, Typography } from 'antd';
import { useTheme } from 'styled-components';

import IconPlants from '@/assets/icons/icon-plants.svg?react';
import IconScenarios from '@/assets/icons/icon-scenarios.svg?react';
import IconStorageSystems from '@/assets/icons/icon-storage-systems.svg?react';
import IconSuccess from '@/assets/icons/icon-success.svg?react';
import IconWarning from '@/assets/icons/icon-warning.svg?react';
import { useAssets } from '@/hooks/useAssets';

type Props = {
  setIsAssetsValid: (check: boolean) => void;
  formState: {
    simulationStartDate: string;
    years: number;
  };
};

const AssetsInformation: React.FC<Props> = ({
  setIsAssetsValid,
  formState,
}) => {
  const theme = useTheme();
  const {
    plantsData = { count: 0 },
    scenarioData = { count: 0 },
    storageSystemData = { count: 0 },
  } = useAssets(formState.simulationStartDate, formState.years);

  useEffect(() => {
    setIsAssetsValid(scenarioData.count > 0 && storageSystemData.count > 0);
  }, [scenarioData.count, storageSystemData.count, setIsAssetsValid]);

  const renderAssetRow = (
    icon: React.ReactNode,
    count: number,
    label: string,
    description: string
  ) => (
    <Row className="mt-24" align={'middle'}>
      <Col className="mt-8">{icon}</Col>
      <Col className="ml-8">
        <Typography.Text className="text-black-color">
          {count} {label} Found
        </Typography.Text>
        <Row align={'middle'}>
          {count === 0 ? (
            <IconWarning
              fill={theme.colors.yellowAccent}
              height={'16px'}
              width={'16px'}
            />
          ) : (
            <IconSuccess
              fill={theme.colors.tagSuccess}
              height={'16px'}
              width={'16px'}
            />
          )}
          <Typography.Text className="ml-8 smoke">
            {description}
          </Typography.Text>
        </Row>
      </Col>
    </Row>
  );

  return (
    <div>
      <Typography.Text className="fs-14-bold text-black-color">
        Assets Found
      </Typography.Text>

      {renderAssetRow(
        <IconPlants fill={theme.colors.dark} height={'32px'} width={'32px'} />,
        plantsData.count,
        'Plant',
        'You can create simulations without plants.'
      )}

      {renderAssetRow(
        <IconScenarios
          fill={theme.colors.dark}
          height={'32px'}
          width={'32px'}
        />,
        scenarioData.count,
        'Scenario',
        'You need one scenario to create a simulation.'
      )}

      {renderAssetRow(
        <IconStorageSystems
          fill={theme.colors.dark}
          height={'32px'}
          width={'32px'}
        />,
        storageSystemData.count,
        'Storage System',
        'You need one storage system to create a simulation.'
      )}
    </div>
  );
};

export default AssetsInformation;
