import {
  Row,
  Typography,
  Dropdown,
  MenuProps,
  Button,
  Modal,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';

import { storageApi } from '@/api';
import DuplicateIcon from '@/assets/icons/icon-folder.svg?react';
import InfoIcon from '@/assets/icons/icon-info.svg?react';
import DeactivateIcon from '@/assets/icons/icon-power.svg?react';
import VerticalEllipsisIcon from '@/assets/icons/icon-three-dots-vertical.svg?react';
import ViewIcon from '@/assets/icons/icon-view-second.svg?react';
import RNotificationCard from '@/components/RNotificationCard';
import RTag from '@/components/RTag';
import CustomTooltip from '@/components/RTooltip';
import { DrawerType } from '@/types/global';
import { StorageSystem } from '@/types/storageSystem';
import { capitalizeFirstLetter, handleError } from '@/utils';

const useStorageSystemsTableColumns = (
  mutate: () => void,
  updateDrawer: (
    type: DrawerType<StorageSystem>['type'],
    data: DrawerType<StorageSystem>['data']
  ) => void
) => {
  const theme = useTheme();
  const [modal, contextHolder] = Modal.useModal();

  const handleActivate = async (record: StorageSystem) => {
    return modal.confirm({
      closeIcon: false,
      footer: null,
      width: 475,
      modalRender: () => (
        <RNotificationCard
          title={`Activating "${capitalizeFirstLetter(record.name)}"`}
          message="Are you sure you want to activate this storage system?"
          type="warning"
          confirmText="Yes"
          cancelText="Cancel"
          open={!record.isActive}
          onCancel={() => Modal.destroyAll()}
          onConfirm={async () => {
            try {
              await storageApi.activateStorageSystem(record.id);
              mutate();
              Modal.destroyAll();
              notification.success({
                message: `Successfully Activated Storage System "${capitalizeFirstLetter(
                  record.name
                )}"`,
              });
            } catch (error) {
              Modal.destroyAll();
              handleError(error);
            }
          }}
        />
      ),
    });
  };

  const handleDeactivate = async (record: StorageSystem) => {
    return modal.confirm({
      // TODO CHANGE THIS OPEN LOGIC
      closeIcon: false,
      footer: null,
      width: 475,
      modalRender: () => (
        <RNotificationCard
          title={`Deactivating "${capitalizeFirstLetter(record.name)}"`}
          message="Are you sure you want to deactivate this storage system?"
          type="warning"
          confirmText="Yes"
          cancelText="Cancel"
          open={record.isActive}
          onCancel={() => Modal.destroyAll()}
          onConfirm={async () => {
            try {
              await storageApi.deactiveStorageSystem(record.id);
              mutate();
              Modal.destroyAll();
              notification.success({
                message: `Successfully Deactivated Storage System "${capitalizeFirstLetter(
                  record.name
                )}"`,
              });
            } catch (error) {
              Modal.destroyAll();
              handleError(error);
            }
          }}
        />
      ),
    });
  };

  const handleView = (record: StorageSystem) => {
    updateDrawer('view', record);
  };

  const handleDuplicate = (record: StorageSystem) => {
    updateDrawer('duplicate', record);
  };

  const generateItems = (record: StorageSystem) => {
    const items: MenuProps['items'] = [
      {
        label: 'View',
        key: 'view-scenario',
        icon: <ViewIcon style={{ width: '16px', height: '16px' }} />,
      },
      {
        label: 'Duplicate',
        key: 'duplicate-scenario',
        icon: <DuplicateIcon style={{ width: '16px', height: '16px' }} />,
      },
    ];

    if (record.isActive) {
      items.push({
        label: 'Deactivate',
        key: 'deactivate-scenario',
        icon: <DeactivateIcon style={{ width: '16px', height: '16px' }} />,
      });
    } else {
      items.push({
        label: 'Activate',
        key: 'activate-scenario',
        icon: <DeactivateIcon style={{ width: '16px', height: '16px' }} />,
      });
    }

    return items;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMenuClick = (info: any, record: StorageSystem) => {
    switch (info.key) {
      case 'view-scenario':
        handleView(record);
        break;
      case 'duplicate-scenario':
        handleDuplicate(record);
        break;
      case 'deactivate-scenario':
        handleDeactivate(record);
        break;
      case 'activate-scenario':
        handleActivate(record);
        break;
      default:
        break;
    }
  };

  function getRTEColor(rte: number) {
    if (rte < 45) return theme.colors.red;
    if (rte < 70) return theme.colors.orangePrimary;
    return theme.colors.greenPrimary;
  }

  const columns: ColumnsType<StorageSystem> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '%15',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{ maxWidth: '200px' }}
            className="text"
            ellipsis={{
              rows: 1,
              tooltip: name,
              expandable: false,
            }}
          >
            {name}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '%25',
      render: (description) => (
        <Row align="middle">
          <Typography.Paragraph
            className="text"
            style={{ maxWidth: '200px' }}
            ellipsis={{
              rows: 1,
              tooltip: description,
              expandable: false,
            }}
          >
            {description}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Round-Trip Eff.',
      dataIndex: 'netOverallRoundTripEfficiency',
      key: 'netOverallRoundTripEfficiency',
      sorter: (a, b) =>
        a.netOverallRoundTripEfficiency - b.netOverallRoundTripEfficiency,
      render: (text) => (
        <Row align="middle" justify="end">
          {text ? (
            <Typography.Text style={{ color: getRTEColor(text) }}>
              <b>{text}%</b>
            </Typography.Text>
          ) : (
            <Typography.Text>
              <b>-</b>
            </Typography.Text>
          )}
        </Row>
      ),
    },
    {
      title: 'Rt. Energy',
      dataIndex: 'ratedEnergyMwh',
      key: 'ratedEnergyMwh',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => (
        <Row align="middle" justify="end">
          <Typography.Text>{text}</Typography.Text>
        </Row>
      ),
    },
    {
      title: 'Rt. Power',
      dataIndex: 'ratedPowerMw',
      key: 'ratedPowerMw',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => (
        <Row align="middle" justify="end">
          <Typography.Text>{text}</Typography.Text>
        </Row>
      ),
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{ maxWidth: '200px' }}
            className="text"
            ellipsis={{
              rows: 1,
              tooltip: data,
              expandable: false,
            }}
          >
            {dayjs(data).format('DD.MM.YYYY HH:MM')}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: (
        <CustomTooltip
          className="cursor-pointer"
          title="Status"
          description="Assets can be either active or inactive. If the status is active, this asset will be visible when creating a simulation."
          trigger="click"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Status</Typography.Text>
            <InfoIcon fill={theme.colors.dark} />
          </Row>
        </CustomTooltip>
      ),
      sorter: (a, b) => String(a.isActive).localeCompare(String(b.isActive)),
      dataIndex: 'isActive',
      key: 'isActive ',
      align: 'left',
      width: '%10',
      render: (dataIndex) =>
        dataIndex ? <RTag type="active" /> : <RTag type="inactive" />,
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions ',
      width: '60px',
      render: (_, record: StorageSystem) => (
        <>
          {contextHolder}
          <Dropdown
            trigger={['click']}
            menu={{
              items: generateItems(record),
              onClick: (info) => handleMenuClick(info, record),
            }}
            placement="bottomRight"
          >
            <Button
              className="default-icon-button"
              type="default"
              icon={<VerticalEllipsisIcon fill={theme.colors.bluePrimary} />}
            ></Button>
          </Dropdown>
        </>
      ),
    },
  ];

  return columns;
};

export default useStorageSystemsTableColumns;
