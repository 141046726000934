import { Button, Row, Typography, Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconResult from '@/assets/icons/icon-sim-result.svg?react';
import TagTable from '@/components/RTag';
import Tooltip from '@/components/RTooltip';
import { DrawerType } from '@/types/global';
import { Plant } from '@/types/plant';
import { Scenario } from '@/types/scenario';
import { Simulation } from '@/types/simulations';
import { StorageSystem } from '@/types/storageSystem';

type Props = {
  handleFormsClick: (type: string, assetID: number) => void;
  setDrawerPlant: React.Dispatch<React.SetStateAction<DrawerType<Plant>>>;
  setDrawerScenario: React.Dispatch<React.SetStateAction<DrawerType<Scenario>>>;
  setDrawerStorageSystem: React.Dispatch<
    React.SetStateAction<DrawerType<StorageSystem>>
  >;
};

const useNotRunningSimulationsColumns = (props: Props) => {
  const columns: ColumnsType<Simulation> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, simulation: Simulation) => (
        <Typography.Paragraph
          style={{ maxWidth: '100px' }}
          className="simulation-item underline text"
          ellipsis={{
            rows: 1,
            tooltip: name,
            expandable: false,
          }}
          onClick={() => {
            if (simulation.status === 'SUCCESS') {
              window.location.replace(`/simulations/result/${simulation.id}`);
            }
          }}
        >
          {name}
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description) => (
        <Typography.Paragraph
          style={{ maxWidth: '100px' }}
          className="text"
          ellipsis={{
            rows: 1,
            tooltip: description,
            expandable: false,
          }}
        >
          {description}
        </Typography.Paragraph>
      ),
    },
    {
      title: (
        <Tooltip
          className="cursor-pointer"
          trigger="click"
          title="Storage System"
          description="Saved storage systems for different simulations. Each storage systems parameters can be edited for different simulations."
        >
          <Row
            align="middle"
            justify="start"
            className="w-100"
            style={{ minWidth: '120px' }}
          >
            <Typography.Text className="pr-8">Storage System</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'storage',
      key: 'storage',
      sorter: (a, b) => {
        const nameA = a.storage?.name ?? '';
        const nameB = b.storage?.name ?? '';

        return nameA.localeCompare(nameB);
      },
      render: (dataIndex) => (
        <Typography.Paragraph
          className="simulation-item underline text"
          style={{ maxWidth: '150px' }}
          ellipsis={{
            rows: 1,
            tooltip: dataIndex,
            expandable: false,
          }}
          onClick={() => {
            props.setDrawerStorageSystem(dataIndex);
            props.handleFormsClick('storage', dataIndex.id);
          }}
          key={dataIndex.id}
        >{`${dataIndex.name}`}</Typography.Paragraph>
      ),
    },
    {
      title: (
        <Tooltip
          className="cursor-pointer"
          trigger="click"
          title="Scenario"
          description="Scenarios include different load and price forecasts that can be associated with different storage systems and different plants for different simulations. Each forecast file related with a scenario can be edited"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Scenario</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'scenario',
      key: 'scenario ',
      sorter: (a, b) => {
        const nameA = a.scenario?.name ?? '';
        const nameB = b.scenario?.name ?? '';

        return nameA.localeCompare(nameB);
      },
      render: (dataIndex) => (
        <Typography.Paragraph
          className="simulation-item underline"
          style={{ maxWidth: '120px' }}
          ellipsis={{
            rows: 1,
            tooltip: dataIndex,
            expandable: false,
          }}
          onClick={() => {
            props.setDrawerScenario(dataIndex);
            props.handleFormsClick('scenario', dataIndex.id);
          }}
          key={dataIndex.id}
        >{`${dataIndex.name}`}</Typography.Paragraph>
      ),
    },
    {
      title: (
        <Tooltip
          className="cursor-pointer"
          trigger="click"
          title="Plants"
          description="Wind, PV or Diesel Generator power plants that can be combined with a storage plant. You can edit previously created plant variables from here."
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Plants</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'plants',
      key: 'plants',
      sorter: (a, b) => {
        const nameA = a.plants[0]?.name ?? '';
        const nameB = b.plants[0]?.name ?? '';

        return nameA.localeCompare(nameB);
      },
      render: (dataIndex) => (
        <Flex gap={5}>
          {dataIndex.map((plant: Plant) => (
            <Typography.Paragraph
              className="simulation-item underline"
              style={{
                maxWidth:
                  dataIndex.length > 3
                    ? '50px'
                    : dataIndex.length == 3
                      ? '90px'
                      : dataIndex.length == 2
                        ? '100px'
                        : dataIndex.length == 1
                          ? '250px'
                          : '100px',
              }}
              ellipsis={{
                rows: 1,
                tooltip: plant.name,
                expandable: false,
              }}
              onClick={() => {
                props.setDrawerPlant({ type: 'view', data: plant });
              }}
              key={plant.id}
            >{`${plant.name}`}</Typography.Paragraph>
          ))}
        </Flex>
      ),
    },
    {
      title: <div style={{ minWidth: '120px' }}>Completion Date</div>,
      dataIndex: 'dateDone',
      key: 'dateDone',
      align: 'start',
      width: 180,
      render: (dataIndex) => moment(dataIndex).format('DD.MM.YYYY HH:mm'),
      sorter: (a, b) =>
        new Date(a.dateDone).getTime() - new Date(b.dateDone).getTime(),
    },
    {
      title: (
        <Tooltip
          className="cursor-pointer"
          trigger="click"
          title="Status"
          description="Status of the simulation. If the simulation is successful, you can view the results."
        >
          <Row
            align="middle"
            justify="start"
            className="w-100"
            style={{ minWidth: '70px' }}
          >
            <Typography.Text className="pr-8">Status</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'status',
      key: 'status',
      align: 'start',
      width: 114,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (dataIndex, record) =>
        dataIndex === 'SUCCESS' ? (
          <TagTable type={'success'} />
        ) : dataIndex === 'FAILURE' ? (
          <Tooltip
            title="Error"
            description={record.errorMessage}
            placement="top"
          >
            <div>
              <TagTable type={'failure'}>{dataIndex}</TagTable>
            </div>
          </Tooltip>
        ) : dataIndex === 'REVOKED' ? (
          <TagTable type={'revoked'}>{dataIndex}</TagTable>
        ) : dataIndex === 'INACTIVE' ? (
          <TagTable type={'inactive'}>{dataIndex}</TagTable>
        ) : dataIndex === 'ACTIVE' ? (
          <TagTable type={'active'}>{dataIndex}</TagTable>
        ) : (
          <></>
        ),
    },
    {
      title: 'Results',
      dataIndex: 'actions',
      key: 'actions',
      width: 90,
      align: 'center',
      render: (_, simulation: Simulation) => (
        <>
          {' '}
          {simulation.status === 'SUCCESS' && (
            <Tooltip
              title="View Results"
              description="View detailed energy and financial analyses of the simulation."
            >
              <Button
                className="default-icon-button"
                type="default"
                onClick={() => {
                  window.location.replace(
                    `/simulations/result/${simulation.id}`
                  );
                }}
                icon={<IconResult />}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return columns;
};

export default useNotRunningSimulationsColumns;
