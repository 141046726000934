import { useEffect, useState } from 'react';

import useSWR from 'swr';

import { Plant, PlantsResponse } from '@/types/plant';
import { Scenario, ScenariosResponse } from '@/types/scenario';
import { StorageSystem, StorageSystemsResponse } from '@/types/storageSystem';

export const useAssets = (simulationStartDate: string, years: number) => {
  const [assets, setAssets] = useState({
    plants: [] as Plant[],
    scenarios: [] as Scenario[],
    storageSystems: [] as StorageSystem[],
  });

  const pageSize = 7;
  const [offset, setOffset] = useState({
    plants: 1,
    scenario: 1,
    storage: 1,
  });

  const buildQuery = (type: string, offset: number) => {
    return `/${type}/?limit=${pageSize}&offset=${
      (offset - 1) * pageSize
    }&numYears=${years}&startDate=${simulationStartDate}&isActive=true`;
  };

  const { data: plantsData, mutate: plantsDataMutate } = useSWR<PlantsResponse>(
    simulationStartDate ? buildQuery('plant', offset.plants) : null
  );

  const { data: scenarioData, mutate: scenarioDataMutate } =
    useSWR<ScenariosResponse>(
      simulationStartDate ? buildQuery('scenario', offset.scenario) : null
    );

  const { data: storageSystemData, mutate: storageSystemMutate } =
    useSWR<StorageSystemsResponse>(
      simulationStartDate ? buildQuery('storage', offset.storage) : null
    );

  useEffect(() => {
    if (plantsData)
      setAssets((prev) => ({ ...prev, plants: plantsData.results }));
    if (scenarioData)
      setAssets((prev) => ({ ...prev, scenarios: scenarioData.results }));
    if (storageSystemData)
      setAssets((prev) => ({
        ...prev,
        storageSystems: storageSystemData.results,
      }));
  }, [plantsData, scenarioData, storageSystemData]);

  return {
    assets,
    pageSize,
    setAssets,
    setOffset,
    plantsData,
    scenarioData,
    storageSystemData,
    plantsDataMutate,
    scenarioDataMutate,
    storageSystemMutate,
  };
};
