import { useEffect, useState } from 'react';

import { Button, Flex, Spin, Typography } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useParams } from 'react-router';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconCalender from '@/assets/icons/icon-calender.svg?react';
import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconSearch from '@/assets/icons/icon-search.svg?react';
import Card from '@/components/RCard';
import CustomGraphicTooltip from '@/components/RGraphicTooltip';
import RToggleButtons from '@/components/RToggleButtons';
import RTooltip from '@/components/RTooltip';
import useCalculateEndDate, {
  EndDateFilters,
} from '@/hooks/useCalculateEndDate';
import {
  SimulationResult,
  SimulationResultFinancialActivity,
} from '@/types/simulations';
import { formatDateXAxis, getTickStep, tickFormatter } from '@/utils';

import StyledEbitda from './styles';
import { StyledSearch, StyledSelect, StyledDatePicker } from '../../styles';

const Ebitda = () => {
  const resultId = useParams<Record<string, string>>();
  const [closedDataKeys, setClosedDataKeys] = useState<string[]>([]);
  const [selected, setSelected] = useState('Quickview');
  const [filters, setFilters] = useState<EndDateFilters>({
    startDate: '',
    endDate: '',
    timeUnit: 'year',
    inputValue: undefined,
  });
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const theme = useTheme();
  const isOnlyPurchaseEnergy = closedDataKeys.length === 4;

  const minRevenueValueMultiplier = isOnlyPurchaseEnergy ? 4 : 0.6;

  useEffect(() => {
    // Sadece gerekli olduğunda state güncellemesi yapın
    if (simulationData?.startDate && simulationData?.endDate) {
      setFilters((prevState) => ({
        ...prevState,
        startDate: moment(simulationData.startDate).toISOString(),
        endDate: moment(simulationData.endDate).toISOString(),
      }));
    }
  }, [simulationData?.startDate, simulationData?.endDate]);

  useCalculateEndDate(filters, setFilters);

  const shouldFetchData =
    filters.startDate && filters.endDate && filters.timeUnit;

  const {
    data: financialActivityData,
    isLoading,
    error,
  } = useSWR<SimulationResultFinancialActivity>(
    shouldFetchData
      ? `/simulation/${resultId.resultId}/financial_activity/?startDatetime=${filters.startDate}&endDatetime=${filters.endDate}&timeUnit=${filters.timeUnit}`
      : null,
    {
      revalidateOnFocus: false,
    }
  );
  const isDataZero = financialActivityData?.graphData.length === 0;

  const data =
    financialActivityData?.graphData.map((item) => {
      const filteredItem: {
        name: string;
        fromArbitrage?: number;
        fromPlant?: number;
        fromDischargedPlant?: number;
        ancillaryServices?: number;
        purchasedEnergy?: number;
      } = {
        name: formatDateXAxis(item.dt, filters.timeUnit || 'year'),
      };

      if (
        !closedDataKeys.includes('fromArbitrage') &&
        item.revenueFromArbitrage !== undefined
      ) {
        filteredItem.fromArbitrage = item.revenueFromArbitrage;
      }
      if (
        !closedDataKeys.includes('fromPlant') &&
        item.revenueFromPlant !== undefined
      ) {
        filteredItem.fromPlant = item.revenueFromPlant;
      }
      if (
        !closedDataKeys.includes('fromDischargedPlant') &&
        item.revenueFromChargedEnergyFromPlant !== undefined
      ) {
        filteredItem.fromDischargedPlant =
          item.revenueFromChargedEnergyFromPlant;
      }
      if (
        !closedDataKeys.includes('ancillaryServices') &&
        item.revenueFromAncillaryServices !== undefined
      ) {
        filteredItem.ancillaryServices = item.revenueFromAncillaryServices;
      }
      if (
        !closedDataKeys.includes('purchasedEnergy') &&
        item.costOfPurchasedEnergy !== undefined
      ) {
        filteredItem.purchasedEnergy = item.costOfPurchasedEnergy;
      }

      return filteredItem;
    }) || [];

  const maxRevenueValue = Math.max(
    ...data.flatMap((item) => [
      Math.abs(item.fromArbitrage || 0) +
        Math.abs(item.fromPlant || 0) +
        Math.abs(item.fromDischargedPlant || 0) +
        Math.abs(item.ancillaryServices || 0) +
        Math.abs(item.purchasedEnergy || 0),
    ])
  );

  const minRevenueValue = Math.min(
    ...data.flatMap((item) => [
      Math.abs(item.fromArbitrage || 0) +
        Math.abs(item.fromPlant || 0) +
        Math.abs(item.fromDischargedPlant || 0) +
        Math.abs(item.ancillaryServices || 0) +
        Math.abs(item.purchasedEnergy || 0),
    ])
  );

  const roundToNearest = (num: number, nearest: number) => {
    return Math.round(num / nearest) * nearest;
  };

  const tickStep = getTickStep(
    Math.max(Math.abs(minRevenueValue), Math.abs(maxRevenueValue))
  );
  const upperBound = roundToNearest(maxRevenueValue * 0.6, tickStep);
  const lowerBound = roundToNearest(
    minRevenueValue * minRevenueValueMultiplier,
    tickStep
  );
  const maxAbsBound = Math.max(Math.abs(upperBound), Math.abs(lowerBound));
  const domain = !closedDataKeys.includes('purchasedEnergy')
    ? [-maxAbsBound, maxAbsBound]
    : [-maxAbsBound, 0];

  const step = Math.ceil(maxAbsBound / 2);

  const ticks =
    !closedDataKeys.includes('purchasedEnergy') && isOnlyPurchaseEnergy
      ? [-4 * step, -3 * step, -2 * step, -1 * step, 0, 1 * step, 2 * step]
          .map((value) => roundToNearest(value, tickStep))
          .filter(
            (value) =>
              value !== 0 || maxRevenueValue !== 0 || minRevenueValue !== 0
          )
      : [-2 * step, -1 * step, 0, 1 * step, 2 * step, 3 * step, 4 * step]
          .map((value) => roundToNearest(value, tickStep))
          .filter(
            (value) =>
              value !== 0 || maxRevenueValue !== 0 || minRevenueValue !== 0
          );
  const toggleClosedDataKeys = (key: string = '') => {
    if (closedDataKeys.includes(key)) {
      setClosedDataKeys(closedDataKeys.filter((item) => item !== key));
    } else {
      setClosedDataKeys([...closedDataKeys, key]);
    }
  };

  const handleViewChange = (view: string) => {
    setSelected(view);
  };

  const handleTimeUnitChange = (value: unknown) => {
    setFilters({ ...filters, timeUnit: value as EndDateFilters['timeUnit'] });
  };

  const handleInputChange = (value: unknown) => {
    const inputValue = value as number;
    setFilters((prevState) => ({
      ...prevState,
      inputValue: inputValue <= 0 ? 1 : inputValue > 200 ? 200 : inputValue,
    }));
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current.isBefore(dayjs(simulationData?.startDate).startOf('day')) ||
        current.isAfter(dayjs(simulationData?.endDate).endOf('day')))
    );
  };

  const handleDatePickerChange = (date: unknown) => {
    const selectedDate = date as moment.Moment | null;
    if (selectedDate) {
      setFilters((prevState) => ({
        ...prevState,
        startDate: selectedDate
          ? selectedDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : '',
      }));
    }
  };

  return (
    <StyledEbitda>
      <Card
        title={
          <Flex gap={8} align="center">
            <Typography.Title level={4} className="fs-17-bold">
              EBITDA
            </Typography.Title>
            <RTooltip
              title="EBITDA"
              description="Overview of financial performance, highlighting the balance
              between revenues and costs."
            >
              <IconInfo />
            </RTooltip>
          </Flex>
        }
        extra={
          <Flex gap={16} align="center">
            <Flex gap={8} align="center">
              {selected === 'Detailed' && (
                <>
                  <motion.div
                    whileTap={{ scale: 1.1 }}
                    transition={{ type: 'spring', stiffness: 300, damping: 24 }}
                  >
                    <StyledDatePicker
                      onChange={handleDatePickerChange}
                      placeholder="Select Date"
                      suffixIcon={<IconCalender />}
                      disabledDate={disabledDate}
                    />
                  </motion.div>
                  <motion.div
                    whileTap={{ scale: 1.1 }}
                    transition={{ type: 'spring', stiffness: 300, damping: 24 }}
                  >
                    <StyledSelect
                      defaultValue="Year"
                      style={{ width: '109px' }}
                      onChange={handleTimeUnitChange}
                    >
                      <StyledSelect.Option value="year">
                        Year
                      </StyledSelect.Option>
                      <StyledSelect.Option value="month">
                        Month
                      </StyledSelect.Option>
                      <StyledSelect.Option value="week">
                        Week
                      </StyledSelect.Option>
                      <StyledSelect.Option value="day">Day</StyledSelect.Option>
                      <StyledSelect.Option value="hour">
                        Hour
                      </StyledSelect.Option>
                    </StyledSelect>
                  </motion.div>
                  <motion.div
                    whileTap={{ scale: 1.1 }}
                    transition={{ type: 'spring', stiffness: 300, damping: 24 }}
                  >
                    <StyledSearch
                      placeholder="120"
                      onChange={handleInputChange}
                      prefix={
                        <IconSearch style={{ height: '16px', width: 'auto' }} />
                      }
                      type="number"
                      value={filters.inputValue}
                      status={
                        filters.inputValue !== undefined &&
                        (filters.inputValue === 1 || filters.inputValue === 200)
                          ? 'warning'
                          : undefined
                      }
                    />
                  </motion.div>
                </>
              )}
              <RToggleButtons
                labels={['Detailed', 'Quickview']}
                selectedLabel={selected}
                onLabelChange={handleViewChange}
              />
            </Flex>
            <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
          </Flex>
        }
        $paddingBody="24px"
        styles={{ header: { padding: '14px 24px' } }}
        style={{ width: '100%', marginTop: '24px' }}
      >
        {isLoading ? (
          <Flex justify="center" align="center" style={{ height: 400 }}>
            <Spin style={{ margin: '20px' }} />
          </Flex>
        ) : error || isDataZero ? (
          <Flex
            vertical
            gap={12}
            justify="center"
            align="center"
            style={{ height: 400 }}
          >
            <IconError width={50} height={50} fill={theme.colors.tagFailure} />
            {error && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                An error occured while fetching data. Please check your inputs
                and contact support if the issue persists.
              </Typography.Text>
            )}
            {isDataZero && (
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                No data is currently available. Please review your inputs.
              </Typography.Text>
            )}
          </Flex>
        ) : (
          <ResponsiveContainer width="100%" height={415}>
            <BarChart data={data} stackOffset="sign">
              <CartesianGrid vertical={false} horizontal={true} />
              <XAxis dataKey="name" axisLine={false} tickLine={false} />
              <YAxis
                domain={domain}
                tickFormatter={tickFormatter}
                ticks={ticks}
                tick={{ dx: -9 }}
                axisLine={false}
                tickLine={false}
              />
              <Tooltip content={<CustomGraphicTooltip />} />
              <Legend
                payload={[
                  {
                    value: 'Revenue of Directly Sold Energy from Plant',
                    type: 'circle',
                    id: 'fromPlant',
                    color: theme.colors.purpleLight,
                    inactive: closedDataKeys.includes('fromPlant'),
                  },
                  {
                    value: 'Revenue from Discharged Energy from Plant',
                    type: 'circle',
                    id: 'fromDischargedPlant',
                    color: theme.colors.yellowPrimary,
                    inactive: closedDataKeys.includes('fromDischargedPlant'),
                  },
                  {
                    value: 'Revenue from Arbitrage',
                    type: 'circle',
                    id: 'fromArbitrage',
                    color: theme.colors.blueGray,
                    inactive: closedDataKeys.includes('fromArbitrage'),
                  },
                  {
                    value: 'Revenue from Ancillary Services',
                    type: 'circle',
                    id: 'ancillaryServices',
                    color: theme.colors.blueLight,
                    inactive: closedDataKeys.includes('ancillaryServices'),
                  },
                  {
                    value: 'Cost of Purchased Energy (Arbitrage & Consumption)',
                    id: 'purchasedEnergy',
                    type: 'circle',
                    color: theme.colors.greenSecondary,
                    inactive: closedDataKeys.includes('purchasedEnergy'),
                  },
                ]}
                onClick={({ id }) => toggleClosedDataKeys(id)}
                iconSize={10}
              />

              {!closedDataKeys.includes('purchasedEnergy') && (
                <Bar
                  dataKey="purchasedEnergy"
                  name="Cost of Purchased Energy (Arbitrage & Consumption)"
                  stackId="a"
                  fill={theme.colors.greenSecondary}
                  barSize={50}
                  unit={'$'}
                />
              )}
              {!closedDataKeys.includes('ancillaryServices') && (
                <Bar
                  dataKey="ancillaryServices"
                  name="Revenue from Ancillary Services"
                  stackId="a"
                  fill={theme.colors.blueLight}
                  barSize={50}
                  unit={'$'}
                />
              )}
              {!closedDataKeys.includes('fromArbitrage') && (
                <Bar
                  dataKey="fromArbitrage"
                  name="Revenue from Arbitrage"
                  stackId="a"
                  fill={theme.colors.blueGray}
                  barSize={50}
                  unit={'$'}
                />
              )}
              {!closedDataKeys.includes('fromDischargedPlant') && (
                <Bar
                  dataKey="fromDischargedPlant"
                  name="Revenue from Discharged Energy from Plant"
                  stackId="a"
                  fill={theme.colors.yellowPrimary}
                  barSize={50}
                  unit={'$'}
                />
              )}
              {!closedDataKeys.includes('fromPlant') && (
                <Bar
                  dataKey="fromPlant"
                  name="Revenue of Directly Sold Energy from Plant"
                  stackId="a"
                  fill={theme.colors.purpleLight}
                  barSize={50}
                  unit={'$'}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        )}
      </Card>
    </StyledEbitda>
  );
};
export default Ebitda;
