/* eslint @typescript-eslint/no-explicit-any: "off" */
import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';

import Interceptor from './Interceptor';

declare module 'axios' {
  export interface AxiosRequestConfig {
    keysToAvoidConversion?: string[];
  }
}
class HttpService {
  axiosInstance: AxiosInstance;
  interceptor: any;
  baseURL: string;

  constructor(options: AxiosRequestConfig) {
    this.baseURL = options.baseURL!;

    this.axiosInstance = axios.create({
      baseURL: options.baseURL,
      ...options,
    });
    this.interceptor = new Interceptor(this.axiosInstance);
  }

  async get(url: string, config?: AxiosRequestConfig) {
    const response: any = await this.axiosInstance.get(url, config);
    return response;
  }

  async post(
    url: string,
    payload?: Record<string, any>,
    config?: AxiosRequestConfig
  ) {
    const response: any = await this.axiosInstance.post(url, payload, config);
    return response;
  }

  async put(
    url: string,
    payload?: Record<string, any>,
    config?: AxiosRequestConfig
  ) {
    const response: any = await this.axiosInstance.put(url, payload, config);
    return response;
  }

  async patch(
    url: string,
    payload?: Record<string, any>,
    config?: AxiosRequestConfig
  ) {
    const response: any = await this.axiosInstance.patch(url, payload, config);
    return response;
  }

  async delete(url: string, config?: AxiosRequestConfig) {
    const response: any = await this.axiosInstance.delete(url, config);
    return response;
  }

  async postFormData(
    url: string,
    payload?: Record<string, any>,
    config?: AxiosRequestConfig
  ) {
    const formData = new FormData();
    Object.entries(payload || {}).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    const response: any = await this.axiosInstance.post(url, formData, {
      ...config,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }
}

export default HttpService;
