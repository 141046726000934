import { useEffect, useState } from 'react';

import { Button, Flex, Row, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { motion } from 'framer-motion';
import Lottie from 'lottie-react';
import moment from 'moment';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconDeactivate from '@/assets/icons/icon-power.svg?react';
import SandWatchAnimation from '@/assets/lotties/sand-watch.json';
import TagTable from '@/components/RTag';
import Tooltip from '@/components/RTooltip';
import { DrawerType } from '@/types/global';
import { Plant } from '@/types/plant';
import { Scenario } from '@/types/scenario';
import { Simulation } from '@/types/simulations';
import { StorageSystem } from '@/types/storageSystem';

//TODO ADD QUEUED
type Props = {
  handleFormsClick: (type: string, assetID: number) => void;
  setDrawerPlant: React.Dispatch<React.SetStateAction<DrawerType<Plant>>>;
  setDrawerScenario: React.Dispatch<React.SetStateAction<DrawerType<Scenario>>>;
  setDrawerStorageSystem: React.Dispatch<
    React.SetStateAction<DrawerType<StorageSystem>>
  >;
  setStopSimulationDrawerOpen: (bool: boolean) => void;
  setSelectedSimulation: (type: string) => void;
};

const useRunningSimulationsColumns = (props: Props) => {
  const [dots, setDots] = useState(''); // 3 nokta animasyonu için

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const columns: ColumnsType<Simulation> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) => (
        <Typography.Paragraph
          style={{ maxWidth: '100px' }}
          className="text simulation-item underline"
          ellipsis={{
            rows: 1,
            tooltip: name,
            expandable: false,
          }}
        >
          {name}
        </Typography.Paragraph>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description) => (
        <Typography.Paragraph
          style={{ maxWidth: '100px' }}
          className="text"
          ellipsis={{
            rows: 1,
            tooltip: description,
            expandable: false,
          }}
        >
          {description}
        </Typography.Paragraph>
      ),
    },

    {
      title: (
        <Tooltip
          description="Saved storage systems for different simulations. Each storage systems parameters can be edited for different simulations."
          className="cursor-pointer"
          trigger="click"
          title="Storage System"
        >
          <Row
            align="middle"
            justify="start"
            className="w-100"
            style={{ minWidth: '120px' }}
          >
            <Typography.Text className="pr-8">Storage System</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'storage',
      key: 'storage',
      sorter: (a, b) => {
        const nameA = a.storage?.name ?? '';
        const nameB = b.storage?.name ?? '';

        return nameA.localeCompare(nameB);
      },
      render: (dataIndex) => (
        <Typography.Paragraph
          className="simulation-item underline text"
          style={{ maxWidth: '120px' }}
          ellipsis={{
            rows: 1,
            tooltip: dataIndex,
            expandable: false,
          }}
          onClick={() => {
            props.setDrawerStorageSystem(dataIndex);
            props.handleFormsClick('storage', dataIndex.id);
          }}
          key={dataIndex.id}
        >{`${dataIndex.name}`}</Typography.Paragraph>
      ),
    },
    {
      title: (
        <Tooltip
          className="cursor-pointer"
          trigger="click"
          title="Scenario"
          description="Scenarios include different load and price forecasts that can be associated with different storage systems and different plants for different simulations. Each forecast file related with a scenario can be edited"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Scenario</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'scenario',
      key: 'scenario ',
      sorter: (a, b) => {
        const nameA = a.scenario?.name ?? '';
        const nameB = b.scenario?.name ?? '';

        return nameA.localeCompare(nameB);
      },
      render: (dataIndex) => (
        <Typography.Paragraph
          style={{ maxWidth: '120px' }}
          ellipsis={{
            rows: 1,
            tooltip: dataIndex,
            expandable: false,
          }}
          className="simulation-item underline"
          onClick={() => {
            props.setDrawerScenario(dataIndex);
            props.handleFormsClick('scenario', dataIndex.id);
          }}
          key={dataIndex.id}
        >{`${dataIndex.name}`}</Typography.Paragraph>
      ),
    },
    {
      title: (
        <Tooltip
          className="cursor-pointer"
          trigger="click"
          title="Plants"
          description="Wind, PV or Diesel Generator power plants that can be combined with a storage plant. You can edit previously created plant variables from here."
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Plants</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'plants',
      key: 'plants',
      sorter: (a, b) => {
        const nameA = a.plants[0]?.name ?? '';
        const nameB = b.plants[0]?.name ?? '';

        return nameA.localeCompare(nameB);
      },
      render: (dataIndex) => {
        return (
          <Flex gap={5}>
            {dataIndex.map((plant: Plant) => (
              <Typography.Paragraph
                className="simulation-item underline"
                style={{ maxWidth: '120px' }}
                ellipsis={{
                  rows: 1,
                  tooltip: plant.name,
                  expandable: false,
                }}
                onClick={() => {
                  props.setDrawerPlant({ type: 'view', data: plant });
                }}
                key={plant.id}
              >
                {plant.name}
              </Typography.Paragraph>
            ))}
          </Flex>
        );
      },
    },
    {
      title: <div style={{ minWidth: '120px' }}>Created Date</div>,
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'start',
      width: 180,
      render: (dataIndex) => moment(dataIndex).format('DD.MM.YYYY HH:mm'),
      sorter: (a, b) =>
        new Date(a.dateDone).getTime() - new Date(b.dateDone).getTime(),
    },
    {
      title: (
        <Tooltip
          className="cursor-pointer"
          trigger="click"
          title="Status"
          description="The status of the simulation. If the simulation is active, you can stop it by clicking the stop button."
        >
          <Row
            align="middle"
            justify="start"
            className="w-100"
            style={{ minWidth: '70px' }}
          >
            <Typography.Text className="pr-8">Status</Typography.Text>
            <IconInfo fill="black" />
          </Row>
        </Tooltip>
      ),
      dataIndex: 'status',
      key: 'status ',
      align: 'start',
      width: 135,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (dataIndex, simulation) => {
        const estimatedTime: number | string = simulation.estimatedTime;

        const renderEstimatedTime = (time: string) => (
          <>
            <Typography.Text>
              {`${time[0].toUpperCase()}${time.slice(1)}`}
            </Typography.Text>
            <motion.span
              key={time}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              style={{ display: 'inline-block', marginLeft: '4px' }}
            >
              {dots}
            </motion.span>
          </>
        );

        const renderTag = (type: string, text: string) => (
          <TagTable type={type}>{text.toLocaleUpperCase()}</TagTable>
        );

        if (dataIndex === 'STARTED') {
          if (typeof estimatedTime === 'number') {
            const minutes = Math.floor(estimatedTime / 60);
            const seconds = estimatedTime % 60;

            if (minutes > 0) {
              return (
                <div className="hourglass-container">
                  <Lottie
                    animationData={SandWatchAnimation}
                    loop={true}
                    className="hourglass-animation"
                  />
                  {minutes} {minutes === 1 ? 'min' : 'mins'}
                </div>
              );
            } else {
              return (
                <div className="hourglass-container">
                  <Lottie
                    animationData={SandWatchAnimation}
                    loop={true}
                    className="hourglass-animation"
                  />
                  {seconds.toFixed(0)} secs
                </div>
              );
            }
          } else if (typeof estimatedTime === 'string') {
            return renderEstimatedTime(estimatedTime);
          }
          return renderTag('started', dataIndex);
        }

        switch (dataIndex) {
          case 'INACTIVE':
            return renderTag('inactive', dataIndex);
          case 'ACTIVE':
            return renderTag('active', dataIndex);
          case 'QUEUED':
            return renderTag('queued', dataIndex);
          default:
            return renderTag('default', dataIndex);
        }
      },
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 90,
      render: (_, simulation: Simulation) => (
        <div>
          {(simulation?.status === 'STARTED' ||
            simulation?.status === 'QUEUED') && (
            <Tooltip
              title={'Stop Simulation'}
              description="If you wish to terminate the simulation, please click here."
            >
              <Button
                className="default-icon-button"
                icon={<IconDeactivate />}
                onClick={() => {
                  props.setStopSimulationDrawerOpen(true);
                  props.setSelectedSimulation(String(simulation.id));
                }}
              />
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  return columns;
};

export default useRunningSimulationsColumns;
