import { useCallback, useEffect, useState } from 'react';

import { Button, Col, Row, Typography, notification } from 'antd';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { simulationApi } from '@/api';
import IconClock from '@/assets/icons/icon-clock-circle.svg?react';
import IconID from '@/assets/icons/icon-id.svg?react';
import IconName from '@/assets/icons/icon-name.svg?react';
import IconPlants from '@/assets/icons/icon-plants.svg?react';
import IconScenarios from '@/assets/icons/icon-scenarios.svg?react';
import IconStorageSystems from '@/assets/icons/icon-storage-systems.svg?react';
import IconWind from '@/assets/icons/icon-wind.svg?react';
import Card from '@/components/RCard';
import RNotificationCard from '@/components/RNotificationCard';
import { useAssets } from '@/hooks/useAssets';
import useAuth from '@/hooks/useAuth';
import PlantDrawer from '@/pages/Plants/Drawer/PlantGenericDrawer';
import { DrawerType as DrawerTypePlant } from '@/pages/Plants/Drawer/types';
import ScenarioDrawer from '@/pages/Scenarios/Drawer/ScenarioGenericDrawer';
import StorageDrawer from '@/pages/StorageSystems/Drawer/StorageGenericDrawer';
import paths from '@/routes/paths';
import { useSelectedAssetsStore } from '@/store/useSelectedAssetsStore';
import { DrawerType } from '@/types/global';
import { OverviewBodyItems, OverviewHeaderItems } from '@/types/overview';
import { Scenario } from '@/types/scenario';
import { SimulationsRaw } from '@/types/simulations';
import { StorageSystem } from '@/types/storageSystem';
import { handleError } from '@/utils';

import AssetsInformation from './AssetsInformation';
import PlantsStorageScenarioCollapse from './PlantsStorageScenarioCollapse';
import SimulationForm from './SimulationForm';
import StyledCol from './styles';
import RDrawer from '../RDrawer';
import Overview from '../ROverview';
import CustomSteps from '../RSteps';
import { RUsageLimitIndicator } from '../RUsageLimitIndicator';

type Props = {
  setNewSimulationDrawerVisible: (bool: boolean) => void;
  mutateFunction: () => void;
  isNewSimulationDrawerVisible: boolean;
  isLocked: boolean;
  runningSimulationsData?: SimulationsRaw;
};

const initialAsset = {
  name: '',
  id: '',
};

const NewSimulation: React.FC<Props> = ({
  setNewSimulationDrawerVisible,
  mutateFunction,
  isNewSimulationDrawerVisible,
  isLocked,
  runningSimulationsData,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const assetLimit = user?.organization.subscription.plan.simAssetLimit;

  // Steps state
  const [current, setCurrent] = useState(0);
  const [notificationCardVisible, setNotificationCardVisible] = useState(false);

  // Form state
  const [formState, setFormState] = useState({
    simulationName: '',
    simulationDescription: '',
    simulationStartDate: '',
    years: 1,
  });

  // Validation state
  const [validation, setValidation] = useState({
    isFormValid: false,
    isAssetsValid: false,
    isCollapseKeysValid: false,
    nextButtonDisabled: true,
  });

  // Selected assets state
  const { selectedAssets, setSelectedAssets } = useSelectedAssetsStore();

  const {
    setAssets,
    plantsData,
    scenarioData,
    storageSystemData,
    plantsDataMutate,
    scenarioDataMutate,
    storageSystemMutate,
  } = useAssets(formState.simulationStartDate, formState.years);

  const [drawerPlant, setDrawerPlant] = useState<DrawerTypePlant>({
    type: null,
    data: null,
  });
  const [drawerScenario, setDrawerScenario] = useState<DrawerType<Scenario>>({
    type: null,
    data: null,
  });

  const [drawerStorage, setDrawerStorage] = useState<DrawerType<StorageSystem>>(
    {
      type: null,
      data: null,
    }
  );

  useEffect(() => {
    plantsDataMutate();
    scenarioDataMutate();
    storageSystemMutate();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [formState.simulationStartDate, formState.years]);

  // Validate next button
  useEffect(() => {
    setValidation((prev) => ({
      ...prev,
      nextButtonDisabled:
        (current === 0 &&
          !(validation.isFormValid && validation.isAssetsValid)) ||
        (current === 1 && !validation.isCollapseKeysValid) ||
        (current !== 0 && current !== 1),
    }));
  }, [
    current,
    validation.isFormValid,
    validation.isAssetsValid,
    validation.isCollapseKeysValid,
  ]);

  const next = useCallback(() => {
    setCurrent((prev) => prev + 1);
    plantsDataMutate();
    scenarioDataMutate();
    storageSystemMutate();
  }, [plantsDataMutate, scenarioDataMutate, storageSystemMutate]);

  const prev = useCallback(() => setCurrent((prev) => prev - 1), []);

  // Handle simulation submission
  const submitSimulation = async () => {
    try {
      if (
        formState.simulationName &&
        formState.simulationStartDate &&
        // selectedAssets.plants.length &&
        selectedAssets.scenario.id &&
        selectedAssets.storageSystem.id
      ) {
        const simulationData: {
          name: string;
          startDate: string;
          numYears: number;
          scenario: number;
          storage: number;
          marketTimeInterval: number;
          description: string;
          plants: number[];
        } = {
          name: formState.simulationName,
          startDate: `${dayjs(formState.simulationStartDate).format(
            'YYYY-MM-DD'
          )}T00:00:00.000Z`,
          numYears: formState.years,
          scenario: parseInt(selectedAssets.scenario.id, 10),
          storage: parseInt(selectedAssets.storageSystem.id, 10),
          marketTimeInterval: 1,
          description: formState.simulationDescription,
          plants: selectedAssets.plants
            ? selectedAssets.plants.map((asset) => +asset.id)
            : [],
        };

        await simulationApi.createSimulation(simulationData);
      }
      notification.success({
        message:
          'Simulation created successfully! You are directed to the simulation page.',
      });
      mutateFunction();
      setTimeout(() => {
        navigate(paths.simulation.base);
        setNewSimulationDrawerVisible(false);
        resetData();
      }, 1000);
    } catch (error: unknown) {
      handleError(error);
    }
  };

  // Handle form reset
  const resetData = useCallback(() => {
    setCurrent(0);
    setAssets({ plants: [], scenarios: [], storageSystems: [] });
    setValidation({
      isFormValid: false,
      isAssetsValid: false,
      isCollapseKeysValid: false,
      nextButtonDisabled: true,
    });
    setFormState({
      simulationName: '',
      simulationDescription: '',
      simulationStartDate: '',
      years: 1,
    });
    setSelectedAssets({
      plants: [],
      scenario: initialAsset,
      storageSystem: initialAsset,
    });
  }, []);

  const overviewParameters: OverviewHeaderItems[] = [
    {
      label: 'Name',
      icon: <IconName fill={theme.colors.grayAccent} />,
      value: formState.simulationName,
    },
    {
      label: 'Description',
      icon: <IconID fill={theme.colors.grayAccent} />,
      value: formState.simulationDescription,
    },
    {
      label: 'Start Date',
      icon: <IconClock fill={theme.colors.grayAccent} />,
      value: formState.simulationStartDate,
    },
    {
      label: 'Year',
      icon: <IconClock fill={theme.colors.grayAccent} />,
      value: formState.years.toString(),
    },
  ];

  const overviewBody: OverviewBodyItems[] = [
    {
      label: 'Plant',
      icon: (
        <IconPlants
          fill={theme.colors.grayAccent}
          height={'32px'}
          width={'32px'}
        />
      ),
      buttons: selectedAssets.plants.map((plant) => ({
        label: plant.name,
        icon: <IconWind fill="black" />,
        onClickFunction: () => {
          setDrawerPlant({ type: 'view', data: plant });
        },
      })),
    },
    {
      label: 'Scenario',
      icon: (
        <IconScenarios
          fill={theme.colors.grayAccent}
          height={'32px'}
          width={'32px'}
        />
      ),
      buttons: [
        {
          label: selectedAssets.scenario.name,
          icon: <IconWind fill="black" />,
          onClickFunction: () => {
            setDrawerScenario({ type: 'view', data: selectedAssets.scenario });
          },
        },
      ],
    },
    {
      label: 'Storage System',
      icon: (
        <IconStorageSystems
          fill={theme.colors.grayAccent}
          height={'32px'}
          width={'32px'}
          style={{ marginTop: '4px' }}
        />
      ),
      buttons: [
        {
          label: selectedAssets.storageSystem.name,
          icon: <IconWind fill="black" width={14} height={14} />,
          onClickFunction: () => {
            setDrawerStorage({
              type: 'view',
              data: selectedAssets.storageSystem,
            });
          },
        },
      ],
    },
  ];

  const steps = [
    {
      title: 'Description of Simulation',
      content: (
        <SimulationForm
          formState={formState}
          setFormState={setFormState}
          setIsFormValid={(isValid) =>
            setValidation((prev) => ({ ...prev, isFormValid: isValid }))
          }
        />
      ),
    },
    {
      title: 'Asset Selection',
      content: (
        <PlantsStorageScenarioCollapse
          setIsCollapseKeysValid={(check) =>
            setValidation((prev) => ({ ...prev, isCollapseKeysValid: check }))
          }
          formState={formState}
        />
      ),
    },
    {
      title: 'Overview',
      content: (
        <>
          <Typography.Text className="fs-14-bold">Overview</Typography.Text>
          <br />
          <Typography.Text className="fs-14-regular dark-6">
            The simulation is set to start on the start date and will operate
            for the selected duration with the chosen plant, storage system, and
            scenario.
          </Typography.Text>

          <Overview
            parameters={overviewParameters}
            body={overviewBody}
          ></Overview>
        </>
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  // Determine if there is unsaved data
  const hasUnsavedData = () => {
    return (
      formState.simulationName ||
      formState.simulationDescription ||
      formState.simulationStartDate ||
      selectedAssets.plants.length ||
      selectedAssets.scenario.id ||
      selectedAssets.storageSystem.id
    );
  };

  const handleNewSimulationDrawerClose = () => {
    if (hasUnsavedData()) {
      setNotificationCardVisible(true);
    } else {
      setNewSimulationDrawerVisible(false);
    }
  };

  return (
    <RDrawer
      open={isNewSimulationDrawerVisible}
      destroyOnClose
      onClose={handleNewSimulationDrawerClose}
      footer={false}
      delayMask={true}
      drawerLock={{
        isLocked,
        lockText:
          'To start a new simulation, please upgrade your plan or wait until your other simulations are completed.',
      }}
      title="Create Simulation"
      description="Set up a new simulation by selecting the necessary assets. You can choose 0-5 plants, and you must select one scenario, one storage system."
      rightSideSection={
        <RUsageLimitIndicator
          text="Remaining simulations"
          currentValue={runningSimulationsData?.count}
          maxValue={
            user?.organization.subscription.plan.name.toLocaleLowerCase() ==
            'enterprise'
              ? Infinity
              : user?.organization.subscription.plan.simultaneousSimLimit
          }
        />
      }
    >
      <StyledCol>
        <Card className="pt-16 px-16 pb-12 content-container mt-24">
          <Col className="steps">
            <CustomSteps current={current} items={items} type="default" />
          </Col>
          <div className="cards">
            <Card className="pt-16 px-16 current-card mt-24">
              {steps[current].content}
            </Card>
            {current == 0 &&
              formState.years != 0 &&
              formState.simulationStartDate !== '' &&
              plantsData != undefined &&
              scenarioData != undefined &&
              storageSystemData != undefined && (
                <Card className="py-16 px-16 mt-24">
                  <AssetsInformation
                    formState={formState}
                    setIsAssetsValid={(check) =>
                      setValidation((prev) => ({
                        ...prev,
                        isAssetsValid: check,
                      }))
                    }
                  />
                </Card>
              )}
          </div>
          <Row
            className="bottom-button-group"
            align="middle"
            justify="space-between"
          >
            <Button
              className="btn-cancel"
              onClick={handleNewSimulationDrawerClose}
            >
              Cancel
            </Button>
            <div className="right-side-btn-group">
              {current > 0 && (
                <Button
                  className="btn-back"
                  type="default"
                  onClick={() => prev()}
                >
                  Previous
                </Button>
              )}

              {current < steps.length - 1 && (
                <Button
                  className="btn-next"
                  type="primary"
                  onClick={() => next()}
                  disabled={validation.nextButtonDisabled}
                >
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button
                  type="primary"
                  className="btn-next"
                  onClick={() => submitSimulation()}
                >
                  Create
                </Button>
              )}
            </div>
          </Row>
        </Card>
        <RNotificationCard
          title="Data will be lost."
          message="Are you sure you want to leave?"
          type="warning"
          confirmText="Leave"
          cancelText="Cancel"
          open={notificationCardVisible}
          onConfirm={() => {
            resetData();
            setNotificationCardVisible(false);
            setNewSimulationDrawerVisible(false);
          }}
          onCancel={() => setNotificationCardVisible(false)}
        />
        {assetLimit && plantsData && (
          <PlantDrawer
            drawerOptions={drawerPlant}
            setDrawerOptions={setDrawerPlant}
          />
        )}
        {assetLimit && scenarioData && (
          <ScenarioDrawer
            drawerOptions={drawerScenario}
            setDrawerOptions={setDrawerScenario}
          />
        )}
        {assetLimit && storageSystemData && (
          <StorageDrawer
            drawerOptions={drawerStorage}
            setDrawerOptions={setDrawerStorage}
          />
        )}
      </StyledCol>
    </RDrawer>
  );
};

export default NewSimulation;
