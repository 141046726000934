import { FC, useEffect, useState } from 'react';

import {
  Button,
  Col,
  Empty,
  Row,
  Select,
  Typography,
  notification,
} from 'antd';
import Link from 'antd/es/typography/Link';
import { Helmet } from 'react-helmet';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import { simulationApi } from '@/api';
import IconPlus from '@/assets/icons/icon-plus.svg?react';
import IconComputer from '@/assets/icons/icon-simulation-computer.svg?react';
import IconStar from '@/assets/icons/icon-star.svg?react';
import NewSimulation from '@/components/NewSimulation';
import Card from '@/components/RCard';
import RNotificationCard from '@/components/RNotificationCard';
import PageHeader from '@/components/RPageHeader';
import SearchInput from '@/components/RSearchInput';
import Table from '@/components/RTable';
import UpdateTime from '@/components/RUpdateTime';
import { RUsageLimitIndicator } from '@/components/RUsageLimitIndicator';
import useAuth from '@/hooks/useAuth';
import useNotRunningSimulationsColumns from '@/hooks/useNotRunningSimulationsColumns';
import useRunningSimulationsColumns from '@/hooks/useRunningSimulationsColumns';
import { DrawerType } from '@/types/global';
import { Plant, PlantsResponse } from '@/types/plant';
import { Scenario, ScenariosResponse } from '@/types/scenario';
import { Simulation, SimulationsRaw } from '@/types/simulations';
import { StorageSystem, StorageSystemsResponse } from '@/types/storageSystem';
import { handleError } from '@/utils';

import StyledSimulations, { TableActionsWrapper } from './styles';
import PlantDrawer from '../Plants/Drawer/PlantGenericDrawer';
import ScenarioDrawer from '../Scenarios/Drawer/ScenarioGenericDrawer';
import StorageDrawer from '../StorageSystems/Drawer/StorageGenericDrawer';
const Simulations: FC = () => {
  const theme = useTheme();

  const [search, setSearch] = useState<string>();
  const [filterStatus, setFilterStatus] = useState('all');
  const [notRunningSimulations, setNotRunningSimulations] = useState<
    Simulation[]
  >([]);
  const [runningSimulations, setRunningSimulations] = useState<Simulation[]>(
    []
  );
  const [isNewSimulationDrawerVisible, setNewSimulationDrawerVisible] =
    useState<boolean>(false);
  // const [drawerType, setDrawerType] = useState<DrawerType>();
  const [isStopSimulationDrawerOpen, setStopSimulationDrawerOpen] =
    useState(false);
  const [selectedSimulation, setSelectedSimulation] = useState<string>();
  const [pageSize, setPageSize] = useState<number>(7);

  const [offset, setOffset] = useState<number>(1);
  const [completedSimulationgQueryString, setCompletedSimulationgQueryString] =
    useState<string>();

  const { user } = useAuth();
  const assetLimit = user?.organization.subscription.plan.simAssetLimit;

  const { data: plantsData } = useSWR<PlantsResponse>(`/plant/`, {
    revalidateOnFocus: false,
  });
  const { data: scenarioData } = useSWR<ScenariosResponse>(`/scenario/`, {
    revalidateOnFocus: false,
  });
  const { data: storageSystemsData } = useSWR<StorageSystemsResponse>(
    `/storage/`,
    {
      revalidateOnFocus: false,
    }
  );

  const [drawerPlant, setDrawerPlant] = useState<DrawerType<Plant>>({
    type: null,
    data: null,
  });
  const [drawerScenario, setDrawerScenario] = useState<DrawerType<Scenario>>({
    type: null,
    data: null,
  });
  const [drawerStorageSystem, setDrawerStorageSystem] = useState<
    DrawerType<StorageSystem>
  >({
    type: null,
    data: null,
  });

  //API CALLS
  const {
    data: completedSimulationsData,
    mutate: completedSimulationsDataMutate,
    isLoading: completedSimulationsDataIsLoading,
  } = useSWR(
    completedSimulationgQueryString
      ? `/simulation/?limit=${pageSize}&offset=${
          (offset - 1) * pageSize
        }&${completedSimulationgQueryString}`
      : null,
    {
      revalidateOnFocus: false,
    }
  );

  const {
    data: runningSimulationsData,
    mutate: runningSimulationsDataMutate,
    isLoading: runningSimulationsDataIsLoading,
  } = useSWR<SimulationsRaw>(`/simulation/?status=QUEUED&status=STARTED`, {
    refreshInterval: 5000,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (runningSimulationsData?.results.length === 0) {
      completedSimulationsDataMutate();
    }
  }, [runningSimulationsData?.results, completedSimulationsDataMutate]);

  const { data: exampleSimulationData } = useSWR('/simulation/demo/', {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    const queryParams = [];
    if (search) {
      queryParams.push(`name=${search}`);
    }
    if (filterStatus !== 'all') {
      queryParams.push(`status=${filterStatus}`);
    }
    if (filterStatus == 'all') {
      queryParams.push(`status=SUCCESS`);
      queryParams.push(`status=FAILURE`);
    }
    setCompletedSimulationgQueryString(queryParams.join('&'));
  }, [search, filterStatus]);

  //FUNCTIONS
  const handleFilter = (value: string) => {
    setFilterStatus(value);
  };

  const handlePaginationChange = (page: number) => {
    setOffset(page);
  };

  async function stopSimulation() {
    try {
      if (selectedSimulation) {
        const result = await simulationApi.killSimulation(selectedSimulation);
        if (result) {
          completedSimulationsDataMutate();
          runningSimulationsDataMutate();
          notification.success({ message: 'Simulation stoped successfully!' });
          setStopSimulationDrawerOpen(false);
        }
      }
    } catch (error) {
      handleError(error);
    }
  }

  const handleFormsClick = (type: string, assetID: number | undefined) => {
    if (type === 'plant') {
      setDrawerPlant({
        type: 'view',
        data: { id: String(assetID) },
      });
    } else if (type === 'scenario') {
      setDrawerScenario({
        type: 'view',
        data: { id: String(assetID) },
      });
    } else if (type === 'storage') {
      setDrawerStorageSystem({
        type: 'view',
        data: { id: String(assetID) },
      });
    }
  };

  useEffect(() => {
    if (completedSimulationsData) {
      setNotRunningSimulations(completedSimulationsData.results);
    }
  }, [completedSimulationsData]);

  useEffect(() => {
    if (runningSimulationsData) {
      setRunningSimulations(runningSimulationsData.results);
    }
  }, [runningSimulationsData]);

  const notRunningSimulationColumns = useNotRunningSimulationsColumns({
    handleFormsClick,
    setDrawerPlant,
    setDrawerScenario,
    setDrawerStorageSystem,
  });

  const runningSimulationColumns = useRunningSimulationsColumns({
    handleFormsClick,
    setDrawerPlant,
    setDrawerScenario,
    setDrawerStorageSystem,
    setStopSimulationDrawerOpen,
    setSelectedSimulation,
  });

  return (
    <StyledSimulations>
      <Helmet>
        <title>RatioSIM</title>
      </Helmet>
      <PageHeader
        title="Simulations"
        description="You can view all currently active simulations as well as previous ones. You also have the option to create a new simulation."
        rightSideSection={
          <RUsageLimitIndicator
            text="Remaining simulations"
            currentValue={
              user?.organization.subscription.plan.name.toLocaleLowerCase() ==
              'enterprise'
                ? Infinity
                : runningSimulationsData
                  ? runningSimulationsData.count
                  : 0
            }
            maxValue={
              user?.organization.subscription.plan.name.toLocaleLowerCase() ==
              'enterprise'
                ? Infinity
                : user?.organization.subscription.plan.simultaneousSimLimit
            }
          />
        }
      />
      <>
        {runningSimulations.length > 0 ? (
          <Card>
            <Empty
              className="mt-12"
              style={{ background: '#fff', border: 'none' }}
              image={
                <IconComputer width={50} fill={theme.colors.blueSecondary} />
              }
              imageStyle={{ height: 50 }}
              description={
                <Typography.Paragraph className="information-text">
                  You have started a new simulation.
                </Typography.Paragraph>
              }
            />
            <Table
              rowKey="id"
              style={{ marginTop: '-8px' }}
              columns={runningSimulationColumns}
              dataSource={runningSimulations}
              loading={runningSimulationsDataIsLoading}
              bordered
              size="small"
              pagination={false}
              scroll={{ x: 900 }}
            />
          </Card>
        ) : (
          <Card title="Running Simulations">
            <Empty
              className="mt-12"
              style={{ background: '#fff', border: 'none' }}
              image={<IconComputer width={50} fill={theme.colors.grayLight} />}
              imageStyle={{ height: 50 }}
              description={
                <>
                  <Typography.Paragraph className="information-text">
                    There is no started simulation yet!
                    <br />
                    You can start a{' '}
                    <Link
                      className="text-blue-color"
                      onClick={() => setNewSimulationDrawerVisible(true)}
                    >
                      New simulation
                    </Link>{' '}
                    now.
                  </Typography.Paragraph>
                </>
              }
            />
          </Card>
        )}
      </>

      <Card size="small" className="mt-24" title={'Completed Simulations'}>
        <TableActionsWrapper>
          <Col>
            <Row align="middle" justify="start">
              <Col>
                <SearchInput
                  placeholder="Search Input"
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => setSearch(e.target.value)}
                  className="search-group"
                />
              </Col>
              <Col style={{ marginLeft: '15px' }}>
                <Select
                  placeholder="Status"
                  style={{ width: 180 }}
                  onChange={handleFilter}
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'SUCCESS', label: 'Success' },
                    { value: 'FAILURE', label: 'Failure' },
                  ]}
                />
              </Col>
              <Col style={{ marginLeft: '15px' }}>
                <UpdateTime fetchFunction={completedSimulationsDataMutate} />
              </Col>
            </Row>
          </Col>
          <Col flex={'auto'}>
            <Row
              align={'middle'}
              justify="end"
              gutter={[theme.spacing.spacing16, theme.spacing.spacing16]}
            >
              <Col>
                <Button
                  className="example-button"
                  onClick={() => {
                    window.location.replace(
                      `/simulations/result/${exampleSimulationData.id}`
                    );
                  }}
                  icon={
                    <IconStar
                      fill={theme.colors.yellowDark}
                      className="button-icon"
                    />
                  }
                >
                  Example Simulation
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  icon={<IconPlus fill={theme.colors.light} />}
                  onClick={() => setNewSimulationDrawerVisible(true)}
                >
                  Create
                </Button>
              </Col>
            </Row>
          </Col>
        </TableActionsWrapper>

        <Table
          className="mt-12"
          rowKey="id"
          columns={notRunningSimulationColumns}
          dataSource={notRunningSimulations}
          loading={completedSimulationsDataIsLoading}
          bordered
          size="small"
          pagination={{
            total: completedSimulationsData?.count,
            pageSize: pageSize,
            hideOnSinglePage: true,
            defaultCurrent: 1,
            onChange: handlePaginationChange,
            pageSizeOptions: ['7', '10', '20', '50', '100'],
            onShowSizeChange: (current, size) => {
              setOffset(current);
              setPageSize(size);
            },
          }}
          scroll={{ x: 900 }}
        />
      </Card>
      {assetLimit && plantsData && (
        <PlantDrawer
          drawerOptions={drawerPlant}
          setDrawerOptions={setDrawerPlant}
        />
      )}
      {assetLimit && scenarioData && (
        <ScenarioDrawer
          drawerOptions={drawerScenario}
          setDrawerOptions={setDrawerScenario}
        />
      )}
      {assetLimit && storageSystemsData && (
        <StorageDrawer
          drawerOptions={drawerStorageSystem}
          setDrawerOptions={setDrawerStorageSystem}
        />
      )}
      <NewSimulation
        isNewSimulationDrawerVisible={isNewSimulationDrawerVisible}
        setNewSimulationDrawerVisible={setNewSimulationDrawerVisible}
        mutateFunction={runningSimulationsDataMutate}
        isLocked={
          (runningSimulationsData ? runningSimulationsData.count : 0) >=
          (user != undefined
            ? user?.organization.subscription.plan.simultaneousSimLimit
            : 0)
        }
        runningSimulationsData={runningSimulationsData}
      />

      <RNotificationCard
        title="Stop Simulation"
        message="Are you sure you want to stop simulation?"
        type="warning"
        confirmText="Yes"
        cancelText="Cancel"
        open={isStopSimulationDrawerOpen}
        onConfirm={() => {
          stopSimulation();
        }}
        onCancel={() => setStopSimulationDrawerOpen(false)}
      />
    </StyledSimulations>
  );
};

export default Simulations;
