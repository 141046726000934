export const ENERGY_UNITS = {
  MW: 'MW',
  // KW: 'KW',
};
export const energyUnits = Object.values(ENERGY_UNITS);

export const RANGE_TYPES = {
  HOUR: 'H',
  DAY: 'D',
  WEEK: 'W',
  MONTH: 'M',
  YEAR: 'Y',
};

export const FILE_FORMATS = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv',
};

export const ROLES_LIST = [
  {
    id: 1,
    name: 'Admin',
    value: 'admin',
    label: 'Admin',
  },
  {
    id: 2,
    name: 'Finance',
    value: 'finance',
    label: 'Finance',
  },
  {
    id: 3,
    name: 'Energy',
    value: 'energy',
    label: 'Energy',
  },
  {
    id: 4,
    name: 'Standard',
    value: 'standart',
    label: 'Standard',
  },
];

export const MONTHS_WITH_VALUES = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
];

export const LINE_DATA_KEYS = [
  'accumulatedCashFlow',
  'soh',
  'inflow',
  'outflow',
  'mcpPrice',
  'pfcPrice',
  'sfcPrice',
  'totalGeneration',
  'inflowStorage',
  'outflowStorage',
  'inflowPlant',
  'outflowPlant',
];
