import { FC } from 'react';

import { Col, Flex, Form, Input, Row, Select, SelectProps } from 'antd';
import { useTheme } from 'styled-components';

import useGenerationTypes from '@/hooks/useGenerationTypes';
import { PlantForm } from '@/types/plant';
import { normalizePositiveNumericValuesInput } from '@/utils';

type Props = {
  disabled?: boolean;
};

const MechanicalProps: FC<Props> = ({ disabled = false }) => {
  const theme = useTheme();
  const generationTypes = useGenerationTypes();

  const flattenGenerationTypes = Object.values(
    generationTypes
  ) as SelectProps['options'];

  return (
    <Form.Item<PlantForm>
      wrapperCol={{ span: 24 }}
      className="p-16 pb-40"
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
    >
      <Col span={12}>
        <Form.Item<PlantForm>
          className="pr-8"
          name="generationType"
          label="Generation Type"
          getValueFromEvent={(value) => value?.value}
          rules={[{ required: true, message: 'Plant name cannot be empty.' }]}
        >
          <Select
            disabled={disabled}
            labelInValue={true}
            labelRender={(option) => (
              <Flex gap={8} dir="row" align="center" justify="start">
                {
                  flattenGenerationTypes?.find(
                    (item) => item.value === option.value
                  )?.icon
                }
                {option.label}
              </Flex>
            )}
            options={flattenGenerationTypes}
            optionRender={(option) => (
              <Flex gap={8} dir="row" align="center" justify="start">
                {
                  flattenGenerationTypes?.find(
                    (item) => item.value === option.value
                  )?.icon
                }
                {option.label}
              </Flex>
            )}
            placeholder="Generation Type"
          />
        </Form.Item>
      </Col>
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<PlantForm>
            name="installedPowerKw"
            label="Installed Power"
            hasFeedback
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Installed Power cannot be empty.',
              },
            ]}
          >
            <Input
              disabled={disabled}
              addonAfter={<div>MW</div>}
              placeholder="Installed Power"
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<PlantForm>
            name="licensedPowerKw"
            label="Licensed Power"
            hasFeedback
            dependencies={['installedPowerKw']}
            normalize={normalizePositiveNumericValuesInput}
            rules={[
              {
                required: true,
                message: 'Licensed Power cannot be empty.',
              },
              {
                pattern: new RegExp(/^\d*\.?\d*$/),
                message: 'Field only accepts positive numbers.',
              },
              ({ getFieldValue }) => ({
                validator(_, value: string) {
                  const numberValue =
                    typeof value === 'string'
                      ? parseFloat(value.replace(/,/g, ''))
                      : parseFloat(value || 0);
                  const installedPower = getFieldValue('installedPowerKw');
                  if (!value || numberValue <= installedPower) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'Licensed Power should not be bigger than Installed Power.'
                    )
                  );
                },
              }),
            ]}
          >
            <Input
              disabled={disabled}
              className="w-100"
              placeholder="Licensed Power"
              addonAfter={<div>MW</div>}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default MechanicalProps;
