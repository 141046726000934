import { FC } from 'react';

import {
  Checkbox,
  Col,
  Form,
  FormInstance,
  Row,
  Select,
  SelectProps,
} from 'antd';
import { useTheme } from 'styled-components';

import { participationStatusOptions, ScenarioForm } from '@/types/scenario';

type Props = {
  disabled?: boolean;
  form: FormInstance<ScenarioForm>;
};

const ElektricityMarketParticipation: FC<Props> = ({ disabled = false }) => {
  const theme = useTheme();

  const modelTypeOptions: SelectProps['options'] = [
    {
      key: 'feed_in_tariff_only',
      label: 'Feed in Tariff Only',
      value: 'feed_in_tariff_only',
    },
    {
      key: 'market_only',
      label: 'Market Only',
      value: 'market_only',
    },
    {
      key: 'hybrid',
      label: 'Hybrid',
      value: 'hybrid',
    },
  ];

  return (
    <Form.Item
      className="p-16 pb-40"
      style={{
        border: '0.5px solid',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
      wrapperCol={{ span: 24 }}
    >
      <Row>
        <Form.Item<ScenarioForm>
          wrapperCol={{ span: 24 }}
          name="gridActions"
          label="Grid Actions"
          rules={[{ required: false }]}
        >
          <Checkbox.Group disabled={disabled}>
            <Checkbox value="sellToGrid">Sell to Grid</Checkbox>
            <Checkbox value="purchaseFromGrid">Purchase from Grid</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      </Row>
      <Form.Item<ScenarioForm>
        wrapperCol={{ span: 12 }}
        className="pr-32"
        name="modelType"
        label="Model Type"
        rules={[{ required: true, message: 'Model Type cannot be empty.' }]}
      >
        <Select
          disabled={disabled}
          options={modelTypeOptions}
          placeholder="Model Type"
        />
      </Form.Item>
      <Row>
        <Col className="pr-8" span={12}>
          <Form.Item<ScenarioForm>
            wrapperCol={{ span: 24 }}
            className="pr-8"
            name="pfcParticipation"
            label="PFC Participation Situation"
            rules={[
              { required: true, message: 'PFC Participation cannot be empty.' },
            ]}
          >
            <Select
              disabled={disabled}
              options={participationStatusOptions}
              placeholder="PFC Participation Situation"
            />
          </Form.Item>
        </Col>
        <Col className="pl-8" span={12}>
          <Form.Item<ScenarioForm>
            wrapperCol={{ span: 24 }}
            className="pr-8"
            name="sfcParticipation"
            label="SFC Participation Situation"
            rules={[
              { required: true, message: 'SFC Participation cannot be empty.' },
            ]}
          >
            <Select
              disabled={disabled}
              options={participationStatusOptions}
              placeholder="SFC Participation Situation"
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default ElektricityMarketParticipation;
