import { TooltipProps, Typography } from 'antd';
import { useTheme } from 'styled-components';

import StyledTooltip from './styles';

type ExtendedTooltipProps = TooltipProps & {
  title?: string;
  description: string | JSX.Element;
};

const RTooltip: React.FC<ExtendedTooltipProps> = ({
  title,
  description,
  ...props
}) => {
  const theme = useTheme();

  return (
    <StyledTooltip
      className="tooltip"
      title={
        <div>
          {title && (
            <>
              <Typography.Text className="fs-14-semi-bold">
                {title}
              </Typography.Text>
              <div className="horizontal-line mt-4"></div>
            </>
          )}

          <Typography.Text className="fs-14-regular smoke mt-4">
            {description}
          </Typography.Text>
        </div>
      }
      overlayInnerStyle={{
        padding: theme.spacing.spacing12 + 'px',
        color: 'inherit',
      }}
      color={theme.colors.light}
      {...props}
    >
      {props.children}
    </StyledTooltip>
  );
};

export default RTooltip;
