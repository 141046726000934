import { FC, useState } from 'react';

import { Dropdown, Row, MenuProps, Image } from 'antd';
import { Link } from 'react-router-dom';

import IconDown from '@/assets/icons/icon-caret-down.svg?react';
import IconUser from '@/assets/icons/icon-user.svg?react';
import IconProfileVector from '@/assets/images/icon-profile-frame.svg';
import useAuth from '@/hooks/useAuth';
import paths from '@/routes/paths';
import { UserResponse } from '@/types/auth';
import { getNameInitials } from '@/utils';

import { AvatarContainer } from './styles';

type Props = {
  user?: UserResponse;
};

const SiderAccount: FC<Props> = ({ user }) => {
  const [open, setOpen] = useState(false);
  const { logout } = useAuth();
  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <Link to={paths.user.base}>Personal Settings</Link>,
    },
    {
      key: '2',
      label: 'Logout',
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === '2') {
      setOpen(false);
      return logout();
    }
  };

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  return (
    <Dropdown
      menu={{ onClick: handleMenuClick, items }}
      onOpenChange={handleOpenChange}
      open={open}
      trigger={['click']}
    >
      <Row
        className="account-card-row-avatar cursor-pointer"
        align="middle"
        justify={'space-between'}
      >
        <IconDown />
        {user?.fullName ? (
          <AvatarContainer className="avatar-cont">
            <Image
              preview={false}
              className="team-avatar"
              src={IconProfileVector}
            />
            <span className="avatar-company-name">
              {user?.fullName && getNameInitials(user?.fullName)?.toUpperCase()}
            </span>
          </AvatarContainer>
        ) : (
          <AvatarContainer className="avatar-cont">
            <Image
              preview={false}
              className="team-avatar"
              src={IconProfileVector}
            />
            <span className="avatar-company-name">
              <IconUser fill="white" />
            </span>
          </AvatarContainer>
        )}
      </Row>
    </Dropdown>
  );
};

export default SiderAccount;
