import { FC, useEffect, useMemo, useReducer, useState } from 'react';

import {
  Button,
  Col,
  Empty,
  Flex,
  Form,
  FormInstance,
  Row,
  SelectProps,
  Spin,
  Typography,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import dayjs from 'dayjs';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  ResponsiveContainer,
  Line,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultTooltipContent';
import { useTheme } from 'styled-components';
import { DefaultTheme } from 'styled-components/dist/types';
import useSWR from 'swr';

import { scenarioApi } from '@/api';
import SAMPLE_PNL_TEMPLATE from '@/assets/files/scenario_price_and_load_template.xlsx';
import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInbox from '@/assets/icons/icon-inbox.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconWarning from '@/assets/icons/icon-warning.svg?react';
import PNLImage from '@/assets/images/prices-load-image.png';
import Card from '@/components/RCard';
import RChartTooltip from '@/components/RGraphicTooltip';
import RSelect from '@/components/RSelect';
import RToggleButtons from '@/components/RToggleButtons';
import RTooltip from '@/components/RTooltip';
import { FILE_FORMATS } from '@/constants';
import CustomAxiosError from '@/types/error';
import { Draft } from '@/types/global';
import {
  Scenario,
  ScenarioForm,
  AnnualPNLGraph,
  DailyPNLGraph,
  DailyPNLGraphData,
  DailyPNLDataPoint,
  AnnualPNLGraphData,
} from '@/types/scenario';
import { formatNumberWithCommas, getYearsInRangeOptions } from '@/utils';

import { filterReducer, initialState } from './filters/reducer';
import { FilterState } from './filters/types';
import StyledPNLProfile from './styles';

type Props = {
  disabled?: boolean;
  form: FormInstance<ScenarioForm>;
  currentScenarioData?: Scenario;
  draft?: Draft;
  setDraft?: React.Dispatch<React.SetStateAction<Draft>>;
  uploadError?: string | null;
  setUploadError?: React.Dispatch<React.SetStateAction<string | null>>;
};

const renderCharts = (
  data: { [key: string]: number | string }[],
  key: string,
  theme: DefaultTheme,
  name: JSX.Element,
  unit: string,
  type: 'annual' | 'daily'
) => (
  <Row justify="center" className="mb-32" key={key}>
    <Flex vertical className="mb-12">
      <Typography.Title level={4} className="fs-17-bold">
        {name}
      </Typography.Title>
    </Flex>
    {type === 'daily' ? (
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          margin={{ top: 15, right: 24, left: 12, bottom: 15 }}
          data={data}
        >
          <XAxis
            dataKey="hour"
            tickFormatter={(tick) => tick}
            angle={-45}
            textAnchor="end"
            interval="preserveStartEnd"
          />
          <YAxis
            tickFormatter={(value) => `${value} ${unit}`}
            interval="equidistantPreserveStart"
          />
          <CartesianGrid
            horizontal={true}
            vertical={false}
            stroke={theme.colors.grayVeryLight}
          />
          {data.length > 0 &&
            Object.keys(data[0])
              .filter((key) => key !== 'hour')
              .map((lineKey) => (
                <Line
                  dot={false}
                  key={lineKey}
                  type="natural"
                  dataKey={lineKey}
                  stroke={theme.colors.grayVeryLight}
                  strokeWidth={0.5}
                />
              ))}
          <Line
            dot={false}
            type="natural"
            dataKey="average"
            stroke={theme.colors.greenPrimary}
            strokeWidth={4}
          />
        </ComposedChart>
      </ResponsiveContainer>
    ) : (
      <ResponsiveContainer width="100%" height={325}>
        <ComposedChart data={data} stackOffset="sign">
          <XAxis
            angle={-45}
            textAnchor="end"
            interval="preserveStart"
            tickFormatter={(tick) => dayjs(tick).format('MMM')}
            dataKey="dt"
          />
          <YAxis
            tickFormatter={(value) =>
              `${formatNumberWithCommas(Number(value))}`
            }
            interval="preserveEnd"
            dataKey={key}
          />
          <Tooltip
            content={({ active, payload, label }) => {
              const typedPayload = payload as Payload<number, string>[];

              const transformedPayload = typedPayload.map((entry) => ({
                ...entry,
                name: entry.name && entry.name.toUpperCase(),
                unit: unit,
                color: theme.colors.primary,
              }));
              return (
                <RChartTooltip
                  active={active}
                  payload={transformedPayload}
                  label={`${dayjs(label).format('MMMM')}, ${dayjs(label).format(
                    'YYYY'
                  )}`}
                />
              );
            }}
          />
          <CartesianGrid
            horizontal={true}
            vertical={false}
            stroke={theme.colors.grayVeryLight}
          />
          <Line
            dot={false}
            type="natural"
            dataKey={key}
            stroke={theme.colors.primary}
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    )}
  </Row>
);

type ChartMapData = {
  data: { [key: string]: number | string }[];
  key: string;
  name: JSX.Element;
  unit: string;
};

const PNLProfile: FC<Props> = ({
  disabled = false,
  form,
  currentScenarioData,
  draft,
  setDraft,
  uploadError,
  setUploadError,
}) => {
  const theme = useTheme();
  const draftTyped = draft as Draft;
  const setDraftTyped = setDraft as React.Dispatch<React.SetStateAction<Draft>>;
  const uploadErrorTyped = uploadError as string | null;
  const setUploadErrorTyped = setUploadError as React.Dispatch<
    React.SetStateAction<string | null>
  >;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [currentScenarioId, setCurrentScenarioId] = useState<
    string | undefined | number
  >();
  const pricesAndLoadFile = form.getFieldValue('pricesAndLoad');

  useEffect(() => {
    if (pricesAndLoadFile) {
      if (
        typeof currentScenarioData?.pricesAndLoad === 'string' &&
        typeof pricesAndLoadFile === 'string'
      ) {
        const existingFile: UploadFile = {
          uid: '-1',
          name: 'Current Prices and Load Profile File',
          status: 'done',
          thumbUrl: PNLImage,
        };
        setFileList([existingFile]);
      }
    }
  }, [pricesAndLoadFile, currentScenarioData]);

  const [filterParams, dispatch] = useReducer(filterReducer, initialState);
  const [chartsData, setChartsData] = useState<ChartMapData[]>([]);

  useEffect(() => {
    const id = draftTyped.draftId || currentScenarioData?.id;
    setCurrentScenarioId(id);
  }, [draftTyped, currentScenarioData]);

  const { apiEndpoint, queryString } = useMemo((): {
    apiEndpoint: string | null;
    queryString: string | null;
  } => {
    if (currentScenarioId && filterParams.selectedYear) {
      const getEndpoint = (
        filter: FilterState['activeFilter'],
        scenarioId: string | undefined | number
      ): string => {
        switch (filter) {
          case 'daily':
            return `/scenario/${scenarioId}/prices_load_graph/daily/`;
          case 'annual':
            return `/scenario/${scenarioId}/prices_load_graph/`;
          default:
            return '';
        }
      };
      const endpoint = getEndpoint(
        filterParams.activeFilter,
        currentScenarioId
      );

      const params: Record<string, string> = {};

      if (filterParams.activeFilter === 'annual') {
        if (filterParams.selectedYear) {
          params.startDatetime = dayjs(
            `${filterParams.selectedYear}-01-01`
          ).toISOString();
          params.endDatetime = dayjs(
            `${filterParams.selectedYear}-12-31`
          ).toISOString();
          params.timeUnit = `month`;
        }
      }

      if (filterParams.activeFilter === 'daily') {
        const { dayOfWeek, season } = filterParams.daily;

        if (filterParams.selectedYear) {
          params.year = filterParams.selectedYear.toString();
        }

        if (dayOfWeek && dayOfWeek !== 'all') {
          params.dayOfWeek = dayOfWeek;
        }

        if (season && season !== 'all') {
          params.season = season;
        }
      }

      const queryString = new URLSearchParams(params).toString();

      return {
        apiEndpoint: endpoint,
        queryString,
      };
    }

    return {
      apiEndpoint: null,
      queryString: null,
    };
  }, [filterParams, currentScenarioId]);

  const [fullURL, setFullURL] = useState(() => {
    return apiEndpoint ? `${apiEndpoint}?${queryString}` : null;
  });

  useEffect(() => {
    if (apiEndpoint) {
      setFullURL(`${apiEndpoint}?${queryString}`);
    } else {
      setFullURL(null);
    }
  }, [apiEndpoint, queryString]);

  const {
    data: generatedGraphData,
    isLoading: isGraphDataLoading,
    error,
  } = useSWR<DailyPNLGraph | AnnualPNLGraph>(fullURL);

  const getThumbnail = (file: UploadFile) => {
    if (file.status === 'error') {
      return;
    }
    return PNLImage;
  };

  const transformDailyData = (
    data: DailyPNLGraphData,
    dataType: keyof DailyPNLDataPoint
  ): { [key: string]: number | string }[] => {
    const hours = Array.from({ length: 24 }, (_, i) => i);
    return hours.map((hour) => {
      const formattedHour = `${String(hour).padStart(2, '0')}:00`;
      const hourData: { [key: string]: number | string } = {
        hour: formattedHour,
        average: data.avg[dataType][hour],
      };

      data.all.forEach((dayData, index) => {
        hourData[`day${index + 1}`] = (dayData as unknown as DailyPNLDataPoint)[
          dataType
        ][hour];
      });
      return hourData;
    });
  };

  useEffect(() => {
    if (generatedGraphData?.graphData) {
      if (
        filterParams.activeFilter === 'daily' &&
        'all' in generatedGraphData.graphData
      ) {
        const dailyData = generatedGraphData.graphData as DailyPNLGraphData;

        setChartsData([
          {
            data: transformDailyData(dailyData, 'load'),
            key: 'load',
            name: (
              <Flex gap={8} align="center">
                <Typography.Title level={4} className="fs-17-bold">
                  Consumption (MWh)
                </Typography.Title>
                <RTooltip
                  title="Consumption (MWh)"
                  description="This graph displays the hourly internal consumption in MWh."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            ),
            unit: 'MWh',
          },
          {
            data: transformDailyData(dailyData, 'damp'),
            key: 'damp',
            name: (
              <Flex gap={8} align="center">
                <Typography.Title level={4} className="fs-17-bold">
                  MCP ($/MWh)
                </Typography.Title>
                <RTooltip
                  title="MCP ($/MWh)"
                  description="This graph displays the hourly MCP in $/MWh."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            ),
            unit: '$/MW',
          },
          {
            data: transformDailyData(dailyData, 'pfcp'),
            key: 'pfcp',
            name: (
              <Flex gap={8} align="center">
                <Typography.Title level={4} className="fs-17-bold">
                  PFCP ($/MW)
                </Typography.Title>
                <RTooltip
                  title="PFCP ($/MW)"
                  description="This graph displays the hourly PFCP in $/MW."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            ),
            unit: '$/MW',
          },
          {
            data: transformDailyData(dailyData, 'sfcp'),
            key: 'sfcp',
            name: (
              <Flex gap={8} align="center">
                <Typography.Title level={4} className="fs-17-bold">
                  SFCP ($/MW)
                </Typography.Title>
                <RTooltip
                  title="SFCP ($/MW)"
                  description="This graph displays the hourly PFCP in $/MW."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            ),
            unit: '$/MW',
          },
        ]);
      } else if (
        filterParams.activeFilter === 'annual' &&
        Array.isArray(generatedGraphData.graphData)
      ) {
        const data = generatedGraphData.graphData as AnnualPNLGraphData;

        setChartsData([
          {
            data: data.map((item) => ({ dt: item.dt, load: item.totalLoad })),
            key: 'load',
            name: (
              <Flex gap={8} align="center">
                <Typography.Title level={4} className="fs-17-bold">
                  Consumption (MWh)
                </Typography.Title>
                <RTooltip
                  title="Consumption (MWh)"
                  description="This graph displays the hourly internal consumption in MWh."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            ),
            unit: 'MWh',
          },
          {
            data: data.map((item) => ({ dt: item.dt, damp: item.avgDamp })),
            key: 'damp',
            name: (
              <Flex gap={8} align="center">
                <Typography.Title level={4} className="fs-17-bold">
                  MCP ($/MW)
                </Typography.Title>
                <RTooltip
                  title="MCP ($/MWh)"
                  description="This graph displays the hourly MCP in $/MWh."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            ),
            unit: '$/MW',
          },
          {
            data: data.map((item) => ({ dt: item.dt, pfcp: item.avgPfcp })),
            key: 'pfcp',
            name: (
              <Flex gap={8} align="center">
                <Typography.Title level={4} className="fs-17-bold">
                  PFCP ($/MW)
                </Typography.Title>
                <RTooltip
                  title="PFCP ($/MW)"
                  description="This graph displays the hourly PFCP in $/MW."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            ),
            unit: '$/MW',
          },
          {
            data: data.map((item) => ({ dt: item.dt, sfcp: item.avgSfcp })),
            key: 'sfcp',
            name: (
              <Flex gap={8} align="center">
                <Typography.Title level={4} className="fs-17-bold">
                  SFCP ($/MW)
                </Typography.Title>
                <RTooltip
                  title="SFCP ($/MW)"
                  description="This graph displays the hourly PFCP in $/MW."
                >
                  <IconInfo />
                </RTooltip>
              </Flex>
            ),
            unit: '$/MW',
          },
        ]);
      } else {
        setChartsData([]);
      }
    }
  }, [filterParams, generatedGraphData?.graphData]);

  const handleFileChange: UploadProps['onChange'] = (props) => {
    const { fileList } = props;
    const extendedFileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      file.thumbUrl = getThumbnail(file);
      return file;
    });

    setFileList(extendedFileList);
  };

  const handleRemove: UploadProps['onRemove'] = () => {
    setUploadErrorTyped(null);
    setCurrentScenarioId(undefined);
  };

  const customFileUploadRequest: UploadProps['customRequest'] = async (
    options
  ) => {
    const { file, onSuccess, onError, onProgress } = options;
    try {
      setFullURL(null);
      const response = await scenarioApi.postDraftScenarioPNLFile({
        file: file,
      });

      //TODO Check this later if it is needed
      await scenarioApi.postDraftScenarioPNLFile(
        {
          file: file,
          draftId: response.draftId,
        },
        (progress) => {
          onProgress && onProgress(progress);
        }
      );

      setDraftTyped({
        draftId: response.draftId,
        startDate: response.startDate,
        endDate: response.endDate,
      });

      onSuccess && onSuccess(response);
    } catch (error) {
      const axiosError = error as CustomAxiosError;
      const errorMessage =
        axiosError.response?.data.userMessage ||
        'An error occurred. Please try again.';

      setUploadErrorTyped(errorMessage);
      onError && onError(error as Error);
    }
  };

  const handleFilterChange = (
    key: keyof Partial<FilterState['daily']> | 'selectedYear',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    if (key === 'selectedYear') {
      dispatch({
        type: 'SET_SELECTED_YEAR',
        payload: value,
      });
    } else if (
      filterParams.activeFilter === 'daily' &&
      key in filterParams.daily
    ) {
      dispatch({
        type: 'SET_DAILY_FILTER',
        payload: {
          [key]: value,
        },
      });
    }
  };

  const handleActiveFilterChange = (value: string) => {
    if (value === 'Daily') {
      dispatch({
        type: 'SET_ACTIVE_FILTER',
        payload: 'daily',
      });
    } else if (value === 'Annualy') {
      dispatch({
        type: 'SET_ACTIVE_FILTER',
        payload: 'annual',
      });
    }
  };

  const daysOfWeekOptions: SelectProps['options'] = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' },
    { label: 'All Days', value: 'all' },
  ];

  const seasonOptions: SelectProps['options'] = [
    { label: 'Spring', value: 'spring' },
    { label: 'Summer', value: 'summer' },
    { label: 'Autumn', value: 'autumn' },
    { label: 'Winter', value: 'winter' },
    { label: 'All Seasons', value: 'all' },
  ];

  useEffect(() => {
    const startDate = draftTyped.startDate || currentScenarioData?.startDate;
    const endDate = draftTyped.endDate || currentScenarioData?.endDate;

    if (startDate && endDate) {
      const years = getYearsInRangeOptions(startDate, endDate);
      dispatch({
        type: 'SET_YEARS',
        payload: years,
      });
    }
  }, [draftTyped, currentScenarioData]);

  return (
    <StyledPNLProfile
      wrapperCol={{ span: 24 }}
      className="p-16 pb-40"
      style={{
        border: '0.5px solid red',
        borderRadius: '10px',
        borderColor: theme.colors.grayLightAccent,
      }}
    >
      {/* TODO CHANGE THIS  */}
      {!currentScenarioData?.pricesAndLoad ? (
        <a
          style={{ color: theme.colors.bluePrimary }}
          href={SAMPLE_PNL_TEMPLATE}
        >
          <IconDownload fill={theme.colors.bluePrimary} />
          &nbsp; Download Template of Prices and Load Profile
        </a>
      ) : (
        <a style={{ color: theme.colors.bluePrimary }} href={pricesAndLoadFile}>
          <IconDownload fill={theme.colors.bluePrimary} />
          &nbsp; Download Current Prices and Load Profile
        </a>
      )}
      {!disabled && (
        <>
          <Form.Item<ScenarioForm>
            label="Upload Prices and Load Profile File"
            name="pricesAndLoad"
            className="mt-16 mb-0"
            rules={[
              {
                required: true,
                message: 'Prices and Load Profile file is required.',
              },
            ]}
            getValueFromEvent={({ fileList }) => fileList[0]?.originFileObj}
          >
            <Upload
              disabled={disabled}
              onRemove={handleRemove}
              customRequest={customFileUploadRequest}
              accept={`${FILE_FORMATS.csv}, ${FILE_FORMATS.xlsx}`}
              multiple={false}
              fileList={fileList}
              listType="picture"
              onChange={handleFileChange}
            >
              {fileList.length == 1 ? null : (
                <Empty
                  image={<IconInbox fill={theme.colors.primary} />}
                  imageStyle={{ height: '100%' }}
                  description={
                    <Button type="link">
                      Click or drag to this area to upload
                    </Button>
                  }
                ></Empty>
              )}
            </Upload>
          </Form.Item>
          {uploadErrorTyped && (
            <Form.Item
              className="upload-error"
              help={uploadErrorTyped}
              validateStatus="error"
            />
          )}
        </>
      )}
      <Col span={24}>
        <Card
          minHeight="300px"
          style={{ width: '100%', marginTop: '24px' }}
          $paddingBody="24px 0px"
          title={
            <Flex gap={8} align="center">
              <Typography.Title level={4} className="fs-17-bold">
                Prices and Load Profile
              </Typography.Title>
              <RTooltip
                title="Prices and Load Profile"
                description="This section displays the prices and load graphics for the
                selected scenario."
              >
                <IconInfo />
              </RTooltip>
            </Flex>
          }
          extra={
            filterParams.years.length > 0 && (
              <Flex gap={16} align="center">
                <Flex style={{ height: '32px' }} gap={8} align="center">
                  <RToggleButtons
                    disabled={isGraphDataLoading}
                    height={'auto'}
                    labels={['Daily', 'Annualy']}
                    onLabelChange={handleActiveFilterChange}
                  />
                  <RSelect
                    disabled={isGraphDataLoading}
                    style={{ height: '100%' }}
                    width={120}
                    defaultValue={filterParams.selectedYear}
                    options={filterParams.years.map((year) => ({
                      label: year.toString(),
                      value: year,
                    }))}
                    onChange={(value) =>
                      handleFilterChange('selectedYear', value)
                    }
                  />
                  {filterParams.activeFilter === 'daily' && (
                    <>
                      <RSelect
                        disabled={isGraphDataLoading}
                        style={{ height: '100%' }}
                        width={120}
                        defaultValue={filterParams.daily.dayOfWeek}
                        options={daysOfWeekOptions}
                        onChange={(value) =>
                          handleFilterChange('dayOfWeek', value)
                        }
                      />
                      <RSelect
                        disabled={isGraphDataLoading}
                        style={{ height: '100%' }}
                        width={120}
                        defaultValue={filterParams.daily.season}
                        options={seasonOptions}
                        onChange={(value) =>
                          handleFilterChange('season', value)
                        }
                      />
                    </>
                  )}
                </Flex>
                <Button
                  icon={<IconDownload fill={theme.colors.bluePrimary} />}
                />
              </Flex>
            )
          }
        >
          {isGraphDataLoading && (
            <Flex justify="center" align="center" style={{ height: 400 }}>
              <Spin style={{ margin: '20px' }} />
            </Flex>
          )}
          {!isGraphDataLoading && generatedGraphData && error && (
            <Flex
              vertical
              gap={12}
              justify="center"
              align="center"
              style={{ height: 400 }}
            >
              <IconError
                width={50}
                height={50}
                fill={theme.colors.tagFailure}
              />
              {error && (
                <Typography.Text className="fs-14-regular text-gray-color text-center">
                  An error occured while fetching data. Please check your inputs
                  and contact support if the issue persists.
                </Typography.Text>
              )}
              {!generatedGraphData && (
                <Typography.Text className="fs-14-regular text-gray-color text-center">
                  No data is currently available. Please review your inputs.
                </Typography.Text>
              )}
            </Flex>
          )}
          {!isGraphDataLoading && !generatedGraphData && (
            <Flex
              vertical
              style={{ height: 300 }}
              justify="center"
              align="center"
            >
              <IconWarning
                fill={theme.colors.tagRevoked}
                className="mb-24"
                width={50}
                height={50}
              />
              <Typography.Text className="fs-14-regular text-gray-color text-center">
                Upload Your Prices And Load File to View the Graph
              </Typography.Text>
            </Flex>
          )}
          {!isGraphDataLoading && generatedGraphData && chartsData && (
            <>
              {filterParams.activeFilter === 'daily'
                ? chartsData.map((chart) =>
                    renderCharts(
                      chart.data,
                      chart.key,
                      theme,
                      chart.name,
                      chart.unit,
                      'daily'
                    )
                  )
                : chartsData.map((chart) =>
                    renderCharts(
                      chart.data,
                      chart.key,
                      theme,
                      chart.name,
                      chart.unit,
                      'annual'
                    )
                  )}
            </>
          )}
        </Card>
      </Col>
    </StyledPNLProfile>
  );
};

export default PNLProfile;
