import { FC } from 'react';

import { Col, Flex, Row, Typography } from 'antd';

import IconInfo from '@/assets/icons/icon-info.svg?react';
import Tooltip from '@/components/RTooltip';
import { SimulationResult } from '@/types/simulations';
import { formatNumberWithCommas } from '@/utils';

import StyledStorage from './styles';

interface StorageTableProps {
  simulationData: SimulationResult;
}

const StorageTable: FC<StorageTableProps> = ({ simulationData }) => {
  return (
    <StyledStorage>
      <Col span={10}>
        <Row>
          <Col span={17}>
            <Flex vertical align="center">
              <Flex align="center" className="column">
                <Typography.Text className="fs-14-bold">
                  Average MCP
                </Typography.Text>
                <Tooltip
                  title="Average MCP"
                  description="The overall average of Market Clearing Prices (MCP) for energy."
                >
                  <IconInfo fill="black" />
                </Tooltip>
              </Flex>
              <Flex gap={9} align="center" className="column">
                <Typography.Text className="fs-14-bold">
                  Weighted Average of Sales Price Before Storage
                </Typography.Text>
                <Tooltip
                  title="Weighted Average of Sales Price Before Storage"
                  description="The average sales price per MWh before storage, weighted by revenue and energy sold."
                >
                  <IconInfo fill="black" />
                </Tooltip>
              </Flex>
              <Flex gap={9} align="center" className="column">
                <Typography.Text className="fs-14-bold">
                  Weighted Average of Sales Price After Storage
                </Typography.Text>
                <Tooltip
                  title="Weighted Average of Sales Price After Storage"
                  description="The average sales price per MWh after storage, weighted by revenue and energy sold."
                >
                  <IconInfo fill="black" />
                </Tooltip>
              </Flex>
            </Flex>
          </Col>
          <Col span={7}>
            <Flex vertical align="center">
              <Flex justify="center" align="center" className="value">
                <Typography.Text className="fs-14-regular text-gray-color">
                  {formatNumberWithCommas(
                    Number(
                      simulationData.result.averageEnergyMarketPrice.toFixed(2)
                    )
                  ) + ' $'}
                </Typography.Text>
              </Flex>
              <Flex justify="center" align="center" className="value">
                <Typography.Text className="fs-14-regular text-gray-color">
                  {formatNumberWithCommas(
                    Number(
                      simulationData.result.averageSalesPriceWos.toFixed(2)
                    )
                  ) + ' $'}
                </Typography.Text>
              </Flex>
              <Flex justify="center" align="center" className="value">
                <Typography.Text className="fs-14-regular text-gray-color">
                  {formatNumberWithCommas(
                    Number(simulationData.result.averageSalesPrice.toFixed(2))
                  ) + ' $'}
                </Typography.Text>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Col>
      <Col span={14}>
        <Row>
          <Col span={10}>
            <Flex vertical>
              <Flex gap={8} align="center" className="column" justify="center">
                <Typography.Text className="fs-14-bold">
                  Storage Premium per MWh
                </Typography.Text>
                <Tooltip
                  title="Storage Premium per MWh"
                  description="The increase in sales price per MWh due to storage, calculated as the difference between the weighted average sales prices after and before storage."
                >
                  <IconInfo fill="black" />
                </Tooltip>
              </Flex>
              <Typography.Text className="fs-14-regular storage-value text-gray-color">
                {formatNumberWithCommas(
                  Number(simulationData.result.storagePremiumPerMwh.toFixed(2))
                ) + ' $'}
              </Typography.Text>
            </Flex>
          </Col>
          <Col span={14} style={{ height: '100%' }}>
            <Flex vertical>
              <Flex gap={8} align="center" className="column" justify="center">
                <Typography.Text className="fs-14-bold ">
                  Weighted Average of MCP During Discharge
                </Typography.Text>
                <Tooltip
                  title="  Weighted Average of MCP During Discharge"
                  description="The average sales price per MWh during storage discharge, weighted by revenue earned from battery and energy sold from battery."
                >
                  <IconInfo fill="black" />
                </Tooltip>
              </Flex>
              <Typography.Text className="fs-14-regular text-gray-color weighted-value">
                {formatNumberWithCommas(
                  Number(
                    simulationData.result.averageSalesPriceFromDischarge.toFixed(
                      2
                    )
                  )
                ) + ' $'}
              </Typography.Text>
            </Flex>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Flex vertical align="center" justify="center">
          <Flex gap={9} align="center">
            <Typography.Text className="fs-14-bold battery-text">
              Battery usage cost that must be exceeded for sale (opportunity
              cost)
            </Typography.Text>
            <Tooltip
              title="Battery Usage Cost"
              description="Represents the cost per MWh that must be exceeded for profitable energy storage; if the cost is $30, the difference between the sale and purchase price must be more than $30 to gain, or during curtailment (when the energy price is considered $0), energy is stored if it can be sold for more than $30."
            >
              <IconInfo fill="black" />
            </Tooltip>
          </Flex>
          <Typography.Text className="fs-14-regular text-gray-color battery-value">
            {formatNumberWithCommas(
              Number(simulationData.result.batteryUsageCostPerMwh.toFixed(2))
            ) + ' $'}
          </Typography.Text>
        </Flex>
      </Col>
    </StyledStorage>
  );
};
export default StorageTable;
