import { useEffect, useState } from 'react';

import { Button, Flex, Spin, Typography } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import moment from 'moment';
import { useParams } from 'react-router';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useTheme } from 'styled-components';
import useSWR from 'swr';

import IconCalender from '@/assets/icons/icon-calender.svg?react';
import IconDownload from '@/assets/icons/icon-download.svg?react';
import IconError from '@/assets/icons/icon-error.svg?react';
import IconInfo from '@/assets/icons/icon-info.svg?react';
import IconSearch from '@/assets/icons/icon-search.svg?react';
import Card from '@/components/RCard';
import CustomGraphicTooltip from '@/components/RGraphicTooltip';
import RTooltip from '@/components/RTooltip';
import { EndDateFilters } from '@/hooks/useCalculateEndDate';
import {
  SimulationResult,
  SimulationResultEnergyDetails,
} from '@/types/simulations';
import { insertLineBreak } from '@/utils';

import { StyledLegend } from './styles';
import { StyledDatePicker, StyledSearch, StyledSelect } from '../../styles';

const GenerationFlow = () => {
  const resultId = useParams<Record<string, string>>();
  const { data: simulationData } = useSWR<SimulationResult>(
    `/simulation/${resultId.resultId}/`,
    {
      revalidateOnFocus: false,
    }
  );
  const [filters, setFilters] = useState<EndDateFilters>({
    startDate: '',
    endDate: '',
    timeUnit: 'full',
    inputValue: undefined,
  });
  const theme = useTheme();

  useEffect(() => {
    if (simulationData?.startDate && simulationData?.endDate) {
      setFilters((prevState) => ({
        ...prevState,
        startDate: moment(simulationData?.startDate).toISOString(),
        endDate: moment(simulationData?.endDate).toISOString(),
      }));
    }
  }, [simulationData]);

  useEffect(() => {
    if (
      filters.startDate &&
      filters.inputValue !== undefined &&
      filters.timeUnit !== 'full'
    ) {
      let endDateMoment = moment(filters.startDate);

      switch (filters.timeUnit) {
        case 'year':
          endDateMoment = endDateMoment
            .clone()
            .add(filters.inputValue, 'years');
          break;
        case 'month':
          endDateMoment = moment(filters.startDate)
            .add(filters.inputValue, 'months')
            .date(moment(filters.startDate).date());
          break;
        case 'week':
          endDateMoment = endDateMoment
            .add(filters.inputValue * 7, 'days')
            .startOf('day');
          break;
        case 'day':
          endDateMoment = endDateMoment
            .clone()
            .add(filters.inputValue, 'days')
            .startOf('day');
          break;

        default:
          break;
      }
      setFilters((prevState) => ({
        ...prevState,
        endDate: endDateMoment
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
      }));
    } else if (filters.timeUnit === 'full' && simulationData) {
      setFilters((prevState) => ({
        ...prevState,
        endDate: moment(simulationData.endDate).toISOString(),
        startDate: moment(simulationData.startDate).toISOString(),
      }));
    }
  }, [filters.startDate, filters.inputValue, filters.timeUnit, simulationData]);

  const shouldFetchData =
    filters.startDate && filters.endDate && filters.timeUnit;

  const {
    data: energyDetailsData,
    isLoading,
    error,
  } = useSWR<SimulationResultEnergyDetails>(
    shouldFetchData
      ? `/simulation/${resultId.resultId}/energy_detail/?startDatetime=${filters.startDate}&endDatetime=${filters.endDate}&timeUnit=${filters.timeUnit}`
      : null,
    {
      revalidateOnFocus: false,
    }
  );
  const isDataZero =
    energyDetailsData?.graphData.length === 0 ||
    (energyDetailsData?.graphData[0]?.chargedFromGeneration === 0 &&
      energyDetailsData?.graphData[0]?.consumptionFromGenerationDirectly ===
        0 &&
      energyDetailsData?.graphData[0]?.curtailed === 0 &&
      energyDetailsData?.graphData[0]?.soldFromGenerationDirectly === 0);

  const data: { name: string; value: number; color: string; unit: string }[] =
    [];

  if (
    energyDetailsData &&
    energyDetailsData.graphData &&
    energyDetailsData.graphData.length > 0
  ) {
    const numberOfEntries = Math.min(
      filters.inputValue || 1,
      energyDetailsData.graphData.length
    );

    const totals = {
      exportToGrid: 0,
      chargedFromGeneration: 0,
      consumptionFromGeneration: 0,
      curtailed: 0,
    };

    for (let i = 0; i < numberOfEntries; i++) {
      const entry = energyDetailsData.graphData[i];
      totals.exportToGrid += entry.soldFromGenerationDirectly;
      totals.chargedFromGeneration +=
        entry.chargedFromGeneration - entry.consumptionFromGeneratedAndStored;
      totals.consumptionFromGeneration +=
        entry.consumptionFromGenerationDirectly +
        entry.consumptionFromGeneratedAndStored;
      totals.curtailed += entry.curtailed;
    }

    data.length = 0;

    data.push(
      {
        name: 'Export to Grid from Generation Directly',
        value: totals.exportToGrid,
        color: theme.colors.blueLight,
        unit: 'MWh',
      },
      {
        name: 'Energy Charged from Generation',
        value: totals.chargedFromGeneration,
        color: theme.colors.brownLight,
        unit: 'MWh',
      },
      {
        name: 'Energy Internal Consumption from Generation in Total',
        value: totals.consumptionFromGeneration,
        color: theme.colors.orangeSecondary,
        unit: 'MWh',
      },
      {
        name: 'Energy Curtailed',
        value: totals.curtailed,
        color: theme.colors.purplePrimary,
        unit: 'MWh',
      }
    );
  }

  const totalValue = data.reduce((acc, entry) => acc + entry.value, 0);

  const handleTimeUnitChange = (value: unknown) => {
    setFilters({ ...filters, timeUnit: value as EndDateFilters['timeUnit'] });
  };

  const handleInputChange = (value: unknown) => {
    const inputValue = value as number;
    setFilters((prevState) => ({
      ...prevState,
      inputValue: inputValue <= 0 ? 1 : inputValue > 200 ? 200 : inputValue,
    }));
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return (
      current &&
      (current.isBefore(dayjs(simulationData?.startDate).startOf('day')) ||
        current.isAfter(dayjs(simulationData?.endDate).endOf('day')))
    );
  };

  const handleDatePickerChange = (date: unknown) => {
    const selectedDate = date as moment.Moment | null;
    if (selectedDate) {
      setFilters((prevState) => ({
        ...prevState,
        startDate: selectedDate
          ? selectedDate.startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
          : '',
      }));
    }
  };

  const legendData = data.map((entry) => ({
    name: entry.name,
    percentage: `${((entry.value / totalValue) * 100).toFixed(1)}% `,
    value: `${entry.value.toLocaleString()} MWh`,
    color: entry.color,
  }));

  const CustomLegend = () => {
    return (
      <StyledLegend vertical>
        {legendData.map((entry, index) => (
          <Flex align="center" gap={8} key={`item-${index}`}>
            <svg
              width="12"
              height="12"
              style={{ marginRight: 8, fill: entry.color }}
            >
              <circle cx="6" cy="6" r="6" />
            </svg>
            <Flex vertical>
              <span className="fs-12-regular text-gray-color">
                {insertLineBreak(entry.name, 4)}
              </span>
              <Flex gap={8}>
                <span className="fs-12-bold text-gray-color">
                  {entry.percentage}
                </span>
                <span className="fs-12-regular text-gray-color">
                  {entry.value}
                </span>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </StyledLegend>
    );
  };

  return (
    <Card
      title={
        <Flex gap={8} align="center">
          <Typography.Title level={4} className="fs-17-bold">
            Generation Distribution <br />
            Chart
          </Typography.Title>
          <RTooltip
            title="Generation Flow Percentage"
            description="This chart illustrates the distribution of energy generated."
          >
            <IconInfo />
          </RTooltip>
        </Flex>
      }
      extra={
        <Flex gap={16} align="center">
          <Flex gap={8} align="center">
            <motion.div
              whileTap={{ scale: 1.1 }}
              transition={{ type: 'spring', stiffness: 300, damping: 24 }}
            >
              <StyledDatePicker
                placeholder="Select Date"
                onChange={handleDatePickerChange}
                suffixIcon={<IconCalender />}
                disabledDate={disabledDate}
              />
            </motion.div>
            <motion.div
              whileTap={{ scale: 1.1 }}
              transition={{ type: 'spring', stiffness: 300, damping: 24 }}
            >
              <StyledSelect
                defaultValue="full"
                style={{ width: '109px' }}
                onChange={handleTimeUnitChange}
              >
                <StyledSelect.Option value="full">Full</StyledSelect.Option>
                <StyledSelect.Option value="year">Year</StyledSelect.Option>
                <StyledSelect.Option value="month">Month</StyledSelect.Option>
                <StyledSelect.Option value="week">Week</StyledSelect.Option>
                <StyledSelect.Option value="day">Day</StyledSelect.Option>
              </StyledSelect>
            </motion.div>
            <motion.div
              whileTap={{ scale: 1.1 }}
              transition={{ type: 'spring', stiffness: 300, damping: 24 }}
            >
              <StyledSearch
                placeholder="120"
                prefix={
                  <IconSearch style={{ height: '16px', width: 'auto' }} />
                }
                onChange={handleInputChange}
                type="number"
                value={filters.inputValue}
                disabled={filters.timeUnit === 'full'}
                status={
                  filters.inputValue !== undefined &&
                  (filters.inputValue === 1 || filters.inputValue === 200)
                    ? 'warning'
                    : undefined
                }
              />
            </motion.div>
          </Flex>
          <Button icon={<IconDownload fill={theme.colors.bluePrimary} />} />
        </Flex>
      }
      $paddingBody="24px"
      style={{ width: '100%', marginTop: '24px' }}
      styles={{ header: { padding: '4.5px 24px' } }}
    >
      {isLoading ? (
        <Flex justify="center" align="center" style={{ height: '400px' }}>
          <Spin style={{ margin: '20px' }} />
        </Flex>
      ) : error || isDataZero ? (
        <Flex
          vertical
          gap={12}
          justify="center"
          align="center"
          style={{ height: 400 }}
        >
          <IconError width={50} height={50} fill={theme.colors.tagFailure} />
          {error && (
            <Typography.Text className="fs-14-regular text-gray-color text-center">
              An error occured while fetching data. Please check your inputs or
              contact support if the issue persists.
            </Typography.Text>
          )}
          {isDataZero && (
            <Typography.Text className="fs-14-regular text-gray-color text-center">
              No data is currently available. Please review your inputs.
            </Typography.Text>
          )}
        </Flex>
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              data={data.map((entry) => ({
                ...entry,
                fill: entry.color,
                unit: entry.unit,
              }))}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={125}
              innerRadius={90}
              labelLine={false}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Legend
              content={CustomLegend}
              layout="vertical"
              verticalAlign="middle"
              align="right"
            />
            <Tooltip content={<CustomGraphicTooltip width={400} />} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};
export default GenerationFlow;
