// eslint-disable-next-line import/default
import React from 'react';

import { Col, Flex, Row, Tag, Typography } from 'antd';
import { motion } from 'framer-motion';

import {
  OverviewBodyItems,
  OverviewButton,
  OverviewHeaderItems,
} from '@/types/overview';

import StyledDiv from './styles';
import Card from '../RCard';

type Props = {
  parameters: OverviewHeaderItems[];
  body: OverviewBodyItems[];
};

const ROverview: React.FC<Props> = ({ parameters, body }) => {
  const header = parameters.map((item: OverviewHeaderItems) => (
    <Flex key={item.label} align="center" className="mr-24">
      <Flex gap={8} align="center">
        <Typography.Text className="fs-14-medium text-faded-color">
          {item.label}
        </Typography.Text>
        {item.icon}
        <Typography.Paragraph
          style={{ maxWidth: '100px' }}
          className="fs-14-regular text-black-color"
          ellipsis={{
            rows: 1,
            tooltip: item.value,
            expandable: false,
          }}
        >
          {item.value}
        </Typography.Paragraph>
      </Flex>
    </Flex>
  ));

  const overviewBody = body.map((item: OverviewBodyItems) => (
    <React.Fragment key={item.label}>
      <Col className="mr-8 " flex={'none'}>
        {item.icon}
      </Col>
      <Col className="mr-24" flex={'none'}>
        <Typography.Paragraph className="fs-14-semi-bold faded-text">
          {item.label}
        </Typography.Paragraph>
      </Col>
      <Col flex={'auto'}>
        <Row align={'middle'} justify={'start'} gutter={[0, 5]}>
          {item.buttons.map((button: OverviewButton, index: number) => (
            <Col key={index}>
              <motion.div key={index} whileTap={{ scale: 0.9 }}>
                <Tag className="tags mr-12" onClick={button.onClickFunction}>
                  <Typography.Paragraph
                    style={{ maxWidth: '75px' }}
                    className="fs-14"
                    ellipsis={{
                      rows: 1,
                      tooltip: button.label,
                      expandable: false,
                    }}
                  >
                    {button.label}
                  </Typography.Paragraph>
                </Tag>
              </motion.div>
            </Col>
          ))}
        </Row>
      </Col>
    </React.Fragment>
  ));

  return (
    <Card
      title={<Row align={'middle'}>{header}</Row>}
      className="mb-48 mt-8"
      styles={{ header: { padding: '19.5px 24px' } }}
      $paddingBody="32px 24px"
    >
      <StyledDiv>
        <Row align="middle" justify={'start'} wrap={false}>
          {overviewBody}
        </Row>
      </StyledDiv>
    </Card>
  );
};

export default ROverview;
