import { useState } from 'react';

import { Form, Button, Typography, Row, Col, Flex, Input, Image } from 'antd';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

import Grid from '@/assets/files/login/grid.png';
import ImageIcon from '@/assets/files/login/login-icon.png';
// import LogoBorusan from '@/assets/files/login/borusan-logo.png';
// import LogoGenpa from '@/assets/files/login/genpa-logo.png';
// import LogoKalyon from '@/assets/files/login/kalyon-logo.png';
// import LogoSolitek from '@/assets/files/login/solitek-logo.png';
// import LogoYeo from '@/assets/files/login/yeo-logo.png';
import useAuth from '@/hooks/useAuth';
import paths from '@/routes/paths';
import { LoginPayload } from '@/types/auth';
import { handleError } from '@/utils';

import LoginStyles from './styles';

// const logos = [
//   { src: LogoKalyon, width: 122, height: 45 },
//   { src: LogoGenpa, width: 54, height: 45 },
//   { src: LogoBorusan, width: 292, height: 45 },
//   { src: LogoYeo, width: 48, height: 45 },
//   { src: LogoSolitek, width: 165, height: 45 },
// ];

const Login = () => {
  const { login } = useAuth();
  const [form] = Form.useForm();
  const [email, setEmail] = useState<string>('');

  const onFinish = async (values: LoginPayload) => {
    const { email, password } = values;
    try {
      await login(email, password);
    } catch (error: unknown) {
      handleError(error);
    }
  };
  const onFinishFailed = (errorInfo: unknown) => {
    handleError(errorInfo);
  };

  return (
    <LoginStyles>
      <Helmet>
        <title>Login - RatioSIM</title>
      </Helmet>
      <Row className="w-100 container">
        <Col span={14} className="sign-col">
          <div className="grid">
            <Image src={Grid} preview={false} width={780} height={231} />
          </div>
          <Flex vertical className="w-100" align="center">
            <Typography.Title level={1} className="fs-42-semi-bold">
              Welcome to <span className="text-blue-color">RATIO SIM</span>
            </Typography.Title>
            <Typography.Text className="fs-14-regular text-center">
              To get started, Please sign in
            </Typography.Text>
          </Flex>
          <Flex vertical className="w-100">
            <Form
              style={{ minWidth: '400px' }}
              name="login"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              requiredMark={false}
            >
              <Form.Item
                label={
                  <Typography.Text className="fs-14-regular">
                    <span style={{ color: '#FF0000' }}>*</span> Email
                  </Typography.Text>
                }
                name="email"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  placeholder="example@example.com"
                  className="input-style"
                  size="large"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </Form.Item>
              <Form.Item
                label={
                  <Typography.Text className="fs-14-regular">
                    <span style={{ color: '#FF0000' }}>*</span> Password
                  </Typography.Text>
                }
                name="password"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.Password
                  className="input-style"
                  placeholder="Input Password"
                />
              </Form.Item>
              <Button
                className="btn-style"
                type="primary"
                htmlType="submit"
                size="large"
                block
              >
                Sign in
              </Button>
            </Form>
            <Flex
              className="w-100 forget-style"
              justify="space-between"
              align="center"
            >
              <Link className="forget" to={paths.passwordRecovery}>
                Forgot Password
              </Link>
              <Typography.Text className="account">
                Don't have an account?{' '}
                <Link to={paths.register.base} className="text-blue-color">
                  Sign up
                </Link>
              </Typography.Text>
            </Flex>
          </Flex>
        </Col>
        <Col span={10} className="img-col">
          <Flex
            vertical
            className="w-100"
            align="center"
            gap={24}
            style={{ marginBottom: '48px' }}
          >
            <Image src={ImageIcon} preview={false} width={206} height={176} />
            <Typography.Text className="img-text">
              Not a user? No worries.
              <br />
              Try best-in-class feasibility analysis for
              <br />
              your storage assets for free.
            </Typography.Text>
            <Flex gap={14} className="btn-group">
              <Flex vertical gap={8}>
                <Link to={`${paths.register.base}/?try-free`}>
                  <Button type="default" className="try-btn">
                    Try Free
                  </Button>
                </Link>
                <Typography.Text className="fs-12-regular text-center text-blue-color">
                  for 1 week
                </Typography.Text>
              </Flex>
              <Link to={paths.register.base}>
                <Button type="default" className="sign-btn">
                  Sign up
                </Button>
              </Link>
            </Flex>
          </Flex>

          {/* <Flex vertical gap={24} align="center">
            <Typography.Text className="fs-17-regular text-center text-light-color">
              Join numerous happy customer
            </Typography.Text>
            <Row className="logos">
              <div className="logos-slide">
                {[...Array(4)].map((_, outerIndex) =>
                  logos.map((logo, innerIndex) => (
                    <Image
                      key={`${outerIndex}-${innerIndex}`}
                      src={logo.src}
                      preview={false}
                      width={logo.width}
                      height={logo.height}
                      alt="logo"
                    />
                  ))
                )}
              </div>
            </Row>
          </Flex> */}
        </Col>
      </Row>
    </LoginStyles>
  );
};

export default Login;
