import type { FC } from 'react';

import { type CardProps } from 'antd';

import StyledCard from './styles';

export type ExtendedCardProps = CardProps & {
  background?: string;
  borderColor?: string;
  children?: React.ReactNode;
  $paddingBody?: string;
  minHeight?: string;
};

const Card: FC<ExtendedCardProps> = ({ children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>;
};

export default Card;
