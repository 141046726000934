import { Layout } from 'antd';
import styled from 'styled-components';

export default styled(Layout.Sider)<{ collapsed: boolean }>`
  height: calc(100vh - 66px);
  background-color: ${({ theme }) => theme.colors.blueLightest};
  padding: 24px 4px 4px 4px;
  border-right: 1px solid ${({ theme }) => theme.colors.grayVeryLight};

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-layout-sider-trigger {
    display: none;
  }
  .toggle-sider-btn {
    width: 15px;
    height: 90px;
    padding: 34px 0px;
    gap: 10px;
    border-radius: 10px 0px 0px 10px;
    color: ${({ theme }) => theme.colors.light} !important;
    background-color: ${({ theme }) => theme.colors.grayDarkAccent} !important;
    border: none !important;
    box-shadow: 5px 3px 8px 0px #00000040 !important;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary} !important;
      color: ${({ theme }) => theme.colors.light} !important;
    }
  }
  .new-simulation {
    .ant-menu-item-icon {
      fill: ${({ theme }) => theme.colors.blueSecondary} !important;
    }
  }
  .ant-menu-item {
    padding-left: ${({ collapsed }) => collapsed && '26px !important'};
  }
`;
