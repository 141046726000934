import {
  Row,
  Typography,
  Dropdown,
  MenuProps,
  Button,
  Modal,
  notification,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';

import { scenarioApi } from '@/api';
import DuplicateIcon from '@/assets/icons/icon-folder.svg?react';
import InfoIcon from '@/assets/icons/icon-info.svg?react';
import DeactivateIcon from '@/assets/icons/icon-power.svg?react';
import VerticalEllipsisIcon from '@/assets/icons/icon-three-dots-vertical.svg?react';
import ViewIcon from '@/assets/icons/icon-view-second.svg?react';
import RNotificationCard from '@/components/RNotificationCard';
import RTag from '@/components/RTag';
import CustomTooltip from '@/components/RTooltip';
import { DrawerType } from '@/types/global';
import { Scenario } from '@/types/scenario';
import { capitalizeFirstLetter, handleError } from '@/utils';

const useScenariosTableColumns = (
  mutate: () => void,
  updateDrawer: (
    type: DrawerType<Scenario>['type'],
    data: DrawerType<Scenario>['data']
  ) => void
) => {
  const theme = useTheme();
  const [modal, contextHolder] = Modal.useModal();

  const handleActivate = (record: Scenario) => {
    return modal.confirm({
      closeIcon: false,
      footer: null,
      width: 475,
      modalRender: () => (
        <RNotificationCard
          title={`Activating "${capitalizeFirstLetter(record.name)}"`}
          message="Are you sure you want to activate this scenario?"
          type="warning"
          confirmText="Yes"
          cancelText="Cancel"
          onCancel={() => Modal.destroyAll()}
          open={!record.isActive}
          onConfirm={async () => {
            try {
              await scenarioApi.activateScenario(record.id);
              mutate();
              Modal.destroyAll();
              notification.success({
                message: `Successfully Activated Scenario "${capitalizeFirstLetter(
                  record.name
                )}"`,
              });
            } catch (error) {
              Modal.destroyAll();
              handleError(error);
            }
          }}
        />
      ),
    });
  };

  //TODO DESTROY ALL DISABLES MODAL CLOSE ANIMATION FIX IT LATER
  const handleDeactivate = async (record: Scenario) => {
    return modal.confirm({
      // TODO CHANGE THIS OPEN LOGIC
      closeIcon: false,
      footer: null,
      width: 475,
      modalRender: () => (
        <RNotificationCard
          title={`Deactivating "${capitalizeFirstLetter(record.name)}"`}
          message="Are you sure you want to deactivate this scenario?"
          type="warning"
          confirmText="Yes"
          cancelText="Cancel"
          open={record.isActive}
          onCancel={() => Modal.destroyAll()}
          onConfirm={async () => {
            try {
              await scenarioApi.deactiveScenario(record.id);
              mutate();
              Modal.destroyAll();
              notification.success({
                message: `Successfully Deactivated Plant "${capitalizeFirstLetter(
                  record.name
                )}"`,
              });
            } catch (error) {
              Modal.destroyAll();
              handleError(error);
            }
          }}
        />
      ),
    });
  };

  const handleView = (record: Scenario) => {
    updateDrawer('view', record);
  };
  const handleDuplicate = (record: Scenario) => {
    updateDrawer('duplicate', record);
  };

  const generateItems = (record: Scenario) => {
    const items: MenuProps['items'] = [
      {
        label: 'View',
        key: 'view-scenario',
        icon: <ViewIcon style={{ width: '16px', height: '16px' }} />,
      },
      {
        label: 'Duplicate',
        key: 'duplicate-scenario',
        icon: <DuplicateIcon style={{ width: '16px', height: '16px' }} />,
      },
    ];

    if (record.isActive) {
      items.push({
        label: 'Deactivate',
        key: 'deactivate-scenario',
        icon: <DeactivateIcon style={{ width: '16px', height: '16px' }} />,
      });
    } else {
      items.push({
        label: 'Activate',
        key: 'activate-scenario',
        icon: <DeactivateIcon style={{ width: '16px', height: '16px' }} />,
      });
    }

    return items;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMenuClick = (info: any, record: Scenario) => {
    switch (info.key) {
      case 'view-scenario':
        handleView(record);
        break;
      case 'duplicate-scenario':
        handleDuplicate(record);
        break;
      case 'deactivate-scenario':
        handleDeactivate(record);
        break;
      case 'activate-scenario':
        handleActivate(record);
        break;
      default:
        break;
    }
  };

  const columns: ColumnsType<Scenario> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '%15',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{ maxWidth: '200px' }}
            className="text"
            ellipsis={{
              rows: 1,
              tooltip: name,
              expandable: false,
            }}
          >
            {name}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '%25',
      render: (description) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{ maxWidth: '200px' }}
            className="text"
            ellipsis={{
              rows: 1,
              tooltip: description,
              expandable: false,
            }}
          >
            {description}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: (
        <CustomTooltip
          className="cursor-pointer"
          title="Prices and Load"
          description="Prices and Load file is required to create a simulation."
          trigger="click"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Prices And Load</Typography.Text>
            <InfoIcon fill={theme.colors.dark} />
          </Row>
        </CustomTooltip>
      ),
      dataIndex: 'pricesAndLoad',
      key: 'pricesAndLoad',
      width: '%15',
      render: (fileLink) =>
        fileLink && (
          <Typography.Link href={fileLink}>
            Prices And Load File
          </Typography.Link>
        ),
    },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '%15',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{ maxWidth: '200px' }}
            className="text"
            ellipsis={{
              rows: 1,
              tooltip: data,
              expandable: false,
            }}
          >
            {dayjs(data).format('DD.MM.YYYY HH:MM')}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '%15',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (data) => (
        <Row align="middle">
          <Typography.Paragraph
            style={{ maxWidth: '200px' }}
            className="text"
            ellipsis={{
              rows: 1,
              tooltip: data,
              expandable: false,
            }}
          >
            {data}
          </Typography.Paragraph>
        </Row>
      ),
    },
    {
      title: (
        <CustomTooltip
          className="cursor-pointer"
          title="Status"
          description="Assets can be either active or inactive. If the status is active, this asset will be visible when creating a simulation."
          trigger="click"
        >
          <Row align="middle" justify="start" className="w-100">
            <Typography.Text className="pr-8">Status</Typography.Text>
            <InfoIcon fill={theme.colors.dark} />
          </Row>
        </CustomTooltip>
      ),
      sorter: (a, b) => String(a.isActive).localeCompare(String(b.isActive)),
      dataIndex: 'isActive',
      key: 'isActive ',
      align: 'left',
      width: '%10',
      render: (dataIndex) =>
        dataIndex ? <RTag type="active" /> : <RTag type="inactive" />,
    },
    {
      title: '',
      dataIndex: 'actions',
      key: 'actions ',
      width: '60px',
      render: (_, record: Scenario) => (
        <>
          {contextHolder}
          <Dropdown
            trigger={['click']}
            menu={{
              items: generateItems(record),
              onClick: (info) => handleMenuClick(info, record),
            }}
            placement="bottomRight"
          >
            <Button
              className="default-icon-button"
              type="default"
              icon={<VerticalEllipsisIcon fill={theme.colors.bluePrimary} />}
            ></Button>
          </Dropdown>
        </>
      ),
    },
  ];

  return columns;
};

export default useScenariosTableColumns;
