import { FC } from 'react';

import { CollapsePanelProps, CollapseProps } from 'antd';

import IconDown from '@/assets/icons/icon-down.svg?react';

import StyledCollapse from './styles';

export type ExtendedCollapsePanelProps = CollapsePanelProps & {
  errorMessage?: string;
  successMessage?: string;
  condition?: boolean;
};

export type ExtendedCollapseProps = CollapseProps & {
  items: ExtendedCollapsePanelProps[];
};

const RCollapse: FC<ExtendedCollapseProps> = ({ items, ...rest }) => {
  return (
    <StyledCollapse
      {...rest}
      expandIcon={(panelProps) => {
        return (
          <IconDown
            style={
              panelProps.isActive
                ? { transform: 'rotate(180deg)' }
                : { transform: 'rotate(0deg)' }
            }
          />
        );
      }}
    >
      {items.map((item: ExtendedCollapsePanelProps) => (
        <StyledCollapse.Panel {...item}>{item.children}</StyledCollapse.Panel>
      ))}
    </StyledCollapse>
  );
};

export default RCollapse;
